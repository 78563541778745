export enum CivilStatus {
  SOLTEIRO = 'Solteiro',
  CASADO = 'Casado',
  DIVORCIADO = 'Divorciado',
  SEPARADO = 'Separado',
  VIUVO = 'Viuvo',
  UNIAO_ESTAVEL = 'União estável',
  NAO_INFORMADO = 'Não Informado'
}

export enum InvestorProfile {
  CONSERVATIVE = 'Conservador. (Arriscar o seu dinheiro é algo que fora de questão.)',
  STRATEGIST = 'Moderado. (Bastante consciente quando se tratar de arriscar o seu dinheiro.)',
  EXPLORER = 'Arrojado. (Você não teme oscilações com certos altos e baixos.)'
}

export enum UserMessage {
  SUCCESS = 'Sua solicitação de alteração foi recebida com sucesso. Em até 24 horas seus dados serão atualizados em nossos sistemas.'
}

export enum ProfileDataType {
  EMAIL = 'email',
  PHONE = 'phone',
  PASSWORD = 'password'
}

export enum ProfileRouteType {
  EMAIL = 'set-contact',
  PHONE = 'set-contact',
  PASSWORD = 'set-password'
}

export enum ProfileDataTitle {
  EMAIL = 'E-mail',
  PHONE = 'Telefone',
  PASSWORD = 'Senha'
}
