import { Component, Input } from '@angular/core';
import { Options } from './call-center.model';

@Component({
  selector: 'app-call-center',
  templateUrl: './call-center.component.html',
  styleUrls: ['./call-center.component.scss']
})
export class CallCenterComponent {
  @Input() options = {
    showTitle: true,
    centralizeText: false,
    wide: false,
    whiteText: false
  } as Options;
}
