<div class="container">
  <mat-card>
    <h3>{{ title }}</h3>
    <form (ngSubmit)="onSubmit()">
      <ng-container *ngIf="!isLoading; else loading">
        <ng-container *ngIf="userEmail && userTelephone; else singleContact">
          <p>{{ text }}</p>

          <mat-radio-group [formControl]="contact">
            <mat-radio-button value="sms" color="primary">
              SMS: {{ userTelephone }}
            </mat-radio-button>
            <mat-radio-button value="email" color="primary">
              E-mail: {{ userEmail }}
            </mat-radio-button>
          </mat-radio-group>

          <mat-error *ngIf="contact.invalid && hasSubmitted">
            Selecione uma opção.
          </mat-error>
        </ng-container>

        <ng-template #singleContact>
          <p *ngIf="!passwordRecovery">
            O código vai ser enviado para
            <ng-container *ngIf="userTelephone">
              {{ userTelephone }}
            </ng-container>
            <ng-container *ngIf="userEmail">
              {{ userEmail }}
            </ng-container>
          </p>
          <p *ngIf="passwordRecovery">
            Caso haja um celular cadastrado em nossa base, enviaremos um token
            via SMS para validação da troca de senha.
          </p>
        </ng-template>
      </ng-container>

      <ng-template #loading>
        <mat-spinner></mat-spinner>
      </ng-template>

      <div class="actions" *ngIf="!isLoading">
        <button
          mat-raised-button
          class="main-button"
          color="primary"
          type="submit"
          title="Avança para a confirmação de código"
        >
          Continuar
        </button>
        <button
          *ngIf="back"
          mat-button
          color="primary"
          type="button"
          title="Voltar"
          (click)="back()"
        >
          Voltar
        </button>
      </div>
    </form>
  </mat-card>

  <div class="warning">
    <mat-icon>info</mat-icon>
    <p class="no-margin">
      <strong>Caso não receba o token em seu telefone em até 7 minutos</strong>,
      entre em contato com a nossa central de relacionamento para atualizar os
      seus dados.
    </p>
  </div>
</div>
