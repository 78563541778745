import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/auth/auth.service';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, finalize, mergeMap, take } from 'rxjs/operators';
import { SuccessTokenRefresh } from '../authentication';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  refreshToken$ = new Subject<SuccessTokenRefresh>();
  isRefreshing = false;

  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = request.clone({});
    return next.handle(request).pipe(
      catchError((error) => {
        if (error.status === 401) {
          if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.authService
              .refreshToken()
              .pipe(take(1))
              .subscribe((tokens: SuccessTokenRefresh) =>
                this.refreshToken$.next(tokens)
              );
          }
          return this.waitUntilRefreshes(request, next);
        } else {
          return throwError(error);
        }
      })
    );
  }

  public waitUntilRefreshes(request: HttpRequest<any>, next: HttpHandler) {
    return this.refreshToken$.pipe(
      take(1),
      mergeMap(() => {
        request = request.clone({
          setHeaders: {
            authorization: this.authService.getTokenWithPrefix(request.url)
          }
        });
        return next.handle(request);
      }),
      finalize(() => {
        this.isRefreshing = false;
      })
    );
  }
}
