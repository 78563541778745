import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';

import { OpinButtonModule } from '../../atoms/button/button.module';

import { ConsentManagementService } from '@app/open-insurance/modules/consent-management/consent-management.service';
import { RevokeDialogComponent } from './revoke-sharing-dialog.component';

@NgModule({
  providers: [ConsentManagementService],
  exports: [RevokeDialogComponent],
  declarations: [RevokeDialogComponent],
  imports: [MatDialogModule, CommonModule, OpinButtonModule]
})
export class OpinRevokeSharingDialogModule {}
