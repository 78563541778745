import { NgModule } from '@angular/core';
import {
  ApolloClientOptions,
  ApolloLink,
  concat,
  defaultDataIdFromObject,
  InMemoryCache,
  StoreObject
} from '@apollo/client/core';
import { CookieService } from '@app/shared/service/cookie.service';
import { environment } from '@env/environment';
import {
  APOLLO_NAMED_OPTIONS,
  APOLLO_OPTIONS,
  ApolloModule
} from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { AuthData } from './core/auth/auth.model';

const statementUri = environment.api.graphql.statement;
const withdrawUri = environment.api.graphql.withdraw;
const cookie = new CookieService();

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(() => ({
    headers: {
      Authorization: `OAUTH2 ${cookie.get(AuthData.AuthToken)}` || '',
      canaloperacao: '5',
      cpf: sessionStorage.getItem('profile: cpf') || ''
    }
  }));

  return forward(operation);
});

const authIncomeTax = new ApolloLink((operation, forward) => {
  operation.setContext(() => ({
    headers: {
      Authorization: `Bearer ${cookie.get(AuthData.AuthToken) || ''}`
    }
  }));

  return forward(operation);
});

export function createDefaultApollo(
  httpLink: HttpLink
): ApolloClientOptions<any> {
  return {
    link: httpLink.create({ uri: environment.api.graphql.default }),
    cache: new InMemoryCache()
  };
}

function createCustomID(object: Readonly<StoreObject>) {
  const customID = {
    Plan: `Plan:${object.registration}`,
    Simulate: `Simulate:${object.registration}`,
    default: defaultDataIdFromObject(object)
  };

  return customID[object.__typename] || customID.default;
}

export function createCustomApollo(
  httpLink: HttpLink
): Record<string, ApolloClientOptions<any>> {
  return {
    statement: {
      name: 'statement',
      link: concat(authMiddleware, httpLink.create({ uri: statementUri })),
      cache: new InMemoryCache()
    },
    withdraw: {
      name: 'withdraw',
      link: concat(authMiddleware, httpLink.create({ uri: withdrawUri })),
      cache: new InMemoryCache({
        dataIdFromObject(responseObject) {
          return createCustomID(responseObject);
        }
      })
    },
    incomeTax: {
      name: 'incomeTax',
      link: concat(authIncomeTax, httpLink.create({ uri: statementUri })),
      cache: new InMemoryCache()
    },
    transactionPassword: {
      name: 'transactionPassword',
      link: concat(authIncomeTax, httpLink.create({ uri: withdrawUri })),
      cache: new InMemoryCache()
    }
  };
}

@NgModule({
  imports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      deps: [HttpLink],
      useFactory: createDefaultApollo
    },
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: createCustomApollo,
      deps: [HttpLink]
    }
  ]
})
export class GraphQLModule {}
