<mat-dialog-content>
  <ng-container *ngIf="isLoading">
    <mat-spinner></mat-spinner>
  </ng-container>

  <ng-container *ngIf="!isLoading && pdfLink">
    <p>Para planos tradicionais, acesse o extrato regulamentar.</p>
    <p>
      <mat-icon svgIcon="pdf"><a href="{{ pdfLink }}"></a></mat-icon>
    </p>
    <a
      mat-raised-button
      color="primary"
      [mat-dialog-close]="true"
      cdkFocusInitial
      href="{{ pdfLink }}"
      target="blank"
      >{{ 'Clique aqui para visualizar seu extrato' | uppercase }}</a
    >
  </ng-container>

  <ng-container *ngIf="!isLoading && !pdfLink">
    <mat-icon>error</mat-icon>
    <p>Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.</p>
    <button mat-raised-button color="primary" [mat-dialog-close]="true">
      Fechar
    </button>
  </ng-container>
</mat-dialog-content>
