import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from '@app/core/notification/notification.service';
import { finalize } from 'rxjs/operators';
import { Channel } from '../auth.model';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-wpp-modal',
  templateUrl: './wpp-modal.component.html',
  styleUrls: ['./wpp-modal.component.scss']
})
export class WppModalComponent {
  isLoading: boolean;
  constructor(
    private authService: AuthService,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<WppModalComponent>
  ) {}

  public onAcceptance(): void {
    this.isLoading = true;
    this.authService
      .askCode(Channel.WPP)
      .pipe(finalize(() => this.dialogRef.close(true)))
      .subscribe(() => {
        this.notificationService.showSuccess(
          'Código enviado para o seu whatsapp',
          'Ok',
          { duration: 5000 }
        );
      });
  }
}
