import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-header-tile',
  templateUrl: './header-tile.component.html',
  styleUrls: ['./header-tile.component.scss']
})
export class HeaderTileComponent {
  @Input() title: string;
  @Input() value: number;
  @Input() tooltip: string;
}
