import { NgModule } from '@angular/core';

import { OpinIconModule } from '../../atoms/icon/icon.module';
import { OpinTextComponentModule } from '../../atoms/text/text.module';
import { OpinHeaderComponent } from './header.component';

@NgModule({
  declarations: [OpinHeaderComponent],
  exports: [OpinHeaderComponent],
  imports: [OpinTextComponentModule, OpinIconModule]
})
export class OpinHeaderComponentModule {}
