import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '@app/core/notification/notification.service';
import { BackButtonSettings } from '@app/shared/components/sub-header/sub-header.model';
import { PlanObjective, bodyUpdatePlan } from '../plan.model';
import { PlanService } from '../plan.service';
import { PlanSummary } from './../../home/statement.model';
import { StatementService } from './../../home/statement.service';

@Component({
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  objectiveForm: UntypedFormGroup;
  isLoading: boolean;
  id: string;
  planName: string;
  backButtonSettings: BackButtonSettings;
  planData;
  planObjective: string;
  planObjectives: string[];
  allPlanObjectives: string[];
  constructor(
    private fb: UntypedFormBuilder,
    private activedRoute: ActivatedRoute,
    private location: Location,
    private planService: PlanService,
    private statementService: StatementService,
    private notificationService: NotificationService,
    private router: Router
  ) {
    this.id = this.activedRoute.snapshot.params.id;
    this.backButtonSettings = {
      title: 'Voltar'
    };
  }

  ngOnInit() {
    this.objectiveForm = this.fb.group({
      objective: [''],
      name: ['', Validators.maxLength(100)],
      amount: ['', Validators.maxLength(20)]
    });

    this.statementService.getPlansGraphql().subscribe((plans) => {
      this.planData = plans.filter((res) => res.registration === this.id)[0];
      this.planName = this.planData.name;
      this.planObjective = this.planData.objective;
      this.fillFields(this.planData);
    });
  }

  public getObjectiveList = (): string[] =>
    (this.planObjectives = Object.values(PlanObjective));

  private fillFields(plan: PlanSummary): void {
    this.objectiveForm.controls.name.setValue(plan.name);
    this.objectiveForm.controls.amount.setValue(plan.objectiveAccumulation);
    this.objectiveForm.controls.objective.setValue(plan.objective);
  }

  onSubmit(): void {
    if (this.objectiveForm.valid) {
      this.updatePlanObjective();
    }
  }

  back(): void {
    this.location.back();
  }

  private updatePlanObjective(): void {
    this.isLoading = true;
    const dataUpdatePlan = {
      numeroMatricula: this.id,
      nome: this.objectiveForm.get('name').value,
      tipo: this.objectiveForm.get('objective').value,
      valor: this.objectiveForm.get('amount').value
    } as bodyUpdatePlan;
    this.planService.updatePlanGraphQL(dataUpdatePlan).subscribe((res) => {
      if (res.error_code) {
        this.notificationService.showError(res.detail);
      } else {
        this.notificationService.showSuccess(
          'Plano atualizado com sucesso"',
          'X',
          { duration: 10000 }
        );
        this.router.navigate(['/plan', this.id]);
      }
    });
  }
}
