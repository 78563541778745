import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { AuthData } from '@app/core/auth/auth.model';

@Component({
  selector: 'app-code-verification',
  templateUrl: './code-verification.component.html',
  styleUrls: ['./code-verification.component.scss']
})
export class CodeVerificationComponent implements OnInit {
  contactMethod: string;
  chosenChannel: string;
  isLoading: boolean;

  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit() {
    this.chosenChannel = this.authService.getAuthData(AuthData.ChosenChannel);

    this.contactMethod =
      this.authService.getAuthData(AuthData.ContactMethod) === 'sms'
        ? 'celular'
        : 'email';

    this.back = this.back.bind(this);
  }

  handleCodeVerified(event: { code: string }): void {
    this.authService.setAuthData(AuthData.SecurityCode, event.code);
    this.router.navigate([
      `auth/password-setup/${this.chosenChannel}/set-password`
    ]);
  }

  back() {
    this.router.navigate([
      `auth/password-setup/${this.chosenChannel}/select-contact`
    ]);
  }
}
