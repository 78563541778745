import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OpinAlertComponent } from './alert.component';

import { OpinIconModule } from '../../atoms/icon/icon.module';
import { OpinLabelModule } from '../../atoms/label/label.module';
import { OpinButtonModule } from '../../atoms/button/button.module';

@NgModule({
  exports: [OpinAlertComponent],
  declarations: [OpinAlertComponent],
  imports: [CommonModule, OpinIconModule, OpinLabelModule, OpinButtonModule]
})
export class OpinAlertModule {}
