import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OpinIconModule } from '../../atoms/icon/icon.module';
import { OpinButtonWithIconComponent } from './button-with-icon.component';

@NgModule({
  declarations: [OpinButtonWithIconComponent],
  exports: [OpinButtonWithIconComponent],
  imports: [CommonModule, OpinIconModule]
})
export class OpinButtonWithIconComponentModule {}
