<h2>
  {{ title }}
  <mat-icon
    [inline]="true"
    matTooltipPosition="after"
    [matTooltip]="tooltip"
    aria-label="Mais informações"
  >
    help
  </mat-icon>
</h2>

<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <strong
          *ngIf="summaryBalance?.totalRelease; else unavailable"
          [appPrivacy]="summaryBalance?.totalRelease | currency"
        >
        </strong>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="details-content">
      <div class="details-line">
        <p>Empresa</p>
        <p
          class="value"
          [appPrivacy]="summaryBalance?.totalReleaseCompany | currency"
        ></p>
      </div>

      <div class="details-line">
        <p>Participante</p>
        <p
          class="value"
          [appPrivacy]="summaryBalance?.totalReleaseParticipant | currency"
        ></p>
      </div>

      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div class="details-line">
              <p>Suplementar</p>
              <p
                class="value"
                [appPrivacy]="
                  summaryBalance?.supplementaryReleases?.totalRelease | currency
                "
              ></p>
            </div>
          </mat-expansion-panel-header>

          <div class="content-three">
            <div class="details-line">
              <p>Empresa</p>
              <p
                class="value"
                [appPrivacy]="
                  summaryBalance?.supplementaryReleases?.totalReleaseCompany
                    | currency
                "
              ></p>
            </div>

            <div class="details-line">
              <p>Participante</p>
              <p
                class="value"
                [appPrivacy]="
                  summaryBalance?.supplementaryReleases?.totalReleaseParticipant
                    | currency
                "
              ></p>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<ng-template #unavailable>
  <strong>Indisponível</strong>
</ng-template>
