import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Error, ErrorWithContext } from '../errors.model';

@Component({
  selector: 'app-error',
  templateUrl: './errors.component.html',
  styleUrls: ['./errors.component.scss']
})
export class ErrorsComponent implements OnInit {
  routeParams: ErrorWithContext;
  data: Error;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.routeParams = this.activatedRoute.snapshot
      .queryParams as ErrorWithContext;
    this.data = this.activatedRoute.snapshot.data as Error;
  }
}
