import { AfterViewInit, Component, Input } from '@angular/core';

export interface TableElement {
  mes: string;
  saldo: number;
  rend: number;
  rent: number;
}

const ELEMENT_DATA: TableElement[] = [
  { mes: 'JAN', saldo: 99600, rend: 250, rent: 0.085 },
  { mes: 'FEV', saldo: 100800, rend: 210, rent: 0.086 },
  { mes: 'MAR', saldo: 103000, rend: 265, rent: 0.088 },
  { mes: 'ABR', saldo: 105200, rend: 250, rent: 0.085 },
  { mes: 'MAI', saldo: 108400, rend: 230, rent: 0.08 },
  { mes: 'JUN', saldo: 112800, rend: 300, rent: 0.092 },
  { mes: 'JUL', saldo: 114000, rend: 350, rent: 0.095 }
];

@Component({
  selector: 'app-consolidated-table',
  templateUrl: './consolidated-table.component.html',
  styleUrls: ['./consolidated-table.component.scss']
})
export class ConsolidatedTableComponent implements AfterViewInit {
  @Input() consolidatedItem;
  public isDetailsVisible = false;
  displayedColumns: string[] = ['Mês', 'Saldo', 'Rend. em R$', 'Rent. média'];
  dataSource = ELEMENT_DATA;

  ngAfterViewInit() {
    console.log(this.consolidatedItem);
  }
  toggleDetails() {
    this.isDetailsVisible = !this.isDetailsVisible;
  }
}
