import { Component, Input, OnInit } from '@angular/core';
import { format } from '@app/shared/date-fns';
import { makeDateFromBrazilianDate } from '@app/shared/utils/date';
import { differenceInMilliseconds, getYear, startOfYear } from 'date-fns';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { PlanService } from '../plan.service';
@Component({
  selector: 'app-plan-tracking-statement',
  templateUrl: './plan-tracking-statement.component.html',
  styleUrls: ['./plan-tracking-statement.component.scss']
})
export class PlanTrackingStatementComponent implements OnInit {
  @Input() registration: string;
  @Input() initialDate: string;
  panelOpenState = true;
  isLoading: boolean;
  openMonthStart: any;
  openMonthEnd: any;
  monthNames = [
    'JAN',
    'FEV',
    'MAR',
    'ABR',
    'MAI',
    'JUN',
    'JUL',
    'AGO',
    'SET',
    'OUT',
    'NOV',
    'DEZ'
  ];
  displayedColumns: string[] = [
    'month',
    'monthBalance',
    'monthYield',
    'monthProfitability'
  ];
  totalMonthBalance: any;
  totalMonthProfitability: any;
  totalMonthYield: any;

  year$: BehaviorSubject<string> = new BehaviorSubject(
    format({ date: new Date(), dateFormat: 'yyyy' })
  );

  planTracking$: Observable<any> = this.year$.pipe(
    switchMap((year) =>
      this.planService.getPlanTracking(this.registration, year.toString())
    )
  );

  years: Array<number> = [];

  constructor(private planService: PlanService) {}

  ngOnInit(): void {
    this.years = this.mountTransferYears();
    this.setReferenceDate(
      this.years[this.years.length - 1].toString(),
      this.initialDate
    );
    this.totalMonthBalance = this.getLastMothBalance('monthBalance');
    this.totalMonthProfitability = this.getReducedValues(
      'monthProfitability'
    ).pipe(map((item) => item / 100));
    this.totalMonthYield = this.getReducedValues('monthYield');
  }

  public mountTransferYears(): Array<number> {
    const start = getYear(makeDateFromBrazilianDate(this.initialDate));
    const end = getYear(new Date());
    const milliseconds = differenceInMilliseconds(end, start);
    const yearsBetween = [];
    for (let year = 0; year <= milliseconds; year++) {
      yearsBetween.push(start + year);
    }
    return yearsBetween;
  }

  setReferenceDate(year: string, initialDate: string) {
    if (this.verifyOldestYear(year.toString(), initialDate)) {
      this.openMonthStart = this.convertInitialDate(initialDate);
      this.openMonthEnd = new Date(`${year.toString()}-12-31T00:00:00`);
    } else if (this.verifyNewestYear(year.toString())) {
      this.openMonthStart = new Date(`${year.toString()}-01-01T00:00:00`);
      this.openMonthEnd = new Date().setDate(new Date().getDate() - 1);
    } else {
      this.openMonthStart = new Date(`${year.toString()}-01-01T00:00:00`);
      this.openMonthEnd = new Date(`${year.toString()}-12-31T00:00:00`);
    }
  }

  verifyOldestYear(year: string, initialDate: string): boolean {
    const initialYear = this.convertInitialDate(initialDate)
      .getFullYear()
      .toString();
    if (year === initialYear) {
      return true;
    } else {
      return false;
    }
  }

  convertInitialDate(date: string): Date {
    const data_en = date.split('/').reverse().join('-');
    return new Date(`${data_en}T00:00:00`);
  }

  verifyNewestYear(year: string) {
    const currentYear = new Date().getFullYear().toString();
    return currentYear === year;
  }

  getReducedValues(itemName: string): Observable<number> {
    return this.planTracking$.pipe(
      first(),
      map((item) =>
        item.monthsType
          .map((month) => month[itemName])
          .reduce((acc, curr) => acc + curr, 0)
      )
    );
  }

  getLastMothBalance(itemName: string) {
    return this.planTracking$.pipe(
      first(),
      map(
        (item) =>
          item.monthsType.map((month) => month[itemName])[
            item.monthsType.length - 1
          ]
      )
    );
  }

  public nextYear(year: number) {
    if (year) {
      this.year$.next(
        format({
          date: startOfYear(new Date(year, 0, 1)),
          dateFormat: 'yyyy'
        })
      );
      this.setReferenceDate(year.toString(), this.initialDate);
      this.totalMonthBalance = this.getLastMothBalance('monthBalance');
      this.totalMonthProfitability = this.getReducedValues(
        'monthProfitability'
      ).pipe(map((item) => item / 100));
      this.totalMonthYield = this.getReducedValues('monthYield');
    }
  }
}
