import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared';
import { OpinDividerComponent } from './divider.component';

@NgModule({
  declarations: [OpinDividerComponent],
  exports: [OpinDividerComponent],
  imports: [CommonModule, SharedModule]
})
export class OpinDividerComponentModule {}
