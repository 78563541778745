<div class="container" *ngIf="cpf">
  <mat-card>
    <h3>Identificação</h3>
    <ng-container *ngIf="!isLoading">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="stored-user">
          <mat-icon>how_to_reg</mat-icon>
          <div class="content">
            <span>CPF</span>
            <span>{{ cpfObj.formated }}</span>
          </div>
          <button
            mat-button
            color="primary"
            type="button"
            (click)="removeLoginData()"
          >
            Trocar
          </button>
        </div>

        <mat-form-field appearance="outline">
          <mat-label>Senha</mat-label>
          <input
            matInput
            [type]="isPasswordHidden ? 'password' : 'text'"
            formControlName="fakePassword"
            name="password"
            maxlength="8"
          />
          <mat-error *ngIf="form.controls['fakePassword'].errors">
            <span *ngIf="form.controls['fakePassword'].errors.required"
              >Esse campo é obrigatório.</span
            >
            <span *ngIf="form.controls['fakePassword'].errors.pattern"
              >Digite apenas números.</span
            >
          </mat-error>
          <button
            matSuffix
            type="button"
            mat-icon-button
            (click)="togglePasswordVisibility()"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="isPasswordHidden"
          >
            <mat-icon>
              {{ isPasswordHidden ? 'visibility_off' : 'visibility' }}
            </mat-icon>
          </button>
        </mat-form-field>

        <div class="actions">
          <button
            class="main-button"
            [disabled]="isLoading"
            mat-raised-button
            color="primary"
            type="submit"
            title="Continuar"
          >
            Continuar
          </button>
          <a
            mat-button
            [routerLink]="['/auth/password-setup/recover']"
            title="Esqueci minha senha"
          >
            Esqueci minha senha
          </a>
        </div>
      </form>
    </ng-container>
    <mat-spinner *ngIf="isLoading"></mat-spinner>
  </mat-card>
</div>
