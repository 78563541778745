import { Injectable } from '@angular/core';

import {
  FeatureFlagKeys,
  FeatureFlags,
  FeatureFlagsType
} from './feature-flag.model';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  private flags: FeatureFlagsType = FeatureFlags;

  checkIsFeatureFlagActivateable(featureFlag: FeatureFlagKeys): boolean {
    return this.flags[featureFlag];
  }

  toggleFlag(flag: FeatureFlagKeys, isActive: boolean): void {
    this.flags[flag] = isActive;
  }
}
