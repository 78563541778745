import {
  DefinedPeriodIncomeProjection,
  DetailPlan,
  Plan,
  SimulationResponse
} from '@app/core/departure-date-bff';
import {
  LifeRetirementProjectionResponse,
  RetirementProjectionResponse,
  UpConfirmPaymentResponse,
  UpConfirmSimulationResponse,
  UpRetirementIncomeTypeResponseRetirementIncomeType,
  UpSimulateChangeDateResponse
} from '@app/core/upselling-api';
import { PlanCard } from '@app/shared/components/registration-card/registration-card.model';

export class PaymentData {
  payment: UpConfirmSimulationResponse;
  value: number;
}

export enum RetirementType {
  Defined = 'defined',
  LifeTime = 'lifeTime'
}

export enum ContributionOperator {
  Increase = 'Increase',
  Decrease = 'Decrease'
}

export class Projection {
  contribution: number;
  futureBalance: number;
}

export class Boleto {
  upConfirmPaymentResponse: UpConfirmPaymentResponse;
  boletoHref: string;
  digitableLineDigits: string;
}

export enum SessionStorageUpsellingId {
  newContributionData = 'new-contribution: Data',
  newContributionPlan = 'new-contribution: Plan',
  extraContributionData = 'extra-contribution: Data',
  departureDatePlan = 'departure-date: Plan',
  departureDateSimulation = 'departure-date: Simulation',
  departureDateData = 'departure-date: Data'
}

export class UpsellingPlan {
  balance?: number;
  currentContribution?: number;
  suggestedContribution?: number;
  valid?: boolean;
  registration?: string;
  planType?: string;
  modalityName?: string;
  name?: string;
  category?: string;
}

export class SeparatedPlans {
  enabled: PlanCard[];
  disabled: PlanCard[];
}

export enum ChartDataType {
  balance = 0,
  retirement = 1
}

export class SeparatedDefinedSimulations {
  10: DefinedPeriodIncomeProjection[];
  15: DefinedPeriodIncomeProjection[];
  20: DefinedPeriodIncomeProjection[];
}

export class DepartureSimulationResponse {
  balanceSimulation: UpSimulateChangeDateResponse;
  retirementTypes: UpRetirementIncomeTypeResponseRetirementIncomeType[];
  retirementSimulation: Array<
    LifeRetirementProjectionResponse | RetirementProjectionResponse[]
  >;
}

export const UpBaseRoute = {
  newContribution: ['upselling', 'new-contribution'],
  extraContribution: ['upselling', 'extra-contribution'],
  departureDate: ['upselling', 'departure-date']
};

export class DepartureData {
  list?: Plan[];
  plan?: PlanCard;
  details?: DetailPlan;
  simulation?: SimulationResponse;
}
