import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { OpinIconModule } from '../../atoms/icon/icon.module';
import { OpinLabelModule } from '../../atoms/label/label.module';
import { OpinTextComponentModule } from '../../atoms/text/text.module';
import { OpinToggleSliderComponentModule } from '../../atoms/toggle-slider/toggle-slider.module';
import { OpinConsentCardComponent } from './consent-card.component';

@NgModule({
  exports: [OpinConsentCardComponent],
  declarations: [OpinConsentCardComponent],
  imports: [
    CommonModule,
    MatIconModule,
    OpinIconModule,
    OpinTextComponentModule,
    OpinLabelModule,
    OpinToggleSliderComponentModule
  ]
})
export class OpinConsentCardComponentModule {}
