import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import { ChartDataset, ChartOptions } from 'chart.js';
import { Observable, Subscription } from 'rxjs';
import { first, map, startWith } from 'rxjs/operators';
import { InvestmentComparatorService } from './investment-comparator.service';
import { InvestmentDetailService } from './investment-detail.service';

@Component({
  selector: 'app-investment-comparator',
  templateUrl: './investment-comparator.component.html',
  styleUrls: ['./investment-comparator.component.scss']
})
export class InvestmentComparatorComponent implements OnDestroy {
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fundCtrl = new UntypedFormControl('');
  filteredFunds: Observable<string[]>;
  funds: string[] = [];
  opened = false;
  data: ChartDataset[];
  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;
  @Input() investmentsNames: string[];
  @ViewChild('fundInput') fundInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('autocompleteTrigger') matACTrigger: MatAutocompleteTrigger;
  labels = this.investmentDetailService.obterMeses();

  option: ChartOptions = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true
      }
    },
    plugins: {
      legend: { display: true, labels: { color: 'black' } },
      tooltip: {
        callbacks: {
          title: (context) => context[0].label
        }
      }
    }
  };

  constructor(
    private investmentComparatorService: InvestmentComparatorService,
    private investmentDetailService: InvestmentDetailService,
    private ref: ChangeDetectorRef
  ) {
    this.filteredFunds = this.fundCtrl.valueChanges.pipe(
      startWith(null),
      map((fund: string | null) =>
        fund ? this._filter(fund) : this.investmentsNames.slice()
      )
    );
    this.data = this.investmentComparatorService.chartDataObj;
  }

  ngOnDestroy(): void {
    this.investmentComparatorService.resetComparator();
  }

  addFundList(): void {
    this.fundCtrl.setValue(null);
  }

  removeFundList(fund: string): void {
    this.investmentComparatorService.removeFundo(fund);
    this.funds = [...this.funds.filter((fundo) => fundo !== fund)];
    this.data = this.investmentComparatorService.chartDataObj;
  }

  selectedFund(event: MatAutocompleteSelectedEvent): void {
    const newValue = event.option.viewValue;
    if (this.funds.includes(newValue)) {
      this.removeFundList(newValue);
    } else {
      this.funds.push(newValue);
      this.investmentComparatorService
        .setFundosComparados(newValue)
        .pipe(first())
        .subscribe(() => {
          this.data = [...this.investmentComparatorService.chartDataObj];
        });
    }
    this.fundInput.nativeElement.value = '';
    this.fundCtrl.setValue(null);
    requestAnimationFrame(() => {
      this.fundInput.nativeElement.blur();
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.investmentsNames.filter(
      (fund) => fund.toLowerCase().indexOf(filterValue) >= 0
    );
  }

  openListFunds(trigger: MatAutocompleteTrigger): void {
    trigger.openPanel();
    this.fundInput.nativeElement.focus();
  }
}
