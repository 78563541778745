import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { OfficeHour } from '@app/surrender/withdraw.types';
import { environment } from '@env/environment';
import { getDate, getMonth, getYear, isAfter, isBefore } from 'date-fns';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { ScheduleService } from '../service/schedule.service';

@Injectable()
export class ScheduleGuard  {
  constructor(private scheduleService: ScheduleService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const isOfficeHour =
      route.data.useOfficeHour !== undefined ? route.data.useOfficeHour : true;
    const limitStartHour = route.data.useCustomStartHour
      ? this.fromArrayToDate(route.data.useCustomStartHour)
      : this.fromArrayToDate(environment.timeLimits.default.start);
    const limitEndHour = route.data.useCustomEndHour
      ? this.fromArrayToDate(route.data.useCustomEndHour)
      : this.fromArrayToDate(environment.timeLimits.default.end);
    return this.scheduleService.getSchedule().pipe(
      first(),
      map((time: OfficeHour) => {
        if (isOfficeHour) return time.onOfficeHour;

        const clockTimeDate = this.fromRemoteToDate(time.clock);
        const isClockTimeBeforeLimitEndHour = isBefore(
          clockTimeDate,
          limitEndHour
        );
        const isClockTimeAfterLimitStartHour = isAfter(
          clockTimeDate,
          limitStartHour
        );

        return isClockTimeBeforeLimitEndHour || isClockTimeAfterLimitStartHour;
      })
    );
  }

  private fromArrayToDate(time: number[]): Date {
    const today = new Date();
    const [hour, minutes, seconds] = time;

    const arrayAsDate = new Date(
      getYear(today),
      getMonth(today),
      getDate(today),
      hour,
      minutes,
      seconds
    );

    return arrayAsDate;
  }

  private fromRemoteToDate(date: string) {
    const timeRaw = date
      .split(' ')[1]
      .split(':')
      .map(item => parseInt(item, 10));
    return this.fromArrayToDate(timeRaw);
  }
}
