import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { AuthData } from '@app/core/auth/auth.model';
import { UserData } from '@app/core/auth/user.model';
import { CpfUtils } from '@app/core/validators/cpf.validator';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-send-cpf',
  templateUrl: './send-cpf.component.html',
  styleUrls: ['../../auth-components.scss']
})
export class SendCpfComponent implements OnInit, OnDestroy {
  chosenChannel: string;
  sendCpfForm: UntypedFormGroup;
  cpf: string;
  isLoading: boolean;
  unsubscribe: Subject<any> = new Subject();

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private cpfUtils: CpfUtils,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.chosenChannel = this.activatedRoute.snapshot.params.type;
    this.authService.setAuthData(AuthData.ChosenChannel, this.chosenChannel);
    this.buildForm();
  }

  ngOnDestroy() {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  private buildForm() {
    this.cpf = this.authService.getLoginData()
      ? this.cpfUtils.format(this.authService.getLoginData())
      : '';
    this.sendCpfForm = this.fb.group({
      cpf: [this.cpf, [Validators.required, this.cpfUtils.validateField()]]
    });
    this.sendCpfForm
      .get('cpf')
      .valueChanges.pipe(takeUntil(this.unsubscribe))
      .subscribe((cpf) => {
        if (this.cpfUtils.isFormated(cpf)) {
          const formatedCpf = this.cpfUtils.format(cpf);
          this.sendCpfForm.get('cpf').setValue(formatedCpf);
        }
      });
  }

  onSubmit() {
    if (this.sendCpfForm.valid) {
      this.authService.setUserData(
        UserData.Cpf,
        this.cpfUtils.toDigits(this.sendCpfForm.get('cpf').value)
      );
      this.router.navigate(['select-contact'], {
        relativeTo: this.activatedRoute
      });
    }
  }
}
