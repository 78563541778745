import { Injectable } from '@angular/core';
import { NotificationService } from '@app/core/notification/notification.service';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { EMPTY } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { Certificate } from './certificate.model';

@Injectable({
  providedIn: 'root'
})
export class CertificateService {
  constructor(
    private apollo: Apollo,
    private notification: NotificationService
  ) {}

  public getCertificate(registration: string) {
    return this.apollo
      .use('statement')
      .watchQuery<Certificate>({
        query: gql`
          query CertificateStatement($registration: String!) {
            certificateStatement(registration: $registration) {
              status
              url
            }
          }
        `,
        variables: {
          registration
        }
      })
      .valueChanges.pipe(
        take(1),
        catchError(() => {
          this.notification.showError(
            `Identificamos um problema no nosso sistema. Retorne em breve, ou se
          preferir entre em contato com a nossa Central de Atendimento através do
          número 4004 7170 para capitais e regiões metropolitanas, ou 0800 729 7170
          para demais localidades, das 8h às 20h, de segunda a sexta-feira, exceto
          feriados.`,
            'Ok',
            { duration: 0 }
          );
          return EMPTY;
        }),
        map((result) => result.data.certificateStatement)
      );
  }

  public onSelection(registration: string) {
    return this.getCertificate(registration).pipe(
      tap((res) => {
        if (res.url) {
          window.open(res.url, '_blank');
        }
      })
    );
  }
}
