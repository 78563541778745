import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  Detail,
  Distribution,
  Eligibility,
  HttpErro,
  InvestmentPlanDetail,
  InvestmentsPlan,
  InvestorProfile,
  Plan,
  PlanDetail,
  PortfolioCompare,
  RelocationService,
  Rentability,
  SimulationDestinationDefineRequest,
  SimulationDestinationDefineResponse,
  SimulationRequest,
  SimulationResponse,
  SolicitationRequest,
  SolicitationResponse,
  Summary
} from '@app/core/new-diversification';
import { NotificationService } from '@app/core/notification/notification.service';
import { Prefix } from '@app/shared/models/prefix.model';
import { SessiontorageData } from '@app/shared/utils/sessionStorage';
import { ExceptionService } from '@app/upselling/exception.service';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { SummaryPortfolio } from '../components/summary-comparison-list/summary-comparison-list.component';
import { DIVERSIFICATION_SERVICE_MESSAGES } from '../constants/messages/diversification-service';
import { MockDiversificationService } from '../constants/mocks/diversification.service';
import { DIVERSIFICATION_ROUTES } from '../constants/routes';
import {
  ValidAndInvalidPlansArray,
  getValidAndInvalidPlans
} from '../utils/get-valid-and-invalid-plans';
import { AuthenticationService } from './authentication.service';
import { DiversificationServiceContract } from './contracts/diversification.service';

@Injectable({
  providedIn: 'root'
})
export class DiversificationService implements DiversificationServiceContract {
  constructor(
    private api: RelocationService,
    private authService: AuthenticationService,
    private router: Router,
    private notificationService: NotificationService,
    private exceptionService: ExceptionService,
    public mockDiversificationService: MockDiversificationService
  ) {
    this.useMockService = this.mockDiversificationService.useMockService;
  }
  listId = 'list:';
  public sessionStorageData = new SessiontorageData(Prefix.Relocation);
  public useMockService: boolean;

  private plans: ValidAndInvalidPlansArray;
  private selectedPlan: Plan;
  private investments: InvestmentPlanDetail[];
  private simulationInvestmentsDestiny: InvestmentPlanDetail[];
  private simulationInvestmentsOrigin: InvestmentPlanDetail[];
  private simulationId: SimulationResponse['simulationId'];
  private simulationRequestPayload: SimulationRequest;
  private investorProfile: InvestorProfile;
  private elegibleProductsAmount: number;
  private suggestion: Distribution;
  private selectedPlanDetail: PlanDetail;
  private summary: Summary;
  private elegibility: Eligibility;
  private solicitationId: SolicitationResponse['solicitationId'];

  handleUndefinedKey(error: Error | null) {
    if (this.useMockService) {
      return this.mockDiversificationService.handleUndefinedKey(error);
    }

    this.router.navigate(DIVERSIFICATION_ROUTES.PLANS);
    this.notificationService.showError(
      DIVERSIFICATION_SERVICE_MESSAGES.UNDEFINED_KEY
    );
  }

  getSummaryPortfolios(): SummaryPortfolio[] {
    if (this.useMockService) {
      return this.mockDiversificationService.getSummaryPortfolios();
    }

    const { currentPortfolio, simulatedPortfolio } = this.getSummary();
    const plan = this.getSelectedPlanDetails();

    const portfolios: SummaryPortfolio[] = [
      { plan, heading: 'Plano atual', investments: currentPortfolio },
      { plan, heading: 'Plano realocado', investments: simulatedPortfolio }
    ];

    return portfolios;
  }

  getSolicitationId(): number {
    if (this.useMockService) {
      return this.mockDiversificationService.getSolicitationId();
    }

    return this.solicitationId;
  }

  setSolicitationId(id: number): void {
    if (this.useMockService) {
      return this.mockDiversificationService.setSolicitationId(id);
    }

    this.solicitationId = id;
  }

  getSimulationRequestPayload(): SimulationRequest {
    if (this.useMockService) {
      return this.mockDiversificationService.getSimulationRequestPayload();
    }

    return this.simulationRequestPayload;
  }

  setSimulationRequestPayload(payload: SimulationRequest): void {
    if (this.useMockService) {
      return this.mockDiversificationService.setSimulationRequestPayload(
        payload
      );
    }

    this.simulationRequestPayload = payload;
  }

  getElegibility(): Eligibility {
    if (this.useMockService) {
      return this.mockDiversificationService.getElegibility();
    }

    return this.elegibility;
  }

  setElegibility(elegibility: Eligibility): void {
    if (this.useMockService) {
      return this.mockDiversificationService.setElegibility(elegibility);
    }

    this.elegibility = elegibility;
  }

  getSelectedPlanDetails(): PlanDetail {
    if (this.useMockService) {
      return this.mockDiversificationService.getSelectedPlanDetails();
    }

    return this.selectedPlanDetail;
  }

  setSelectedPlanDetails(detail: PlanDetail): void {
    if (this.useMockService) {
      return this.mockDiversificationService.setSelectedPlanDetails(detail);
    }

    this.selectedPlanDetail = detail;
  }

  getSuggestion(): Distribution {
    if (this.useMockService) {
      return this.mockDiversificationService.getSuggestion();
    }

    return this.suggestion;
  }

  setSuggestion(suggestion: Distribution): void {
    if (this.useMockService) {
      return this.mockDiversificationService.setSuggestion(suggestion);
    }

    this.suggestion = suggestion;
  }

  getInvestorProfile(): InvestorProfile {
    if (this.useMockService) {
      return this.mockDiversificationService.getInvestorProfile();
    }

    return this.investorProfile;
  }

  setInvestorProfile(profile: InvestorProfile): void {
    if (this.useMockService) {
      return this.mockDiversificationService.setInvestorProfile(profile);
    }

    this.investorProfile = profile;
  }

  getSimulationId(): number {
    if (this.useMockService) {
      return this.mockDiversificationService.getSimulationId();
    }

    return this.simulationId;
  }

  setSimulationId(id: number): void {
    if (this.useMockService) {
      return this.mockDiversificationService.setSimulationId(id);
    }

    this.simulationId = id;
  }

  setPlans(plans: ValidAndInvalidPlansArray) {
    if (this.useMockService) {
      return this.mockDiversificationService.setPlans(plans);
    }

    this.plans = plans;
  }

  getPlans(): ValidAndInvalidPlansArray {
    if (this.useMockService) {
      return this.mockDiversificationService.getPlans();
    }

    return this.plans;
  }

  getSelectedPlan(): Plan {
    if (this.useMockService) {
      return this.mockDiversificationService.getSelectedPlan();
    }

    return this.selectedPlan;
  }

  setSelectedPlan(plan: Plan): void {
    if (this.useMockService) {
      return this.mockDiversificationService.setSelectedPlan(plan);
    }

    this.selectedPlan = plan;
  }

  getInvestments(): InvestmentPlanDetail[] {
    if (this.useMockService) {
      return this.mockDiversificationService.getInvestments();
    }

    return this.investments;
  }

  setInvestments(investments: InvestmentPlanDetail[]): void {
    if (this.useMockService) {
      return this.mockDiversificationService.setInvestments(investments);
    }

    this.investments = investments;
  }

  getSimulationInvestmentsOrigin(): InvestmentPlanDetail[] {
    if (this.useMockService) {
      return this.mockDiversificationService.getSimulationInvestmentsOrigin();
    }

    return this.simulationInvestmentsOrigin;
  }

  setSimulationInvestmentsOrigin(investments: InvestmentPlanDetail[]): void {
    if (this.useMockService) {
      return this.mockDiversificationService.setSimulationInvestmentsOrigin(
        investments
      );
    }

    this.simulationInvestmentsOrigin = investments;
  }

  getSimulationInvestmentsDestiny(): InvestmentPlanDetail[] {
    if (this.useMockService) {
      return this.mockDiversificationService.getSimulationInvestmentsDestiny();
    }

    return this.simulationInvestmentsDestiny;
  }

  setSimulationInvestmentsDestiny(simulation: InvestmentPlanDetail[]): void {
    if (this.useMockService) {
      return this.mockDiversificationService.setSimulationInvestmentsDestiny(
        simulation
      );
    }

    this.simulationInvestmentsDestiny = simulation;
  }

  getElegibleProductsAmount(): number {
    if (this.useMockService) {
      return this.mockDiversificationService.getElegibleProductsAmount();
    }

    return this.elegibleProductsAmount;
  }

  setElegibleProductsAmount(amount: number): void {
    this.elegibleProductsAmount = amount;
  }

  getSummary(): Summary {
    if (this.useMockService) {
      return this.mockDiversificationService.getSummary();
    }

    return this.summary;
  }

  setSummary(summary: Summary): void {
    if (this.useMockService) {
      return this.mockDiversificationService.setSummary(summary);
    }

    this.summary = summary;
  }

  updateSimulationInvestmentsOrigin(investment?: InvestmentPlanDetail): void {
    if (!investment) return;

    if (this.useMockService) {
      return this.mockDiversificationService.updateSimulationInvestmentsOrigin(
        investment
      );
    }

    if (!this.getSimulationInvestmentsOrigin() || !investment) return;

    const newSimulationInvestments = this.getSimulationInvestmentsOrigin().map(
      (item) => {
        if (item.id === investment.id) return investment;
        return item;
      }
    );

    this.setSimulationInvestmentsOrigin(newSimulationInvestments);
  }

  updateSimulationInvestmentsDestiny(investment?: InvestmentPlanDetail): void {
    if (!investment) return;

    if (this.useMockService) {
      return this.mockDiversificationService.updateSimulationInvestmentsDestiny(
        investment
      );
    }

    if (!this.getSimulationInvestmentsDestiny() || !investment) return;

    const newSimulationInvestments = this.getSimulationInvestmentsDestiny().map(
      (item) => {
        if (item.id === investment.id) return investment;
        return item;
      }
    );

    this.setSimulationInvestmentsDestiny(newSimulationInvestments);
  }

  getAllocatedAndOthersInvestments(
    investments: InvestmentPlanDetail[]
  ): InvestmentsPlan {
    const allocated = investments.filter(
      (investment) => investment.value !== 0
    );
    const others = investments.filter((investment) => investment.value === 0);

    return { allocated, others };
  }

  getSolicitationDetails$(): Observable<Detail> {
    if (this.useMockService) {
      return this.mockDiversificationService.getSolicitationDetails$();
    }

    return this.api.detail(this.authService.getToken(), this.solicitationId);
  }

  getSummaryRentability$(simulationId: number): Observable<PortfolioCompare> {
    if (this.useMockService) {
      return this.mockDiversificationService.getSummaryRentability$(
        simulationId
      );
    }

    return this.api.compare(this.authService.getToken(), simulationId);
  }

  makeSolicitation$(
    payload: SolicitationRequest
  ): Observable<SolicitationResponse> {
    if (this.useMockService) {
      return this.mockDiversificationService.makeSolicitation$(payload);
    }

    return this.api.solicitation(this.authService.getToken(), payload).pipe(
      catchError((error: HttpErro) => {
        this.exceptionService.handleTransactionExceptions(error);
        return EMPTY;
      })
    );
  }

  getSummary$(simulationId: number): Observable<Summary> {
    if (this.useMockService) {
      return this.mockDiversificationService.getSummary$(simulationId);
    }

    return this.api
      .summary(this.authService.getToken(), simulationId)
      .pipe(tap((summary) => this.setSummary(summary)));
  }

  getDistributionSuggestion$(simulationId: number): Observable<Distribution> {
    if (this.useMockService) {
      return this.mockDiversificationService.getDistributionSuggestion$(
        simulationId
      );
    }

    return this.api
      .distribution(this.authService.getToken(), simulationId)
      .pipe(
        tap((suggestion) => {
          this.setSuggestion(suggestion);
          this.setSimulationInvestmentsDestiny(suggestion.investments);
        })
      );
  }

  defineSimulationDistribution$(
    payload: SimulationDestinationDefineRequest
  ): Observable<SimulationDestinationDefineResponse> {
    if (this.useMockService) {
      return this.mockDiversificationService.defineSimulationDistribution$(
        payload
      );
    }

    return this.api.define(this.authService.getToken(), payload);
  }

  simulateDistribution$(
    payload: SimulationRequest
  ): Observable<SimulationResponse> {
    if (this.useMockService) {
      return this.mockDiversificationService.simulateDistribution$();
    }

    return this.api.simulation(this.authService.getToken(), payload).pipe(
      tap(({ simulationId }) => {
        this.setSimulationId(simulationId);
      })
    );
  }

  getAvailableProducts$(): Observable<Eligibility> {
    if (this.useMockService) {
      return this.mockDiversificationService.getAvailableProducts$();
    }

    return this.api
      .plansEligibility(
        this.authService.getToken(),
        +this.getSelectedPlan().registration
      )
      .pipe(tap((elegibility) => this.setElegibility(elegibility)));
  }

  getInvestorProfile$(): Observable<InvestorProfile> {
    if (this.useMockService) {
      return this.mockDiversificationService.getInvestorProfile$();
    }

    return this.api
      .investorProfile(this.authService.getToken())
      .pipe(tap((profile) => this.setInvestorProfile(profile)));
  }

  getInvestmentRentability$(investmentId: number): Observable<Rentability> {
    if (this.useMockService) {
      return this.mockDiversificationService.getInvestmentRentability$();
    }

    return this.api.plansInvestmentsRentability(
      this.authService.getToken(),
      investmentId
    );
  }

  getPlanInvestments$(): Observable<InvestmentsPlan> {
    if (this.useMockService) {
      return this.mockDiversificationService.getPlanInvestments$();
    }

    return this.api
      .plansInvestments(
        this.authService.getToken(),
        this.getSelectedPlan().registration
      )
      .pipe(
        tap((investments) => {
          this.setInvestments([
            ...investments.allocated,
            ...investments.others
          ]);
        })
      );
  }

  getPlanDetails$(): Observable<PlanDetail> {
    if (this.useMockService) {
      return this.mockDiversificationService.getPlanDetails$();
    }

    return this.api
      .plansDetail(
        this.authService.getToken(),
        this.getSelectedPlan().registration
      )
      .pipe(
        tap((detail) => {
          this.setSelectedPlanDetails(detail);
        })
      );
  }

  getValidAndInvalidPlans$(): Observable<ValidAndInvalidPlansArray> {
    if (this.useMockService) {
      return this.mockDiversificationService.getValidAndInvalidPlans$();
    }

    return this.api.plansList(this.authService.getToken()).pipe(
      map((plans) => getValidAndInvalidPlans(plans)),
      tap((plans) => {
        this.setPlans(plans);
      })
    );
  }

  public onSelection(plan: Plan): Observable<any> {
    this.setSelectedPlan(plan);
    this.router.navigate(DIVERSIFICATION_ROUTES.MY_PLAN);
    return of().pipe(take(1));
  }

  public setPlanData(plans: Plan[]): void {
    this.sessionStorageData.setData(this.listId, JSON.stringify(plans));
  }

  public getPlanData(): Plan[] {
    return JSON.parse(this.sessionStorageData.getData(this.listId));
  }
}
