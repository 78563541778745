import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WindowService {
  get window() {
    return window;
  }

  getHistory() {
    return window.history;
  }

  redirectToExternalURL(url: string) {
    this.window.location.href = url;
  }
}
