import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable()
export class PasswordUtils {
  sequentialNumbersValidator(): ValidatorFn {
    const pattern = '0123456789876543210';
    return (password: AbstractControl): ValidationErrors | null => {
      if (pattern.includes(password.value)) {
        return { sequentialNumbers: true };
      } else {
        return null;
      }
    };
  }

  repeatedNumbersValidator() {
    const regexPattern = /(\d)(?:\D*\1){3,}/;
    return (password: AbstractControl): ValidationErrors | null => {
      if (regexPattern.test(password.value)) {
        return { repeatedNumbers: true };
      }
      return null;
    };
  }
}
