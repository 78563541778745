export const DIVERSIFICATION_DEFAULT_ROUTE = 'diversificacao';

export const DIVERSIFICATION_SCREENS = {
  PLANS: 'planos',
  MY_PLAN: 'meu-plano',
  FUNDS_DISTRIBUTION_ORIGIN: 'realocar-origem',
  FUNDS_DISTRIBUTION_DESTINY: 'realocar-destino',
  SUMMARY: 'resumo',
  PASSWORD: 'autorizacao',
  SUCCESS: 'sucesso'
};

export const DIVERSIFICATION_ROUTES = {
  PLANS: [DIVERSIFICATION_DEFAULT_ROUTE, DIVERSIFICATION_SCREENS.PLANS],
  MY_PLAN: [DIVERSIFICATION_DEFAULT_ROUTE, DIVERSIFICATION_SCREENS.MY_PLAN],
  FUNDS_DISTRIBUTION_ORIGIN: [
    DIVERSIFICATION_DEFAULT_ROUTE,
    DIVERSIFICATION_SCREENS.FUNDS_DISTRIBUTION_ORIGIN
  ],
  FUNDS_DISTRIBUTION_DESTINY: [
    DIVERSIFICATION_DEFAULT_ROUTE,
    DIVERSIFICATION_SCREENS.FUNDS_DISTRIBUTION_DESTINY
  ],
  SUMMARY: [DIVERSIFICATION_DEFAULT_ROUTE, DIVERSIFICATION_SCREENS.SUMMARY],
  PASSWORD: [DIVERSIFICATION_DEFAULT_ROUTE, DIVERSIFICATION_SCREENS.PASSWORD],
  SUCCESS: [DIVERSIFICATION_DEFAULT_ROUTE, DIVERSIFICATION_SCREENS.SUCCESS]
};
