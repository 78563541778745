import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PlanSummary } from '@app/home/statement.model';
import { StatementService } from '@app/home/statement.service';
import { first } from 'rxjs/operators';
import { PlanService } from '../plan.service';

@Component({
  selector: 'app-files-page',
  templateUrl: './files-page.component.html',
  styleUrls: ['./files-page.component.scss']
})
export class FilesPageComponent implements OnInit {
  registration: string;
  pdfUrl: string;
  showRegulamentarStatement: boolean;
  plan: PlanSummary;
  grantDate: string;

  constructor(
    private planService: PlanService,
    private router: Router,
    private statementService: StatementService,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.registration = this.router.url.split('/')[2];
    this.populatePlanData();

    this.showRegulamentarStatement = !this.plan?.servicesToHide?.includes(
      'EXTRATO_REGULAMENTAR'
    );

    this.loadPDFDownloadUrl();
  }

  loadPDFDownloadUrl(): void {
    this.planService
      .getRegulatoryStatement(this.registration)
      .pipe(first())
      .subscribe((res) => {
        this.pdfUrl = `https://www1.brasilprev.com.br/redirecionar?url=${res.url}`;
        this.ref.markForCheck();
      });
  }

  public populatePlanData() {
    const data = this.statementService.getPlanData();
    this.plan = data.filter(
      (item) => item.registration === this.registration
    )[0];

    if (this.plan && this.plan.grantDate) {
      this.grantDate = this.plan.grantDate.slice(6, this.plan.grantDate.length);
    }
  }
}
