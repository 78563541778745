const navigationSvgIcons = [
  {
    name: 'arrow-back-ios',
    path: '/assets/design-system/icons/navigation/arrow_back_ios.svg'
  },
  {
    name: 'arrow-forward-ios',
    path: '/assets/design-system/icons/navigation/arrow_forward_ios.svg'
  },
  {
    name: 'check',
    path: '/assets/design-system/icons/navigation/check.svg'
  },
  {
    name: 'east',
    path: '/assets/design-system/icons/navigation/east.svg'
  },
  {
    name: 'expand-more',
    path: '/assets/design-system/icons/navigation/expand_more.svg'
  },
  {
    name: 'refresh',
    path: '/assets/design-system/icons/navigation/refresh.svg'
  },
  {
    name: 'chevron-right',
    path: '/assets/design-system/icons/navigation/chevron_right.svg'
  },
  {
    name: 'close',
    path: '/assets/design-system/icons/navigation/close.svg'
  }
];

export default navigationSvgIcons;
