import { Injectable } from '@angular/core';
import { AuthService } from '@app/auth/auth.service';
import { Plan, PlanDetail, Service } from '@app/core/checkup-bff';
import { Prefix } from '@app/shared/models/prefix.model';
import { SessiontorageData } from '@app/shared/utils/sessionStorage';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PlanService {
  readonly planId = 'plan:';
  private sessionStorageData = new SessiontorageData(Prefix.Checkup);

  constructor(
    private nswagBffService: Service,
    private authService: AuthService
  ) {}

  private get authToken(): string {
    return this.authService.getToken();
  }

  list(): Observable<Plan[]> {
    return this.nswagBffService.plansList(this.authToken).pipe(first());
  }

  getDetails(registration: string): Observable<PlanDetail> {
    return this.nswagBffService.plan(this.authToken, registration);
  }

  public setPlanData(plan: Plan[]) {
    this.sessionStorageData.setData(this.planId, JSON.stringify(plan));
  }

  public getPlanData() {
    return JSON.parse(this.sessionStorageData.getData(this.planId));
  }

  public deletePlanData() {
    this.sessionStorageData.deleteData(this.planId);
  }
}
