import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { OpinIconComponent } from './icon.component';

@NgModule({
  declarations: [OpinIconComponent],
  exports: [OpinIconComponent],
  imports: [SharedModule]
})
export class OpinIconModule {}
