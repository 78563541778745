import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DetailPlan } from '@app/core/extra-contribution-bff';
import { StatementService } from '@app/home/statement.service';
import { FilterFundsModalComponent } from '@app/plan/funds-page/filter-funds-modal/filter-funds-modal.component';
import { PlanService } from '@app/plan/plan.service';
import { ExtraContributionData } from '@app/upselling/extra-contribution/extra-contribution.model';
import { ExtraContributionService } from '@app/upselling/extra-contribution/extra-contribution.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-profitability',
  templateUrl: './profitability.component.html',
  styleUrls: ['./profitability.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      )
    ])
  ]
})
export class ProfitabilityComponent implements OnInit, OnDestroy {
  @Input() referenceDate;
  @Input() profitability;
  @Input() availableProfitability;
  @Input() plan;
  @Input() registration;
  subscription: Subscription;
  investmentList;
  availableInvestmentList = new MatTableDataSource();
  expandedElement;
  defaultTableLines = 8;
  showMoreLines = 5;
  isShowMoreLinesVisible = true;
  completeFundsList;
  daTable_filtered;
  countFilter = 0;
  filterData;
  propSorted: string;
  orderBy: string;
  isComparatorVisible = false;
  displayedColumns: string[] = [
    'name',
    'riskGrade',
    'months60',
    'months48',
    'months36',
    'months24',
    'months12',
    'currencyYear',
    'pastMonth',
    'currentMonth',
    'expand'
  ];
  displayedColumnsProfitability: string[] = [
    'name',
    'riskGrade',
    'months60',
    'months48',
    'months36',
    'months24',
    'months12',
    'currencyYear',
    'pastMonth',
    'currentMonth',
    'expand'
  ];
  investmentsNames: string[];

  constructor(
    private extraCService: ExtraContributionService,
    private router: Router,
    private planService: PlanService,
    private statementService: StatementService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.loadDataTable();
    this.subscription = this.profitability.subscribe((res) => {
      const data = [];
      data.push({
        name: 'RENTABILIDADE DA CARTEIRA',
        percentageCurrentMonth: res.averagePercentageCurrentMonth,
        percentagePastMonth: res.averagePercentagePastMonth,
        percentageAccumulatedYear: res.averagePercentageAccumulatedYear,
        percentageAccumulated12: res.averagePercentageAccumulated12,
        percentageAccumulated24: res.averagePercentageAccumulated24,
        percentageAccumulated36: res.averagePercentageAccumulated36,
        percentageAccumulated48: res.averagePercentageAccumulated48,
        percentageAccumulated60: res.averagePercentageAccumulated60
      });
      data.push(...res.investmentList);
      this.investmentList = data;
    });

    const planData = this.statementService.getPlanData();
    this.plan = planData.filter(
      (item) => item.registration === this.registration
    )[0];
  }

  loadDataTable() {
    this.availableProfitability.subscribe((res) => {
      this.investmentsNames = [];
      this.completeFundsList = res.availableInvestmentList.map((item) => {
        this.investmentsNames.push(item.name);
        return {
          riskOrder: this.addRiskOrder(item.riskGrade),
          ...item
        };
      });
      this.createFundsTable();
    });
  }

  addRiskOrder(risk: string): number {
    switch (risk) {
      case 'MUITO BAIXO':
        return 0;
      case 'BAIXO':
        return 1;
      case 'MÉDIO':
        return 2;
      case 'ALTO':
        return 3;
      case 'MUITO ALTO':
        return 4;
    }
  }

  public navigateToExtraContribution() {
    this.extraCService
      .getPlanDetails(this.registration)
      .subscribe((planDetail: DetailPlan) => {
        this.extraCService.setPlanData({
          planDetail: {
            ...planDetail,
            planGoal: this.planService.getObjectiveImage(this.plan.objective)
          }
        } as ExtraContributionData);
        this.router.navigate([
          `upselling/extra-contribution/simulator/${this.registration}`
        ]);
      });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.availableInvestmentList.filter = filterValue.trim().toLowerCase();
  }

  showMoreFunds() {
    const numberOfLines =
      this.availableInvestmentList.data.length + this.showMoreLines;
    this.createFundsTable(this.orderBy, numberOfLines);
  }

  openFilterModal() {
    const dialogRef = this.dialog.open(FilterFundsModalComponent, {
      width: '600px',
      data: {
        referenceDate: this.referenceDate,
        filterData: this.filterData
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.filterFundsTable(result);
    });
  }

  filterFundsTable(filterData) {
    if (!filterData) {
      this.countFilter = 0;
      this.loadDataTable();
      this.filterData = {
        order: '',
        rentabilities: '',
        risk: {
          muito_baixo: false,
          baixo: false,
          medio: false,
          alto: false,
          muito_alto: false
        },
        categories: {
          renda_fixa: false,
          multimercado: false,
          exclusivo: false,
          ciclo_de_vida: false
        }
      };
      return;
    }
    this.filterData = filterData;
    this.countFilter = 0;

    const filteredList = [];

    const hasRiskFilter = Object.keys(filterData.risk).some(
      (item) => filterData.risk[item] === true
    );
    const hasCategoryFilter = Object.keys(filterData.categories).some(
      (item) => filterData.categories[item] === true
    );

    if (hasRiskFilter && hasCategoryFilter) {
      const riskList = this.filterList(
        this.completeFundsList,
        filterData.risk,
        'riskGrade'
      );
      filteredList.push(
        ...this.filterList(riskList, filterData.categories, 'category')
      );
    } else {
      if (hasRiskFilter) {
        filteredList.push(
          ...this.filterList(
            this.completeFundsList,
            filterData.risk,
            'riskGrade'
          )
        );
      }

      if (hasCategoryFilter) {
        filteredList.push(
          ...this.filterList(
            this.completeFundsList,
            filterData.categories,
            'category'
          )
        );
      }
    }

    this.daTable_filtered = filteredList;

    if (this.filterData.order) {
      if (this.filterData.order === 'maior_rentabilidade') {
        this.createFundsTable(this.filterData.rentabilities);
        this.orderBy = this.filterData.rentabilities;
      } else {
        this.createFundsTable(this.filterData.order);
        this.orderBy = this.filterData.order;
      }
      return;
    }

    this.createFundsTable();
  }

  filterList(fundsList, filterObj, filterBy) {
    const filteredList = [];
    Object.keys(filterObj).map((item) => {
      if (filterObj[item]) {
        this.countFilter++;
        const itemsValue = fundsList.filter(
          (fund) =>
            fund[filterBy]
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '') === item.split('_').join(' ')
        );
        filteredList.push(...itemsValue);
      }
    });
    return filteredList;
  }

  createFundsTable(oderBy?: string, lineCount?: number): void {
    let fundsList = this.countFilter
      ? this.daTable_filtered
      : this.completeFundsList;
    if (!lineCount) {
      lineCount = this.defaultTableLines;
    }
    if (oderBy) {
      const orderList = [...fundsList];
      if (oderBy === 'name' || oderBy === 'riskOrder') {
        fundsList = orderList.sort((a, b) =>
          a[oderBy] < b[oderBy] ? -1 : a[oderBy] > b[oderBy] ? 1 : 0
        );
      } else {
        fundsList = orderList.sort((a, b) =>
          a[oderBy] > b[oderBy] ? -1 : a[oderBy] < b[oderBy] ? 1 : 0
        );
      }
    }

    if (fundsList.length > lineCount) {
      this.availableInvestmentList = new MatTableDataSource(
        fundsList.slice(0, lineCount)
      );
      this.isShowMoreLinesVisible = true;
    } else {
      this.availableInvestmentList = new MatTableDataSource(fundsList);
      this.isShowMoreLinesVisible = false;
    }
  }

  showInvestmentComparator() {
    this.isComparatorVisible = !this.isComparatorVisible;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
