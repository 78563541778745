import { CurrencyPipe } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { environment } from '@env/environment';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { BoletosComponent } from './boletos/boletos.component';
import { ErrorsHandler } from './core/errors';
import { GraphQLModule } from './graphql.module';
import { OpenInsuranceSharedModule } from './open-insurance/shared/shared.module';
import { PlanModule } from './plan/plan.module';
import { RedirectDialogComponent } from './redirect-dialog/redirect-dialog.component';
import { ScheduleGuard } from './shared/guard/schedule.guard';
import { EnvironmentService } from './shared/service/environment.service';
import { ScheduleService } from './shared/service/schedule.service';

@NgModule({
  declarations: [AppComponent, RedirectDialogComponent, BoletosComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    PlanModule,
    AuthModule,
    CoreModule,
    SharedModule,
    GraphQLModule,
    OpenInsuranceSharedModule,
    MatDialogModule,
    RecaptchaV3Module
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: ErrorsHandler
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey
    },
    ScheduleGuard,
    ScheduleService,
    CurrencyPipe,
    EnvironmentService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
