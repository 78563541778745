import { OpinAlertModule } from './alert/alert.module';
import { OpinButtonWithIconComponentModule } from './button-with-icon/button-with-icon.module';
import { OpinCardListInfoModule } from './card-list-information/card-list-information.module';
import { OpinCardInfoModule } from './card-simple-information/card-simple-information.module';
import { OpinConsentCardComponentModule } from './consent-card/consent-card.module';
import { OpinDialogDetailsModule } from './dialog-details/dialog-details.module';
import { OpinDialogFAQModule } from './dialog-faq/dialog-faq.module';
import { OpinHeaderComponentModule } from './header/header.module';
import { OpinInputModule } from './input/input.module';
import { OpinCardModule } from './opin-card/opin-card.module';
import { OpinRevokeSharingDialogModule } from './revoke-sharing-dialog/revoke-sharing-dialog.module';
import { OpinSharingCardComponentModule } from './sharing-card/sharing-card.module';
import { OpinSharingHeaderModule } from './sharing-header/sharing-header.module';

const COMPONENTS = [
  OpinCardModule,
  OpinAlertModule,
  OpinCardInfoModule,
  OpinCardListInfoModule,
  OpinDialogDetailsModule,
  OpinDialogFAQModule,
  OpinSharingHeaderModule,
  OpinHeaderComponentModule,
  OpinRevokeSharingDialogModule,
  OpinConsentCardComponentModule,
  OpinSharingCardComponentModule,
  OpinInputModule,
  OpinButtonWithIconComponentModule
];

export default COMPONENTS;
