import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonComponent } from './button/button.component';
import { LoadingComponent } from './loading/loading.component';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { StepperComponent } from './stepper/stepper.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { TooltipDirective } from './tooltip/tooltip.directive';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { SliderComponent } from './slider/slider.component';

import { RadioModule } from './radio/radio.module';
import { AccordionModule } from './accordion/accordion.module';
import { CircularStepperComponent } from './circular-stepper/circular-stepper.component';

@NgModule({
  declarations: [
    ButtonComponent,
    LoadingComponent,
    StepperComponent,
    TooltipComponent,
    TooltipDirective,
    CheckboxComponent,
    SliderComponent,
    CircularStepperComponent
  ],
  exports: [
    ButtonComponent,
    LoadingComponent,
    StepperComponent,
    TooltipComponent,
    TooltipDirective,
    CheckboxComponent,
    SliderComponent,
    RadioModule,
    AccordionModule,
    CircularStepperComponent
  ],
  imports: [CommonModule, AngularMaterialModule, RadioModule, AccordionModule]
})
export class DesignSystemModule {}
