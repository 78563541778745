import { Injectable } from '@angular/core';
@Injectable()
export class PhoneUtils {
  public format(phone: string): string {
    return phone.length > 9
      ? this.formatWithDDD(phone)
      : this.formatWithoutDDD(phone);
  }

  private formatWithDDD(phone: string): string {
    const maskPhone =
      phone.length < 10
        ? new RegExp(/^(\d{2})(\d{4})(\d{4})$/)
        : new RegExp(/^(\d{2})(\d{5})(\d{4})$/);
    return phone.replace(maskPhone, '($1) $2-$3');
  }

  private formatWithoutDDD(phone: string): string {
    const maskPhone =
      phone.length > 8
        ? new RegExp(/^(\d{5})(\d{4})$/)
        : new RegExp(/^(\d{4})(\d{4})$/);
    return phone.replace(maskPhone, '$1-$2');
  }
}
