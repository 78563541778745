import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CpfUtils } from '@app/core/validators/cpf.validator';
import { filter, finalize } from 'rxjs/operators';
import { CPF } from '../auth.model';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['../auth-components.scss']
})
export class PasswordComponent implements OnInit, OnDestroy {
  isPasswordHidden = true;
  form: UntypedFormGroup;
  isLoading: boolean;
  modalWidth: string;
  cpf = this.route.snapshot.params.cpf;
  cpfObj = {
    digitis: this.cpf,
    formated: this.cpfUtils.format(this.cpf)
  } as CPF;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private cpfUtils: CpfUtils
  ) {}

  ngOnInit(): void {
    this.buildForm();
    this.startListening();
  }

  ngOnDestroy(): void {
    this.stopListening();
  }

  togglePasswordVisibility() {
    this.isPasswordHidden = !this.isPasswordHidden;
  }

  private buildForm(): void {
    this.form = this.fb.group({
      fakePassword: [
        '',
        [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.maxLength(8),
          Validators.minLength(8)
        ]
      ],
      password: [{ value: '', disabled: true }]
    });
  }

  removeLoginData() {
    this.authService.deleteLoginData();
    this.cpfObj = new CPF();
    this.router.navigate(['auth']);
  }

  randomIntFromInterval(): number {
    return Math.floor(Math.random() * (9 - 0 + 1) + 0);
  }

  validatePassword(): boolean {
    const password = [];
    this.form
      .get('fakePassword')
      .value.split('')
      .forEach((value) => {
        password.push(value, this.randomIntFromInterval());
      });
    this.form.get('password').patchValue(password.join(''));
    return this.form.get('password').value.length === 16;
  }

  onSubmit() {
    if (this.form.valid && this.validatePassword()) {
      this.isLoading = true;
      this.authService
        .preLogin(this.cpfObj.digitis, this.form.get('password').value)
        .pipe(
          filter((res) => res !== null),
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe(() => {
          sessionStorage.setItem('username', this.cpfObj.digitis);
          // eslint-disable-next-line @typescript-eslint/dot-notation
          if (window['ReactNativeWebView']) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            window['ReactNativeWebView'].postMessage(
              JSON.stringify({
                username: this.cpfObj.digitis,
                password: this.form.get('fakePassword').value
              })
            );
          }
          this.router.navigate(['auth', 'ask-code']);
        });
    }
  }

  startListening(): void {
    window.addEventListener('message', (event) => {
      if (typeof event.data === 'string') {
        try {
          const password = event.data;
          this.form.get('fakePassword').patchValue(password);
        } catch (error) {
          console.error('Erro ao atualizar dados:', error);
        }
      }
    });
  }

  stopListening(): void {
    window.removeEventListener('message', () => {});
  }
}
