import { NgModule } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { OpinToggleSliderComponent } from './toggle-slider.component';

@NgModule({
  declarations: [OpinToggleSliderComponent],
  exports: [OpinToggleSliderComponent],
  imports: [MatSlideToggleModule]
})
export class OpinToggleSliderComponentModule {}
