import { Feature } from '@app/plan-manager/plan-manager.model';

export class MenuState {
  isMenuOpen: boolean;
  isProfileOpen: boolean;
  isASubMenuOpen?: boolean;

  constructor(
    isMenuOpen?: boolean,
    isProfileOpen?: boolean,
    isSubMenuOpen?: boolean
  ) {
    this.isMenuOpen = isMenuOpen ?? false;
    this.isProfileOpen = isProfileOpen ?? false;
    this.isASubMenuOpen = isSubMenuOpen ?? false;
  }
}

export class NavigationItem {
  name: string;
  url?: string;
  callback?: Callback;
}

export enum Callback {
  OPIN = 'OPIN',
  INCOME_TAX = 'INCOME_TAX',
  OPEN_PAYMENTS = 'OPEN_PAYMENTS',
  LOGOUT = 'LOGOUT'
}

export enum MenuGroups {
  MANAGE_PLANS = 'MANAGE_PLANS',
  OTHER_FEATURES = 'OTHER_FEATURES'
}

export const menuGroups = {
  MANAGE_PLANS: [
    Feature.BALANCE,
    Feature.NEW_CONTRIBUTION,
    Feature.DEPARTURE_DATE,
    Feature.BOLETOS,
    Feature.CHECKUP,
    Feature.DEFINE_INCOME,
    Feature.RELOCATION
  ],
  OTHER_FEATURES: ['SURRENDER', 'VERIFY_SURRENDER', 'INCOME_TAX']
};
