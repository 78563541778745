import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { UserData } from '@app/core/auth/user.model';
import {
  Simulation,
  SimulationService,
  SuccessResponseSeeSimulation
} from '@app/core/bpApi';
import { BackButtonSettings } from '@app/shared/components/sub-header/sub-header.model';
import { makeDateFromBrazilianDate } from '@app/shared/utils/date';
import { InvestorProfile } from '@app/user/user.model';
import { differenceInYears } from 'date-fns';
import { Observable } from 'rxjs';
import { PlanObjective } from '../plan.model';
import { PlanService } from '../plan.service';

@Component({
  selector: 'app-simulation',
  templateUrl: './simulation.component.html',
  styleUrls: ['./simulation.component.scss']
})
export class SimulationComponent implements OnInit {
  userName: string;
  investorProfile: string;
  remainingYears: number;
  simulationForm: UntypedFormGroup;
  simulation$: Observable<SuccessResponseSeeSimulation>;
  planObjectiveValueList: string[] = Object.keys(PlanObjective);
  age: number;
  objectiveRetirementMsg: string;
  objectiveNotRetirementMsg: string;
  backButtonSettings: BackButtonSettings;
  constructor(
    private fb: UntypedFormBuilder,
    private simulationService: SimulationService,
    private planService: PlanService,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.userName = this.authService.getUserData(UserData.Name);
    const investorProfile =
      InvestorProfile[this.authService.getUserData(UserData.InvestorProfile)];
    if (investorProfile === undefined) {
      this.router.navigate(['/profile/investor']);
      return;
    }
    this.investorProfile = investorProfile.replace(/\. \(([^\)]+)\)/, '');
    this.age = differenceInYears(
      new Date(),
      makeDateFromBrazilianDate(
        this.authService.getUserData(UserData.BirthDate)
      )
    );
    this.objectiveNotRetirementMsg = `A previdência privada é um investimento com melhores resultados a médio
                                      e longo prazo, 6 anos é o tempo mínimo indicado,
                                      mas você pode investir a partir de 1 ano. A idade de saída máxima é de 99 anos,
                                      neste caso, seu tempo de contribuição precisa
                                      ser no máximo até ${99 - this.age} anos.`;
    this.objectiveRetirementMsg = `Com quantos anos você gostaria de se aposentar? Lembre-se que essa idade deve ser maior do que a sua
                                  atual, ou seja à partir ${this.age + 1} anos,
                                  até o máximo permitido para a idade de saída, que é de 99 anos.`;

    this.buildForm();
    this.backButtonSettings = {
      title: 'Voltar'
    };
  }
  private buildForm() {
    this.simulationForm = this.fb.group({
      objective: ['', Validators.required],
      investmentDuration: [
        '',
        [Validators.required, Validators.min(1), Validators.max(99 - this.age)]
      ],
      monthlyContribution: ['', [Validators.required, Validators.min(200)]],
      objectiveAmount: ''
    });
    this.simulationForm.get('objective').valueChanges.subscribe((value) => {
      const investmentDuration = this.simulationForm.get('investmentDuration');
      if (value === 'RETIREMENT') {
        investmentDuration.clearValidators();
        investmentDuration.setValidators([
          Validators.required,
          Validators.min(this.age + 1),
          Validators.max(99)
        ]);
        investmentDuration.updateValueAndValidity();
      } else {
        investmentDuration.clearValidators();
        investmentDuration.setValidators([
          Validators.required,
          Validators.min(1),
          Validators.max(99 - this.age)
        ]);
        investmentDuration.updateValueAndValidity();
      }
    });
  }
  getPlanObjective(planObjectiveValue: string): string {
    return PlanObjective[planObjectiveValue];
  }
  getPlanObjectiveImage(objective: PlanObjective): string {
    return this.planService.getObjectiveImage(objective);
  }
  onSubmit(): void {
    const idadeSaida =
      this.simulationForm.get('objective').value === 'RETIREMENT'
        ? this.simulationForm.get('investmentDuration').value
        : this.age + this.simulationForm.get('investmentDuration').value;
    const dataNascimentoProponente = this.authService.getUserData(
      UserData.BirthDate
    );
    const numeroCPFProponente = this.authService
      .getUserData(UserData.Cpf)
      .replace(/\D/g, '');
    const codigoGeneroProponente =
      this.authService.getUserData(UserData.Gender) === 'MASCULINO' ? 1 : 2;
    let idPerfilInvestidor: number;
    switch (this.investorProfile) {
      case InvestorProfile.CONSERVATIVE:
        idPerfilInvestidor = 1;
        break;
      case InvestorProfile.EXPLORER:
        idPerfilInvestidor = 2;
        break;
      case InvestorProfile.STRATEGIST:
        idPerfilInvestidor = 3;
        break;

      default:
        idPerfilInvestidor = 1;
        break;
    }
    const payload = {
      codigoCanalDistribuicao: 2,
      codigoCanalVenda: 1,
      valorContribuicao: this.simulationForm.get('monthlyContribution').value,
      codigoPeriodicidade: 1,
      idadeSaida,
      codigoTipoDeclaracaoIR: 2,
      nomeProponente: this.userName,
      numeroCPFProponente,
      codigoGeneroProponente,
      dataNascimentoProponente,
      idPerfilInvestidor
    } as unknown as Simulation;
  }
}
