import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared';
import { AskTwofaTokenComponent } from './ask-twofa-token/ask-twofa-token.component';
import { AskCodeComponent } from './auth-code/ask-code/ask-code.component';
import { CheckCodeComponent } from './auth-code/check-code/check-code.component';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthComponent } from './auth.component';
import { CheckTwofaTokenComponent } from './check-twofa-token/check-twofa-token.component';
import { CPFComponent } from './cpf/cpf.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { LoginTypeComponent } from './login-type/login-type.component';
import { CodeVerificationComponent } from './new-password/code-verification/code-verification.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { SelectContactComponent } from './new-password/select-contact/select-contact.component';
import { SendCpfComponent } from './new-password/send-cpf/send-cpf.component';
import { SetPasswordComponent } from './new-password/set-password/set-password.component';
import { PasswordComponent } from './password/password.component';
import { RedirectBbComponent } from './redirect-bb/redirect-bb.component';
import { RedirectModalComponent } from './redirect-modal/redirect-modal.component';
import { VirtualKeyboardComponent } from './virtual-keyboard/virtual-keyboard.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { WppModalComponent } from './wpp-modal/wpp-modal.component';

@NgModule({
  declarations: [
    AuthComponent,
    CPFComponent,
    NewPasswordComponent,
    WelcomeComponent,
    SelectContactComponent,
    CodeVerificationComponent,
    SetPasswordComponent,
    SendCpfComponent,
    RedirectBbComponent,
    RedirectModalComponent,
    WppModalComponent,
    VirtualKeyboardComponent,
    PasswordComponent,
    AskTwofaTokenComponent,
    CheckTwofaTokenComponent,
    AskCodeComponent,
    CheckCodeComponent,
    ErrorPageComponent,
    LoginTypeComponent
  ],
  exports: [AskTwofaTokenComponent, CheckTwofaTokenComponent],
  imports: [CommonModule, AuthRoutingModule, SharedModule, RouterModule]
})
export class AuthModule {}
