import { NgModule } from '@angular/core';
import { NgChartsModule } from 'ng2-charts';
import { BpcComparisionChartComponent } from './bpc-comparision-chart.component';
import { ChartComponent } from './chart/chart.component';

@NgModule({
  declarations: [BpcComparisionChartComponent, ChartComponent],
  imports: [NgChartsModule],
  exports: [BpcComparisionChartComponent, ChartComponent, NgChartsModule]
})
export class BpcComparisionChartModule {}
