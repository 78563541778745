<div
  class="container"
  [ngClass]="{
    'centralized-text': options.centralizeText,
    wide: options.wide,
    'white-text': options.whiteText
  }"
>
  <h1 *ngIf="options.showTitle">Se precisar, entre em contato conosco:</h1>
  <article>
    <section>
      <h2>Central de Relacionamento</h2>
      <small>Dúvidas cadastrais, contratuais e consultas aos planos.</small>

      <div>
        <a href="tel:40047170">4004 7170</a>
        <p>Capitais e regiões metropolitanas</p>
      </div>

      <div>
        <a href="tel:08007297170">0800 729 7170</a>
        <p>Demais localidades</p>
      </div>
    </section>

    <section>
      <h2>SAC</h2>
      <small>Informações gerais, sugestões, reclamações e cancelamentos.</small>

      <div>
        <a href="tel:08007297191">0800 729 7191</a>
        <p>Qualquer localidade</p>
      </div>
    </section>

    <section>
      <h2>Atendimento a pessoas com deficiência auditiva ou de fala</h2>

      <div>
        <a href="tel:08007290150">0800 729 0150</a>
        <p>Qualquer localidade</p>
      </div>
    </section>

    <footer>
      <mat-divider *ngIf="!options.whiteText"></mat-divider>

      <strong
        >Atendimento das 8h às 20h, de segunda a sexta-feira, exceto
        feriados.</strong
      >
    </footer>
  </article>
</div>
