import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {
  get(name: string): string | undefined {
    const match = document.cookie.match(
      new RegExp('(^| )' + name + '=([^;]+)')
    );
    return match ? match[2] : undefined;
  }

  set(
    name: string,
    value: string,
    expireMinutes: number = 0,
    path: string = '/'
  ): void {
    const currentDate = new Date();
    const expiringDate = new Date(
      currentDate.getTime() + expireMinutes * 60 * 1000
    );
    const expires = `expires=${expiringDate.toUTCString()}`;
    const cookiePath: string = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}${cookiePath}`;
  }

  delete(name: string): void {
    this.set(name, '', -1);
  }
}
