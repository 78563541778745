import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PlatformStore  {
  constructor(private router: Router) {}

  resolve(): Observable<void> {
    const url = new URL(window.location.href);
    const platformSearchParam = url.searchParams.get('platform');

    if (platformSearchParam) {
      localStorage.setItem('platform', platformSearchParam);
    }

    this.router.navigate(['auth']);

    return;
  }
}
