import { Injectable } from '@angular/core';
import { Plan } from '@app/core/departure-date-bff';
import { PlanService } from '@app/plan/plan.service';
import { IneligiblePlans } from '@app/upselling/extra-contribution/extra-contribution.model';
import {
  BottomContent,
  PlanCard
} from '../components/registration-card/registration-card.model';

@Injectable({
  providedIn: 'root'
})
export class CardService {
  constructor(private planService: PlanService) {}

  public convertToPlanCard(plan: Plan, buttonText?: string): PlanCard {
    return {
      type: `${plan.planType} - ${plan.planHiringType}`,
      name: plan.planName,
      registration: plan.registration,
      balance: plan.balance,
      image: this.planService.getObjectiveImage(plan.planGoal),
      buttonText,
      isDisabled: !plan.isEligible,
      bottomContent: this.getBottomContent(plan)
    };
  }

  private getBottomContent(plan: Plan): BottomContent | undefined {
    const isPlanTypeNotElegible =
      BottomContent[IneligiblePlans[plan.planType]] ===
      BottomContent.notEligible;
    const isHiringTypeNotElegible =
      BottomContent[IneligiblePlans[plan.planHiringType]] ===
      BottomContent.notEligible;
    const isNotElegible = isPlanTypeNotElegible || isHiringTypeNotElegible;

    return isNotElegible ? BottomContent.notEligible : undefined;
  }

  public separateEnabledFromDisabled(registrations: PlanCard[]): {
    enabled: PlanCard[];
    disabled: PlanCard[];
  } {
    return registrations.reduce(
      (acc, cur) => {
        const list = cur.isDisabled ? 'disabled' : 'enabled';
        acc[list].push(cur);
        return acc;
      },
      {
        enabled: [],
        disabled: []
      }
    );
  }
}
