<div class="custom-table">
  <div class="custom-table__row" *ngFor="let item of info">
    <span class="custom-table__title">{{
      keyList[item[0]] ? keyList[item[0]] : item[0]
    }}</span>
    <span *ngIf="!isNumber(item[1]); else number" class="custom-table__value">{{
      item[1]
    }}</span>
    <ng-template #number>
      <span
        #number
        class="custom-table__value"
        [appPrivacy]="item[1] | currency"
        >{{ item[1] }}</span
      >
    </ng-template>
  </div>
</div>
