import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpError as HttpError3 } from '@app/core/departure-date-bff';
import { HttpError as HttpError4 } from '@app/core/extra-contribution-bff';
import { HttpError as HttpError2 } from '@app/core/new-contribution-bff';
import { HttpErro } from '@app/core/new-diversification';
import { NotificationService } from '@app/core/notification/notification.service';
import { HttpError } from '@app/core/validation-bff';
import { Steps } from '@app/transaction-password/transaction-password.model';
import { TransactionPassword } from '@app/transaction-password/transaction-password.service';
import { EMPTY, Observable, throwError } from 'rxjs';
import { ErrorMessage } from './exception.model';

@Injectable({
  providedIn: 'root'
})
export class ExceptionService {
  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private transactionPasswordService: TransactionPassword
  ) {}

  public handleTransactionExceptions(
    error: HttpError | HttpError2 | HttpError3 | HttpError4 | HttpErro
  ): Observable<never | HttpError> {
    if (ErrorMessage[error.type]) {
      const action = this.callAction[error.type] ?? this.callAction.DEFAULT;
      this.notificationService.showError(ErrorMessage[error.type], 'X', {
        duration: 0
      });
      action();
      return EMPTY;
    }
    return throwError(error);
  }

  private callAction = {
    DEFAULT: () => this.transactionPasswordService.reset(),
    INVALID_CODE: () =>
      (this.transactionPasswordService.selectedIndex = Steps.ASK_CODE),
    TRANSACTION_PASSWORD_INVALID: () =>
      (this.transactionPasswordService.selectedIndex = Steps.PASSWORD),
    TRANSACTION_PASSWORD_BLOCKED: () =>
      this.transactionPasswordService.reset(true),
    DOUBLE_TRANSACTION: () =>
      this.navigateTo(['upselling', 'extra-contribution', 'list']),
    SOLICITATION_ALREADY_REGISTERED: () =>
      this.navigateTo(['upselling', 'extra-contribution', 'list'])
  };

  private navigateTo(url: string[]) {
    this.router.navigate(url);
  }
}
