import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { NewPasswordSuccess } from '@app/auth/auth.model';
import { AuthService } from '@app/auth/auth.service';
import { AuthData } from '@app/core/auth/auth.model';
import { PasswordUtils } from '@app/core/validators/loginPassword.validator';
import { finalize, first } from 'rxjs/operators';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['../../auth-components.scss']
})
export class SetPasswordComponent implements OnInit {
  setPasswordForm: UntypedFormGroup;
  isLoading: boolean;
  passwordChanged = false;
  chosenChannel: string;
  isPasswordHidden = true;

  get formErrors(): ValidationErrors {
    return this.setPasswordForm.controls.password.errors;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private authService: AuthService,
    private passwordUtils: PasswordUtils
  ) {}

  ngOnInit() {
    this.buildForm();
    this.chosenChannel = this.authService.getAuthData(AuthData.ChosenChannel);
  }

  private buildForm(): void {
    this.setPasswordForm = this.fb.group({
      password: [
        '',
        [
          Validators.required,
          Validators.maxLength(8),
          Validators.minLength(8),
          Validators.pattern('^[0-9]*$'),
          this.passwordUtils.repeatedNumbersValidator(),
          this.passwordUtils.sequentialNumbersValidator()
        ]
      ]
    });
  }

  onSubmit(): void {
    if (this.setPasswordForm.valid) {
      this.isLoading = true;
      this.authService
        .changePassword(this.setPasswordForm.get('password').value)
        .pipe(
          finalize(() => (this.isLoading = false)),
          first()
        )
        .subscribe((response: NewPasswordSuccess) => {
          if (response.success) {
            this.passwordChanged = true;
          } else if (response.resendCode) {
            this.router.navigate([
              'auth/password-setup/recover/select-contact'
            ]);
          }
        });
    }
  }

  togglePasswordVisibility(): void {
    this.isPasswordHidden = !this.isPasswordHidden;
  }

  back(): void {
    this.router.navigate([
      `auth/password-setup/${this.chosenChannel}/code-verification`
    ]);
  }
}
