import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { OpinIconModule } from '../../atoms/icon/icon.module';
import { OpinLabelModule } from '../../atoms/label/label.module';
import { DialogFAQComponent } from './dialog-faq.component';

@NgModule({
  exports: [DialogFAQComponent],
  declarations: [DialogFAQComponent],
  imports: [
    MatDialogModule,
    MatExpansionModule,
    ScrollingModule,
    CommonModule,
    OpinIconModule,
    OpinLabelModule
  ]
})
export class OpinDialogFAQModule {}
