import { Injectable } from '@angular/core';
import { AuthService } from '@app/auth/auth.service';
import { UserData } from '@app/core/auth/user.model';
import { MockDiversificationService } from '../constants/mocks/diversification.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  constructor(
    public mockDiversificationService: MockDiversificationService,
    private authService: AuthService
  ) {
    this.useMockService = this.mockDiversificationService.useMockService;
  }
  public useMockService: boolean;

  getToken(): string {
    return this.authService.getToken();
  }

  getUsername(): string {
    if (this.useMockService) {
      return this.mockDiversificationService.getUsername();
    }

    return this.authService.getUserData(UserData.Name);
  }
}
