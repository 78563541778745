import { Component, Input } from '@angular/core';
import { itemKeyList } from './info-list.model';

@Component({
  selector: 'app-info-list',
  templateUrl: './info-list.component.html',
  styleUrls: ['./info-list.component.scss']
})
export class InfoListComponent {
  public keyList = itemKeyList;

  @Input() info: [string, number | string][];

  isNumber(val: string | number): boolean {
    return typeof val === 'number';
  }
}
