import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { OpinIconModule } from '../../atoms/icon/icon.module';
import { OpinLabelModule } from '../../atoms/label/label.module';
import { OpinTextRedirectModule } from '../../atoms/text-redirect/text-redirect.module';
import { OpinCardSimpleInformationContainerComponent } from './card-simple-information.component';

@NgModule({
  declarations: [OpinCardSimpleInformationContainerComponent],
  exports: [OpinCardSimpleInformationContainerComponent],
  imports: [
    OpinLabelModule,
    OpinTextRedirectModule,
    OpinIconModule,
    MatCardModule,
    CommonModule
  ]
})
export class OpinCardInfoModule {}
