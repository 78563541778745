import { Success } from '@app/core/authentication';
import { Data19, SuccessResponseProfileV2 } from '@app/core/bpApi';
import { ValidationLoginPlansData } from '@app/validation/validations.model';

export enum AuthErrorMessage {
  INVALID_CODE = 'Código inválido, selecione um de seus meios de contato para receber um novo código.',
  BLOCKED_CODE = `Você atingiu o limite de 5 tentativas incorretas! Por segurança, bloqueamos seu acesso.
  Aguarde 24h pro desbloqueio automático. Se não ocorrer, ligue na nossa Central de Relacionamento.`,
  GENERIC = 'Ops! ocorreu um erro inesperado! Por favor, tente novamente mais tarde!',
  INVALID_CREDENTIALS = 'Dados de acesso incorretos. Sua senha será bloqueada após a terceira tentativa incorreta.',
  NEW_PASSWORD_ERROR = `Não é possível criar uma senha: com números sequenciais, números repetidos ou que componham
  sua data de nascimento.`,
  TRANSACTION_WRONG_PASSWORD = 'Senha inválida. Sua senha sera bloqueada após a terceira tentativa incorreta',
  BLOCKED_PASSWORD = 'O acesso foi bloqueado por segurança. Clique em "Esqueci minha senha" para recuperar sua senha.',
  BLOCKED_TRANSACTION_PASSWORD = 'Sua senha de transação foi bloqueada.',
  EXPIRED_TOKEN = 'Por motivo de segurança, sua sessão foi finalizada. Por favor, faça login novamente.',
  MISSING_USER_DATA = 'Foi identificado um problema nas suas informações cadastrais.',
  INVALID_PHONE_SMS = 'O telefone cadastrado em nossa base é um telefone inválido.',
  MISSING_CONTACTS = 'Usuário não possui contatos cadastrados para o envio do token.',
  BIRTH_DATE_ERROR = 'A senha não pode ser sua data de nascimento.'
}

export enum ErrorType {
  INVALID_CODE = 'INVALID_CODE',
  BLOCKED_CODE = 'BLOCKED_CODE',
  GENERIC = 'GENERIC',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  NEW_PASSWORD_ERROR = 'NEW_PASSWORD_ERROR',
  TRANSACTION_WRONG_PASSWORD = 'TRANSACTION_WRONG_PASSWORD',
  BLOCKED_PASSWORD = 'BLOCKED_PASSWORD',
  BLOCKED_TRANSACTION_PASSWORD = 'BLOCKED_TRANSACTION_PASSWORD',
  EXPIRED_TOKEN = 'EXPIRED_TOKEN',
  MISSING_USER_DATA = 'MISSING_USER_DATA',
  INVALID_PHONE_SMS = 'INVALID_PHONE_SMS',
  MISSING_CONTACTS = 'MISSING_CONTACTS',
  DOUBLE_TRANSACTION = 'DOUBLE_TRANSACTION'
}

export class SuccessProfileAndPlansResponse {
  profile: SuccessResponseProfileV2;
  validationData: ValidationLoginPlansData;
}

export enum AuthenticationByJourneyUrl {
  CHECKUP = '/auth/checkup',
  DECUMULATION = '/auth/decumulation'
}

export enum TargetJourneyUrl {
  CHECKUP = '/checkup',
  DECUMULATION = '/decumulation'
}

export enum Channel {
  SMS = 'sms',
  EMAIL = 'email',
  WPP = 'whatsapp'
}

export class NewPasswordSuccess extends Success {
  resendCode: boolean;
}

export enum LocalStorageId {
  LOGIN = 'login: '
}

export class CPF {
  digitis: string;
  formated: string;

  constructor(digits?: string, formated?: string) {
    this.digitis = digits ?? '';
    this.formated = formated ?? '';
  }
}
export class ProfileData extends Data19 {
  socialName?: string | null;
}

export class Profile extends SuccessResponseProfileV2 {
  data?: ProfileData;

  constructor(profileV2: SuccessResponseProfileV2) {
    super();

    this.status = profileV2.status;
    this.data = profileV2.data;
  }
}

export enum SetPasswordTitle {
  new = 'Ainda não tem senha?',
  recover = 'Esqueceu sua senha?'
}
