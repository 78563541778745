import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { environment } from '@env/environment';
import { API_BASE_URL_AUTH, AuthenticationService } from './authentication';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [
    {
      provide: API_BASE_URL_AUTH,
      useValue: environment.api.brasilprev.auth_url
    },
    AuthenticationService
  ],
  exports: []
})
export class AuthenticationModule {}
