import { Injectable } from '@angular/core';
import { Plan, PlanTraditional } from '@app/core/validation-bff';
import { Prefix } from '@app/shared/models/prefix.model';
import { SessiontorageData } from '@app/shared/utils/sessionStorage';

@Injectable({
  providedIn: 'root'
})
export class PlanAuthService {
  public validationSessionStorage = new SessiontorageData(Prefix.Validation);

  constructor() {}

  public setValidationPlans(plans: Plan[]): void {
    this.validationSessionStorage.setData('Plans', JSON.stringify(plans));
  }

  public getValidationPlans(): Plan[] {
    return JSON.parse(this.validationSessionStorage.getData('Plans'));
  }

  public deleteValidationPlans(): void {
    this.validationSessionStorage.deleteData('TradPlans');
  }

  public setTradValidationPlans(plans: PlanTraditional[]): void {
    this.validationSessionStorage.setData('TradPlans', JSON.stringify(plans));
  }

  public getTradValidationPlans(): PlanTraditional[] {
    return JSON.parse(this.validationSessionStorage.getData('TradPlans'));
  }

  public deleteTradValidationPlans(): void {
    this.validationSessionStorage.deleteData('TradPlans');
  }
}
