import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared';
import { OpinIconModule } from '../icon/icon.module';
import { OpinButtonComponent } from './button.component';

@NgModule({
  declarations: [OpinButtonComponent],
  exports: [OpinButtonComponent],
  imports: [CommonModule, SharedModule, OpinIconModule]
})
export class OpinButtonModule {}
