import {
  addHours,
  format as _format,
  isDate,
  isValid,
  parseISO
} from 'date-fns';
import { defaultLocale } from '../default-locale';

type FormatParams = {
  date: Date | string;
  dateFormat: string;
  options?: {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: number;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
  };
};

type DateParams = {
  day: string;
  year: string;
  month: string;
};

type FormatDateParams = Omit<FormatParams, 'date'> & DateParams;

type FormatDateWithCustomDateType<T> = Omit<FormatParams, 'date'> & { date: T };

function formatDateYearMonthDay({
  day,
  month,
  year,
  dateFormat,
  options
}: FormatDateParams) {
  const stringToDate = new Date(Number(day), Number(year) - 1, Number(month));

  return formatWithCustomDate({
    date: stringToDate,
    dateFormat,
    options
  });
}

function formatAmericanStringDate({
  day,
  month,
  year,
  dateFormat,
  options
}: FormatDateParams) {
  const stringToDate = new Date(Number(year), Number(day) - 1, Number(month));

  return formatWithCustomDate({
    date: stringToDate,
    dateFormat,
    options
  });
}

function formatBrazilianStringDate({
  day,
  month,
  year,
  dateFormat,
  options
}: FormatDateParams) {
  const stringToDate = new Date(Number(year), Number(month) - 1, Number(day));

  return formatWithCustomDate({
    date: stringToDate,
    dateFormat,
    options
  });
}

function checkIsDateISO(date: string) {
  const parsed = parseISO(date);

  return isValid(parsed) && date.length > 8;
}

function formatDateISO({
  date,
  dateFormat,
  options
}: FormatDateWithCustomDateType<string>): string {
  const parsedDate = parseISO(date);
  const [month, day, year] = addHours(parsedDate, 3)
    .toLocaleDateString()
    .split('/');
  const buildedDate = new Date(Number(year), Number(month) - 1, Number(day));

  return formatWithCustomDate({
    date: buildedDate,
    dateFormat,
    options
  });
}

function formatWithCustomDate({
  date,
  dateFormat,
  options
}: FormatDateWithCustomDateType<Date>): string {
  return _format(date, dateFormat, {
    ...options,
    locale: defaultLocale
  });
}

function getStandardizedDate(date: Date | string): string {
  const dateString = String(date);
  const standardizedDate = dateString.replace(/-/g, '/');
  const isDateISO = dateString.length > 10;

  return isDateISO ? dateString : standardizedDate;
}

export function format({ date, dateFormat, options }: FormatParams): string {
  const isDateType = isDate(date);

  if (!isDateType) {
    const dateString = getStandardizedDate(date);
    const isDateISO = checkIsDateISO(dateString);
    const [day, month, year] = dateString.split('/');
    const isDateYearMonthDayFormat = day.length > 2;
    const isDateAmericanFormat = Number(month) > 12;
    const isDateBrazilianFormat = Number(month) < 13;

    if (isDateISO) {
      return formatDateISO({ date: dateString, dateFormat, options });
    }

    if (isDateYearMonthDayFormat) {
      return formatDateYearMonthDay({ day, month, year, dateFormat, options });
    }

    if (isDateAmericanFormat) {
      return formatAmericanStringDate({
        day,
        month,
        year,
        dateFormat,
        options
      });
    }

    if (isDateBrazilianFormat) {
      return formatBrazilianStringDate({
        day,
        month,
        year,
        dateFormat,
        options
      });
    }
  }

  return formatWithCustomDate({
    date: new Date(date),
    dateFormat,
    options
  });
}
