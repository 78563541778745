const communicationSvgIcons = [
  {
    name: 'list-alt',
    path: '/assets/design-system/icons/communication/list_alt.svg'
  },
  {
    name: 'contact-phone',
    path: '/assets/design-system/icons/communication/contact_phone.svg'
  },
  {
    name: 'mark-email-unread',
    path: '/assets/design-system/icons/communication/mark_email_unread.svg'
  },
  {
    name: 'chat',
    path: '/assets/design-system/icons/communication/chat.svg'
  },
  {
    name: 'domain-verification',
    path: '/assets/design-system/icons/communication/domain_verification.svg'
  }
];

export default communicationSvgIcons;
