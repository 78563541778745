<a
  [class]="'open-acc-service-chn-btn open-acc-service-chn-btn__' + this.color"
  [href]="redirectUrl"
  target="_blank"
>
  <div class="icon-container">
    <img [src]="iconPath" alt="open-dialog-icon" />
  </div>
  <small class="description-container">{{ title }}</small>
</a>
