<header
  *ngIf="menuState$ | async as menuState"
  [ngClass]="{ open: menuState.isMenuOpen }"
>
  <button
    class="menu-button"
    mat-icon-button
    (click)="toggleMenuVisibility(menuState.isMenuOpen)"
    title="Abrir menu de serviços"
  >
    <mat-icon>{{ menuState.isMenuOpen ? 'clear' : 'menu' }}</mat-icon>
  </button>

  <h1>
    <span>Brasilprev</span>
    <button (click)="navigateTo('/home')" title="Retornar a home">
      <img
        src="../../../../assets/svg/brasilprev-new.svg"
        alt="logo BrasilPrev"
        role="button"
        aria-label="logo brasilprev"
      />
    </button>
  </h1>

  <app-profile-menu
    [nameInitials]="nameInitials"
    [isProfileOpen]="menuState.isProfileOpen"
    [navigationList]="[
      { name: 'Meus dados', url: '/profile' },
      { name: 'Open insurance', callback: callback.OPIN },
      { name: 'Minha privacidade', url: '/privacy' },
      { name: 'Sair', callback: callback.LOGOUT }
    ]"
  ></app-profile-menu>

  <app-header-navigation
    [isMenuOpen]="menuState.isMenuOpen"
  ></app-header-navigation>
</header>
