import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-filter-funds-modal',
  templateUrl: './filter-funds-modal.component.html',
  styleUrls: ['./filter-funds-modal.component.scss']
})
export class FilterFundsModalComponent implements OnInit {
  filter_data = {
    order: '',
    rentabilities: '',
    risk: {
      muito_baixo: false,
      baixo: false,
      medio: false,
      alto: false,
      muito_alto: false
    },
    categories: {
      renda_fixa: false,
      multimercado: false,
      exclusivo: false,
      ciclo_de_vida: false
    }
  };

  constructor(
    public dialogRef: MatDialogRef<FilterFundsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    if (this.data.filterData) {
      this.filter_data = this.data.filterData;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
