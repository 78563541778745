<ng-container *ngIf="selectedPlanData$ | async as planData">
  <ul *ngIf="planData.menuFeatList" [ngClass]="{ open: isMenuOpen }">
    <li class="first" *ngIf="planData.registration">
      <app-expansion-panel
        [title]="'Gerenciar meus planos'"
        [registration]="planData.registration"
        [features]="
          mountMenuGroup(menuGroups.MANAGE_PLANS, planData.menuFeatList)
        "
        [fourColums]="true"
      ></app-expansion-panel>
    </li>
    <li
      class="second"
      *ngIf="
        planData.registration &&
        hasFeatOn(feature.EXTRA_CONTRIBUTION, planData.cardsFeatList)
      "
    >
      <button
        mat-flat-button
        (click)="
          handleNavigationItemAction(
            feature.EXTRA_CONTRIBUTION,
            planData.registration
          )
        "
        *ngIf="hasFeatOn(feature.EXTRA_CONTRIBUTION, planData.menuFeatList)"
      >
        Contribuição extra
      </button>
    </li>
    <li class="third">
      <app-expansion-panel
        [title]="'Outras funcionalidades'"
        [registration]="planData.registration"
        [features]="
          mountMenuGroup(menuGroups.OTHER_FEATURES, planData.menuFeatList)
        "
      ></app-expansion-panel>
    </li>
    <li class="fourth">
      <button mat-flat-button (click)="navigateTo('/support')">
        Fale conosco
      </button>
    </li>
  </ul>
</ng-container>
