import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SummaryBalance } from '../plan.model';

@Component({
  selector: 'app-header-tile-details',
  templateUrl: './header-tile-details.component.html',
  styleUrls: ['./header-tile-details.component.scss']
})
export class HeaderTileDetaisComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() value: number;
  @Input() tooltip: string;
  @Input() balance;
  subscription: Subscription;
  summaryBalance: SummaryBalance;

  ngOnInit() {
    this.subscription = this.balance.subscribe(res => {
      this.summaryBalance = res.summaryBalanceStatement;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
