<h3 class="title">Rendimento mês a mês</h3>
<mat-card class="transfers">
  <mat-card-content>
    <mat-tab-group
      mat-align-tabs="start"
      [selectedIndex]="years.length - 1"
      (selectedTabChange)="nextYear(years[$event.index])"
    >
      <mat-tab
        *ngFor="let year of years; let i = index"
        [label]="year.toString()"
        isActive="active"
      >
        <ng-container
          *ngIf="planTracking$ | async as planTracking; else loading"
        >
          <mat-accordion>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
              hideToggle
              [expanded]="true"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span>
                    <mat-icon
                      svgIcon="evolution"
                      class="evolution-icon"
                    ></mat-icon>
                    Evolução de saldo e rendimento durante o ano
                  </span>
                </mat-panel-title>
                <mat-icon [ngClass]="panelOpenState ? 'arrow-up' : ''"
                  >keyboard_arrow_down</mat-icon
                >
              </mat-expansion-panel-header>

              <div class="content-table">
                <table mat-table [dataSource]="planTracking?.monthsType">
                  <ng-container matColumnDef="month">
                    <th mat-header-cell *matHeaderCellDef>Mês</th>
                    <td mat-cell *matCellDef="let element">
                      {{ monthNames[element.month - 1] }}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>Acumulado</td>
                  </ng-container>

                  <ng-container matColumnDef="monthBalance">
                    <th mat-header-cell *matHeaderCellDef>Saldo</th>
                    <td mat-cell *matCellDef="let element">
                      <span
                        [appPrivacy]="element.monthBalance | currency"
                      ></span>
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                      <span
                        [appPrivacy]="totalMonthBalance | async | currency"
                      ></span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="monthYield">
                    <th mat-header-cell *matHeaderCellDef>
                      Rendimento<br />em R$
                    </th>
                    <td mat-cell *matCellDef="let element" class="month-income">
                      <span [appPrivacy]="element.monthYield | currency"></span>
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                      <span
                        [appPrivacy]="totalMonthYield | async | currency"
                      ></span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="monthProfitability">
                    <th mat-header-cell *matHeaderCellDef>
                      Rentabilidade<br />Média
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.monthProfitability / 100 | percent : '1.2-2' }}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                      {{ totalMonthProfitability | async | percent : '1.2-2' }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="message">
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      colspan="4"
                      class="open-month"
                    >
                      <p *ngIf="openMonthEnd">
                        Movimentações relativas ao período de
                        {{ openMonthStart | date : 'dd/MM/yyyy' }} a
                        {{ openMonthEnd | date : 'dd/MM/yyyy' }}
                      </p>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                  ></tr>
                  <tr mat-footer-row *matFooterRowDef="['message']"></tr>
                  <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
                </table>
              </div>
              <div class="content-spinner" *ngIf="!planTracking$ || isLoading">
                <mat-spinner [diameter]="60"></mat-spinner>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </ng-container>
        <ng-template #loading>
          <mat-spinner></mat-spinner>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>
