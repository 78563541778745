import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OpinTextComponent } from './text.component';

@NgModule({
  imports: [CommonModule],
  declarations: [OpinTextComponent],
  exports: [OpinTextComponent]
})
export class OpinTextComponentModule {}
