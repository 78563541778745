const editorSvgIcons = [
  {
    name: 'publish',
    path: '/assets/design-system/icons/editor/publish.svg'
  },
  {
    name: 'insert-drive-file',
    path: '/assets/design-system/icons/editor/insert_drive_file.svg'
  },
  {
    name: 'attach-money',
    path: '/assets/design-system/icons/editor/attach_money.svg'
  }
];

export default editorSvgIcons;
