<h1 mat-dialog-title>Atenção!</h1>
<div mat-dialog-content>
  <p>
    Ao clicar em “continuar” você será redirecionado para solução de
    autenticação segura do Banco do Brasil e fará login utilizando agência,
    conta e senha (a mesma do internet banking).
  </p>
  <p>
    Em caso de dúvida, contate 4004-0001
  </p>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">
    Não continuar
  </button>
  <button
    mat-button
    mat-raised-button
    [mat-dialog-close]="true"
    color="primary"
  >
    Continuar
  </button>
</div>
