import { ListRegistrationCardDTORegistrations } from '@app/core/diversification-api';
import { PlanSummary } from '@app/home/statement.model';
import { Plan } from '@app/plan/plan.model';
import { Plan as ReallocationPlan } from '@app/reallocation/models/plans.model';
import { UpsellingPlan } from '@app/upselling/upselling.model';

export class UnconvertedPlanCard {
  plan:
    | UpsellingPlan
    | Plan
    | ReallocationPlan
    | PlanSummary
    | ListRegistrationCardDTORegistrations;
  image: string;
  buttonText: string;
  isDisabled: boolean;
  bottomContent?: string;
  hasDetails?: boolean;
}

export class PlanCard {
  type: string;
  name: string;
  registration: string;
  image: string;
  buttonText: string;
  balance: number;
  isDisabled: boolean;
  bottomContent?: BottomContent | undefined;
  hasDetails?: boolean;
}

export enum BottomContent {
  notEligible = `Plano não elegível para essa funcionalidade`,
  juniorOwnership = `Este plano está vinculado a este CPF até o júnior titular se tornar
  maior de idade, após esse período, o plano poderá ser consultado através
  do login do titular.`
}
