import { Injectable } from '@angular/core';
import { ChartDataset } from 'chart.js';
import { DetalheFundo } from './detalheFundo';
import { InvestmentDetailService } from './investment-detail.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InvestmentComparatorService {
  fundosComparados = [];
  fundosDados = {};
  months = [];
  chartDataObj: ChartDataset<'line'>[] = [];
  constructor(private investmentDetailService: InvestmentDetailService) {}

  setFundosComparados(fund: string) {
    this.fundosComparados = [...this.fundosComparados, fund];
    return this.obterRentabilidade(fund);
  }

  obterRentabilidade(fund: string) {
    const slug = this.nameToSlug(fund);
    return this.investmentDetailService.getData(slug).pipe(
      tap((res) => {
        const data: number[] = this.investmentDetailService
          .obterRentabilidadeFundoUltimos12Meses(res.rentabilidade)
          .map((item: number) => item * 100);
        const borderColor = `#${(new Date().getTime() * Math.random())
          .toString(16)
          .substr(2, 6)}`;
        this.fundosDados[slug] = {
          data,
          fill: false,
          label: fund,
          borderColor,
          pointBackgroundColor: borderColor,
          backgroundColor: borderColor
        };

        this.addToChart(this.fundosDados[slug]);
      })
    );
  }

  removeFundo(fund: string): void {
    const index = this.fundosComparados.indexOf(fund);
    if (index >= 0) {
      this.chartDataObj = this.chartDataObj.filter(
        (f: any) => f.label !== fund
      );
      fund = this.nameToSlug(fund);
      this.fundosComparados.splice(index, 1);
      if (fund in this.fundosDados) {
        delete this.fundosDados[fund];
      }
    }
  }

  addToChart(fund): void {
    this.chartDataObj.push({
      data: fund.data,
      label: fund.label,
      tension: 0,
      fill: false,
      borderColor: fund.borderColor,
      pointBackgroundColor: fund.borderColor,
      backgroundColor: fund.borderColor,
      xAxisID: 'x'
    });
  }

  resetComparator(): void {
    this.fundosComparados = [];
    this.fundosDados = {};
    this.chartDataObj = [];
  }

  nameToSlug(name): string {
    return name.trim().replaceAll(' ', '_');
  }
}
