import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { GetEndpointReceptionBFFResponse } from '@app/open-insurance/modules/consents/services/opin-authorization/opin-authorization.model';
import { environment } from '@env/environment';

@Injectable()
export class OpinAuthorizationService {
  constructor(private readonly httpClient: HttpClient) {}

  // TODO: pass it to opin-consent API and purge that lambda @Rafael
  getReceptionJourneyURL$(
    authorizationToken: string
  ): Observable<GetEndpointReceptionBFFResponse> {
    return this.httpClient.post<GetEndpointReceptionBFFResponse>(
      `${environment.opin.opin_authentication_url}/redirect-reception-bff`,
      {
        authorization: authorizationToken
      }
    );
  }
}
