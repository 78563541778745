import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ErrorResponse } from '@app/core/authentication';
import { ConsultarOptinWhatsappResponse } from '@app/core/login';
import { NotificationService } from '@app/core/notification/notification.service';
import { ScreenUtils } from '@app/shared/utils/screenUtils';
import { environment } from '@env/environment';
import { finalize } from 'rxjs/operators';
import { Channel } from '../auth.model';
import { AuthService } from '../auth.service';
import { WppModalComponent } from '../wpp-modal/wpp-modal.component';

@Component({
  selector: 'app-check-twofa-token',
  templateUrl: './check-twofa-token.component.html',
  styleUrls: ['../auth-components.scss']
})
export class CheckTwofaTokenComponent implements OnInit {
  @Input() title: string;
  @Input() text: string;
  @Input() verifyCode = true;
  @Input() isLoading: boolean;
  @Input() isWhatsappButtonEnabled: boolean = this.isWppEnabled(
    environment.features.whatsapp_mfa_button
  );
  @Input() back: () => void;
  @Output() handleWpp = new EventEmitter<void>();
  @Output() hasCode = new EventEmitter<{ code: string }>();
  isWhatsappMessagesAllowed: boolean;

  code = new FormControl('', [
    Validators.required,
    Validators.minLength(6),
    Validators.maxLength(6),
    Validators.pattern('^[0-9]*$')
  ]);

  constructor(
    private authService: AuthService,
    private notificationService: NotificationService,
    public dialog: MatDialog,
    private screenUtils: ScreenUtils
  ) {}

  ngOnInit(): void {
    this.checkWhatsappOptin();
  }

  public isWppEnabled(environmentVar: string): boolean {
    return environmentVar === 'true';
  }

  public checkWhatsappOptin() {
    if (this.isWhatsappButtonEnabled) {
      this.isLoading = true;
      this.authService
        .checkWhatsappOptin()
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe(
          (response: ConsultarOptinWhatsappResponse) =>
            (this.isWhatsappMessagesAllowed = response.permitirMensagens)
        );
    }
  }

  getErrorMessage(): string {
    let message: string;

    if (this.code.hasError('required')) {
      message = 'Campo obrigatório';
    }

    if (this.code.hasError('minlength') || this.code.hasError('maxlength')) {
      message = 'O campo deve conter 6 caracteres';
    }

    if (this.code.hasError('pattern')) {
      message = 'O campo deve conter apenas números';
    }

    return message;
  }

  onSubmit() {
    if (this.code.valid) {
      this.hasCode.emit({ code: this.code.value });
    }
  }

  onHandleWpp() {
    if (this.isWhatsappMessagesAllowed) {
      this.isLoading = true;
      this.authService
        .askCode(Channel.WPP)
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe((response: ErrorResponse | null) => {
          if (response === null) {
            this.notificationService.showSuccess(
              'Código enviado para o seu whatsapp',
              'Ok',
              { duration: 5000 }
            );
          }
        });
    } else {
      this.dialog.open(WppModalComponent, {
        width: this.screenUtils.isMobile ? '90%' : '23.625rem'
      });
    }
    this.handleWpp.emit();
  }

  resetControl() {
    this.code.reset('');
    this.code.setErrors(null);
  }

  linkPress(event: KeyboardEvent) {
    if (event.code === 'Enter') {
      this.back();
    }
  }
}
