/* eslint-disable max-len */
import {
  Detail,
  Distribution,
  Eligibility,
  InvestmentDetail,
  InvestmentPlanDetail,
  InvestmentPortability,
  InvestmentsPlan,
  InvestmentSummary,
  InvestorProfile,
  Plan,
  PlanDetail,
  PortabilityDistribution,
  PortfolioCompare,
  ProfitabilitySummary,
  Rentability,
  SimulationDestinationDefineRequest,
  SimulationDestinationDefineResponse,
  SimulationRequest,
  SimulationResponse,
  SolicitationResponse,
  Summary
} from '@app/core/new-diversification';
import { SummaryPortfolio } from '@app/diversification/components/summary-comparison-list/summary-comparison-list.component';
import { ValidAndInvalidPlansArray } from '@app/diversification/utils/get-valid-and-invalid-plans';
import { MockRegistration } from '@app/funds-diversification/plan-about/__mocks__';

export const MockInvesmentSummary: InvestmentSummary = {
  detail: 'mock-informativeText',
  name: 'mock-investmentName',
  percentage: 12345,
  risk: 'mock-risk',
  value: 12345
};

export const MockSummary: Summary = {
  currentPortfolio: [...Array(4).keys()].map(() => MockInvesmentSummary),
  simulatedPortfolio: [...Array(4).keys()].map(() => MockInvesmentSummary),
  balance: 12345,
  monthlyContributionValue: 12345,
  planGoal: 'mock-planGoal',
  planName: 'mock-planName',
  planType: 'mock-planType',
  registration: 'mock-registration',
  taxType: 'mock-taxType'
};

export const MockSimulationResponse: SimulationResponse = {
  simulationId: 12345,
  status: 'Simulação concluída com sucesso!'
};

export const MockSimulationDestinationDefineResponse: SimulationDestinationDefineResponse =
  MockSimulationResponse;

export const MockInvestmentPortability: InvestmentPortability = {
  id: 12345,
  name: 'mock-name',
  percentage: 12345,
  susepId: 12345,
  susepProcessNumber: 'mock-susep-process-number'
};

export const MockSimulationDestinationDefineRequest: SimulationDestinationDefineRequest =
  {
    portabilityDistribution: [],
    simulationId: 12345
  };

export const MockSimulateDistributionPayload: SimulationRequest = {
  portabilityValue: 12345,
  registration: 'mock-registration',
  investments: [...Array(1).keys()].map(() => MockInvestmentPortability)
};

export const MockEligibilityAllTrue: Eligibility = {
  diversification: true,
  relocation: true
};

export const MockEligibilityDiversificationOnly: Eligibility = {
  diversification: true,
  relocation: false
};

export const MockEligibilityRelocationOnly: Eligibility = {
  diversification: false,
  relocation: true
};

export const MockValidPlan: Plan = {
  balance: 100000,
  hiringType: 'mock-hiringType',
  taxType: 'REGRESSIVO',
  ineligibleMessage: '',
  planName: 'Minha aposentadoria',
  planType: 'PGBL',
  registration: '098213409',
  isEligible: true
};

export const MockInvalidPlan: Plan = {
  ...MockValidPlan,
  ineligibleMessage: 'Seu plano possui solicitações em andamento',
  isEligible: false
};

export const MockPlans_1Valid_1Invalid: Plan[] = [
  MockValidPlan,
  MockInvalidPlan
];

export const MockPlans_0Valid_2Invalid: Plan[] = [...Array(2).keys()].map(
  () => MockInvalidPlan
);

export const MockPlans_2Valid_0Invalid: Plan[] = [...Array(2).keys()].map(
  () => MockValidPlan
);

export const MockPlans_0Valid_0Invalid: Plan[] = [];

export const MockValidAndInvalidPlans: ValidAndInvalidPlansArray = {
  valid: [...Array(1).keys()].map(() => MockValidPlan),
  invalid: [...Array(1).keys()].map(() => MockInvalidPlan)
};

export const MockValidAndInvalidPlansEmpty: ValidAndInvalidPlansArray = {
  valid: [],
  invalid: []
};

export const MockPlanInvestmentRentabilityEmpty: Rentability = {
  currentMonth: null,
  currentYear: null,
  lastMonth: null,
  last12Months: null,
  last24Months: null,
  last36Months: null,
  last48Months: null,
  last60Months: null
};

export const MockPlanInvestmentRentability: Rentability = {
  currentMonth: 10.0,
  currentYear: 10.0,
  lastMonth: 10.0,
  last12Months: 10.0,
  last24Months: 10.0,
  last36Months: 10.0,
  last48Months: 10.0,
  last60Months: 10.0
};

export const MockInvestmentPlanDetailEmpty: InvestmentPlanDetail = {
  detail:
    'Este fundo tem menos de 12 meses. Para avaliação de volatilidade de um fundo de investimento, é recomendável a análise de, no mínimo, 12 (doze) meses.',
  id: 12345,
  name: 'MULTIMERCADO PREMIUM III',
  percentage: 0,
  risk: 'Baixo',
  susepId: 12345,
  susepProcessNumber: 'mock-susep-process-number',
  value: 0,
  taf: 0,
  volatility: null
};

export const MockInvestmentPlanDetail: InvestmentPlanDetail = {
  detail:
    'Este fundo tem menos de 12 meses. Para avaliação de volatilidade de um fundo de investimento, é recomendável a análise de, no mínimo, 12 (doze) meses.',
  id: 1,
  name: 'MULTI ESPECIAL',
  percentage: 25,
  risk: 'Alto',
  susepId: 12345,
  susepProcessNumber: 'mock-susep-process-number',
  value: 25000,
  taf: 1.2,
  volatility: 12.34
};

export const MockPortabilityDistribution: PortabilityDistribution = {
  investments: [...Array(1).keys()].map(() => MockInvestmentPlanDetail),
  portabilityValue: 12345,
  productId: 12345
};

export const MockSimulationInvestmentOrigin: InvestmentPlanDetail = {
  ...MockInvestmentPlanDetail,
  percentage: 20,
  value: 20_000
};

export const getMockInvestmentPlanWithId = (id: number) => ({
  id,
  name: 'MULTI ESPECIAL ' + id,
  detail:
    'Este fundo tem menos de 12 meses. Para avaliação de volatilidade de um fundo de investimento, é recomendável a análise de, no mínimo, 12 (doze) meses.',
  percentage: 25,
  risk: 'Alto',
  susepId: 12345,
  susepProcessNumber: 'mock-susep-process-number',
  value: 25_000,
  taf: 1.2,
  volatility: 12.34
});

export const getMockInvestmentPlanEmptyWithId = (id: number) => ({
  id,
  name: 'MULTIMERCADO PREMIUM III ' + id,
  detail:
    'Este fundo tem menos de 12 meses. Para avaliação de volatilidade de um fundo de investimento, é recomendável a análise de, no mínimo, 12 (doze) meses.',
  percentage: 0,
  risk: 'Baixo',
  susepId: 12345,
  susepProcessNumber: 'mock-susep-process-number',
  value: 0,
  taf: 0,
  volatility: null
});

export const MockInvesmentPlanAllocated = [...Array(4).keys()].map((_, index) =>
  getMockInvestmentPlanWithId(index + 1)
);
export const MockInvestmentPlanOther = [...Array(4).keys()].map((_, index) =>
  getMockInvestmentPlanEmptyWithId(index + 5)
);

export const MockInvestmentPlan: InvestmentsPlan = {
  allocated: MockInvesmentPlanAllocated,
  others: MockInvestmentPlanOther
};

export const MockInvestments: InvestmentPlanDetail[] = [
  ...MockInvesmentPlanAllocated,
  ...MockInvestmentPlanOther
];

export const MockPlanDetail: PlanDetail = {
  balance: 12345,
  endDate: 'mock-end-date',
  hiringType: 'mock-hiring-type',
  monthlyContributionValue: 12345,
  name: 'Minha aposentadoria',
  type: 'PGBL',
  taxType: 'REGRESSIVO',
  registration: '098213409',
  goal: 'mock-goal',
  productId: 12345,
  productName: 'mock-productName'
};

export const MockInvestorProfile: InvestorProfile = {
  investorProfile: 'MODERADO',
  investorProfileDescription:
    'Esse perfil enfatiza segurança nos seus investimentos, mas também opta por produtos que podem proporcionar maiores ganhos no longo prazo. Realocar os recursos é o mais aconselhável para a sua estratégia de investimentos'
};

export const MockDistributionSuggestedFalse: Distribution = {
  distributionValue: MockValidPlan.balance,
  investments: MockInvestmentPlanOther,
  suggested: false
};

export const MockDistributionSuggestedTrue: Distribution = {
  distributionValue: MockValidPlan.balance,
  investments: MockInvesmentPlanAllocated,
  suggested: true
};

export const MockSummaryPortfolios: SummaryPortfolio[] = [
  {
    heading: 'Plano atual',
    plan: MockPlanDetail,
    investments: [...Array(4).keys()].map(() => MockInvesmentSummary)
  },
  {
    heading: 'Plano realocado',
    plan: MockPlanDetail,
    investments: [...Array(4).keys()].map(() => MockInvesmentSummary)
  }
];

export const MockSolicitationResponse: SolicitationResponse = {
  solicitationId: 12345,
  status: 'Simulação concluída com sucesso!'
};

export const MockProfitabilitySummary: ProfitabilitySummary = {
  name: 'mock-name',
  currentMonth: 1.0,
  currentYear: 2.0,
  lastMonth: 3.0,
  last12Months: 4.0,
  last24Months: 5.0,
  last36Months: 6.0,
  last48Months: 7.0,
  last60Months: null
};

export const MockPortfolioCompare: PortfolioCompare = {
  currentPortfolio: [...Array(10).keys()].map(() => MockProfitabilitySummary),
  simulatedPortfolio: [...Array(10).keys()].map(() => MockProfitabilitySummary)
};

export const MockInvestmentDetail: InvestmentDetail = {
  name: 'mock-name',
  percentage: 12.34
};

export const MockDetail: Detail = {
  currentPortfolio: [...Array(10).keys()].map(() => MockProfitabilitySummary),
  newPortfolio: [...Array(10).keys()].map(() => MockProfitabilitySummary)
};

export const MockSimulationRequest: SimulationRequest = {
  investments: [...Array(1).keys()].map(() => MockInvestmentPortability),
  portabilityValue: 25000,
  registration: MockRegistration
};
