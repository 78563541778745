import { Component, Input, OnInit } from '@angular/core';
import { PlanSummary } from '@app/home/statement.model';
import { StatementService } from '@app/home/statement.service';

@Component({
  selector: 'app-processing-date',
  templateUrl: './processing-date.component.html',
  styleUrls: ['./processing-date.component.scss']
})
export class ProcessingDateComponent implements OnInit {
  @Input() registration: string;
  plan: PlanSummary;

  constructor(private statementService: StatementService) {}

  ngOnInit(): void {
    this.getPlans();
  }

  private getPlans(): void {
    this.statementService.getPlansGraphql().subscribe(
      (plans: any) => {
        this.plan = plans.filter(
          (item) => item.registration === this.registration
        )[0];
      },
      (err) => console.error(err)
    );
  }
}
