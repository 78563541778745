const alertSvgIcons = [
  {
    name: 'error-outline',
    path: '/assets/design-system/icons/alert/error_outline.svg'
  },
  {
    name: 'warning-amber',
    path: '/assets/design-system/icons/alert/warning_amber.svg'
  }
];

export default alertSvgIcons;
