<strong>O boleto foi enviado para:</strong>
<p>{{ email }}</p>
<button
  mat-raised-button
  color="primary"
  type="button"
  title="fechar"
  [mat-dialog-close]
>
  Fechar
</button>
