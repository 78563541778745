export enum ErrorMessage {
  INVALID_CODE = 'Código inválido ou expirado, selecione um de seus meios de contato para receber um novo código.',
  TRANSACTION_PASSWORD_BLOCKED = 'Sua senha de transação foi bloqueada.',
  TRANSACTION_PASSWORD_INVALID = 'Senha inválida. Sua senha sera bloqueada após a terceira tentativa incorreta.',
  DOUBLE_TRANSACTION = `Você já fez uma contribuição extra com esse valor há poucos
  minutos. Se quiser repetir a operação, aguarde 15 minutos.`,
  DETAIL_PLAN_NOT_FOUND = `Não foi possível carregar os detalhes da matrícula, por favor,
  entre em contato com a nossa central de atendimento.`,
  ACCOUNT_WITH_INSUFFICIENT_FUNDS = 'O saldo em conta é insuficiente para essa transação. Por favor, tente novamente com outro valor.',
  ONLINE_DEBIT_UNAVAILABLE = 'O tempo para realizar o débito foi excedido, por favor tente novamente.',
  CUSTOMER_NOT_IS_ACCOUNT_OWNER = 'O cliente informado não é titular da conta a ser debitada.',
  SOLICITATION_ALREADY_REGISTERED = 'Esta simulação de contribuição extra já foi solicitada.'
}
