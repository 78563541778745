import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { UserData } from '@app/core/auth/user.model';
import {
  Boleto,
  BoletoResponseDTO,
  BoletoService,
  EmailResponseDTO,
  Planos,
  SolicitacaoEnviarEmailRequestDTO,
  SolicitacaoSegundaViaRequestDTO
} from '@app/core/boleto';
import { NotificationService } from '@app/core/notification/notification.service';
import { Observable, iif, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { EmailSuccessComponent } from './details/dialogs/email-success/email-success.component';
import { SendEmailComponent } from './details/dialogs/send-email/send-email.component';
import { InvoiceStatus } from './invoice.model';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  plans: Planos[];
  keyId = 'boleto plan: ';
  selectedEmail: string;

  constructor(
    private router: Router,
    private authService: AuthService,
    private boletoService: BoletoService,
    private dialog: MatDialog,
    private notification: NotificationService
  ) {}

  public listPlans(): Observable<Planos[]> {
    return this.boletoService.listarBoleto(this.authService.getToken()).pipe(
      take(1),
      map((plans: Planos[]) => this.filterNoInvoicePlans(plans))
    );
  }

  public onSelection(registration: string): Observable<any> {
    this.router.navigate(['invoices', 'details', registration]);
    return of();
  }

  public setPlanData(planData: Planos[]): void {
    sessionStorage.setItem(this.keyId, JSON.stringify(planData));
  }

  public getPlanData(): Planos[] {
    return JSON.parse(sessionStorage.getItem(this.keyId));
  }

  public deletePlanData(): void {
    sessionStorage.removeItem(this.keyId);
  }

  public sendEmail(
    payload: SolicitacaoEnviarEmailRequestDTO
  ): Observable<EmailResponseDTO> {
    return this.boletoService
      .solicitacaoEnviarEmail(this.authService.getToken(), payload)
      .pipe(take(1));
  }

  public handleEmailSolicitation(id: string): void {
    this.dialog
      .open(SendEmailComponent, {
        data: this.authService.getUserData(UserData.Email)
      })
      .afterClosed()
      .pipe(
        take(1),
        switchMap((email: string) =>
          iif(
            () => email !== undefined,
            ((this.selectedEmail = email),
            this.sendEmail({
              emailDestino: email,
              idBoleto: id
            } as SolicitacaoEnviarEmailRequestDTO))
          )
        )
      )
      .subscribe(() =>
        this.dialog.open(EmailSuccessComponent, { data: this.selectedEmail })
      );
  }

  public newInvoice(id: string): Observable<BoletoResponseDTO> {
    return this.boletoService
      .solicitacaoSegundaViaBoleto(this.authService.getToken(), {
        idBoleto: id
      } as SolicitacaoSegundaViaRequestDTO)
      .pipe(take(1));
  }

  public letOnlyNumbers(digitableLine: string): string {
    return digitableLine.replace(/\D/g, '');
  }

  public downloadBoleto(url: string): void {
    window.open(url);
    this.notification.showSuccess(
      'Boleto baixado com sucesso, a senha para abrir o arquivo são os 5 primeiros dígitos do seu CPF.',
      'Ok',
      { duration: 0 }
    );
  }

  public getInvoiceStatus(boleto: Boleto): InvoiceStatus {
    let status: InvoiceStatus;

    if (boleto.boletoPago) {
      status = InvoiceStatus.PAGO;
    } else if (boleto.boletoVencido && !boleto.boletoPago) {
      status = InvoiceStatus.VENCIDO;
    } else {
      status = InvoiceStatus.EM_ABERTO;
    }
    return status;
  }

  public filterNoInvoicePlans(plans: Planos[]) {
    return (
      plans.length > 0 &&
      plans.filter(
        (plan: Planos) => plan?.boletos !== null && plan?.boletos !== undefined
      )
    );
  }
}
