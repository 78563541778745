import { BoletoResponseDTO } from '@app/core/boleto';

export enum InvoiceListType {
  EM_ABERTO = 0,
  PAGOS = 1
}

export enum InvoiceStatus {
  PAGO = 'PAGO',
  VENCIDO = 'VENCIDO',
  EM_ABERTO = 'EM_ABERTO'
}

export enum InvoiceStatusText {
  PAGO = 'Pago',
  VENCIDO = 'Vencido',
  EM_ABERTO = 'A vencer'
}

export enum InvoiceStatusIcon {
  PAGO = 'check_circle_outline',
  VENCIDO = 'error_outline',
  EM_ABERTO = 'error_outline'
}

export class ActionData extends BoletoResponseDTO {
  registration: string;
}
