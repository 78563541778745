<div class="container">
  <mat-icon aria-hidden="false" aria-label="error icon">error_outline</mat-icon>
  <p>
    Ocorreu um erro. Por favor, entre em contato com nossa central de
    atendimento e atualize seu cadastro.
  </p>
  <app-call-center [options]="{ showTitle: false }"></app-call-center>
  <button
    mat-raised-button
    color="primary"
    title="Voltar para página inicial"
    routerLink="/auth"
  >
    Voltar
  </button>
</div>
