import { Plan } from '@app/core/new-diversification';

export type ValidAndInvalidPlansAmount = {
  valid: number;
  invalid: number;
};

export type ValidAndInvalidPlansArray = {
  valid: Plan[];
  invalid: Plan[];
};

export function getValidAndInvalidPlans(
  plans: Plan[]
): ValidAndInvalidPlansArray {
  return plans.reduce(
    (result, plan) => {
      const destinationList = plan.isEligible ? 'valid' : 'invalid';

      result[destinationList].push(plan);

      return result;
    },
    { valid: [], invalid: [] } as ValidAndInvalidPlansArray
  );
}

export function getValidAndInvalidPlansAmounts(
  plans: Plan[]
): ValidAndInvalidPlansAmount {
  const { valid, invalid } = getValidAndInvalidPlans(plans);

  return { valid: valid.length, invalid: invalid.length };
}
