export enum Feature {
  MONTHLY_PROFITABILITY = 'MONTHLY_PROFITABILITY',
  NEW_CONTRIBUTION = 'NEW_CONTRIBUTION',
  EXTRA_CONTRIBUTION = 'EXTRA_CONTRIBUTION',
  DEPARTURE_DATE = 'DEPARTURE_DATE',
  RELOCATION = 'RELOCATION',
  BOLETOS = 'BOLETOS',
  SURRENDER = 'SURRENDER',
  VERIFY_SURRENDER = 'VERIFY_SURRENDER',
  CERTIFICATE = 'CERTIFICATE',
  DEFINE_INCOME = 'DEFINE_INCOME',
  CHECKUP = 'CHECKUP',
  INCOME_TAX = 'INCOME_TAX',
  BALANCE = 'BALANCE'
}
export enum FeatureCanceledPlan {
  VERIFY_SURRENDER = 'VERIFY_SURRENDER',
  CERTIFICATE = 'CERTIFICATE'
}
export class SelectedPlanData {
  registration?: string;
  cardsFeatList?: Feature[];
  menuFeatList?: Feature[];

  constructor(
    registration?: string,
    cardsFeatList?: Feature[],
    menuFeatList?: Feature[]
  ) {
    this.registration = registration;
    this.cardsFeatList = cardsFeatList ?? [
      Feature.MONTHLY_PROFITABILITY,
      Feature.BOLETOS,
      Feature.EXTRA_CONTRIBUTION,
      Feature.NEW_CONTRIBUTION,
      Feature.DEPARTURE_DATE,
      Feature.RELOCATION,
      Feature.SURRENDER,
      Feature.VERIFY_SURRENDER,
      Feature.CERTIFICATE,
      Feature.DEFINE_INCOME,
      Feature.CHECKUP,
      Feature.INCOME_TAX
    ];
    this.menuFeatList = menuFeatList ?? [
      Feature.SURRENDER,
      Feature.VERIFY_SURRENDER,
      Feature.INCOME_TAX,
      Feature.DEFINE_INCOME,
      Feature.BALANCE
    ];
  }
}

export enum FeatTitle {
  MONTHLY_PROFITABILITY = 'Rendimento mês a mês',
  NEW_CONTRIBUTION = 'Alterar contribuição',
  EXTRA_CONTRIBUTION = 'Contribuição extra',
  DEPARTURE_DATE = 'Alterar idade de saída',
  RELOCATION = 'Realocar fundos',
  CHECKUP = 'Checkup previdenciário',
  BOLETOS = 'Boletos em aberto',
  CERTIFICATE = 'Certificado do plano',
  SURRENDER = 'Solicitar Resgate',
  VERIFY_SURRENDER = 'Acompanhar resgate',
  DEFINE_INCOME = 'Definir minha renda',
  INCOME_TAX = 'Informe de rendimentos',
  BALANCE = 'Extrato'
}

export enum FeatEligibleCall {
  MONTHLY_PROFITABILITY = 'isMonthlyProfitabilityEligible',
  NEW_CONTRIBUTION = 'isNewContributionEligible',
  EXTRA_CONTRIBUTION = 'isExtraContributionEligible',
  DEPARTURE_DATE = 'isDepartureEligible',
  RELOCATION = 'isRelocationEligible',
  BOLETOS = 'isBoletoEligible',
  CHECKUP = 'isCheckupEligible',
  CERTIFICATE = 'isCertificateEligible'
}

export enum FeatSelectionCall {
  MONTHLY_PROFITABILITY = 'onBalanceSelection',
  NEW_CONTRIBUTION = 'onNewContributionSelection',
  EXTRA_CONTRIBUTION = 'onExtraContributionSelection',
  DEPARTURE_DATE = 'onDepartureDateSelection',
  RELOCATION = 'onRelocationSelection',
  BOLETOS = 'onBoletoSelection',
  CHECKUP = 'onCheckupSelection',
  SURRENDER = 'onSurrenderSelection',
  CERTIFICATE = 'onCertificateSelection',
  VERIFY_SURRENDER = 'onVerifySurrenderSelection',
  DEFINE_INCOME = 'onDefineIncomeSelection',
  INCOME_TAX = 'onIncomeTaxSelection',
  BALANCE = 'onBalanceSelection'
}

export class FeatData {
  registration: string;
}
