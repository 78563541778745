import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthErrorMessage, Profile } from '@app/auth/auth.model';
import { AuthService } from '@app/auth/auth.service';
import { Data19Investor_profile_status } from '@app/core/bpApi';
import { LoginBBRequest, LoginBBResponse, LoginService } from '@app/core/login';
import { environment } from '@env/environment';
import { Observable, of, throwError } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import {
  ErrorResponseError_code as ErrorCode,
  Oauth2Service,
  Setup,
  SuccessApiAuth,
  SuccessAuth
} from '../core/authentication/authentication';
import { NotificationService } from '../core/notification/notification.service';
import { OpenInsuranceService } from '@app/open-insurance/open-insurance.service';

@Injectable({
  providedIn: 'root'
})
export class OAuthService {
  constructor(
    private router: Router,
    private bffOauthService: Oauth2Service,
    private authService: AuthService,
    public dialog: MatDialog,
    private notification: NotificationService,
    private loginService: LoginService,
    private readonly openInsuranceService: OpenInsuranceService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  public getBBAuthUrl(): string {
    const apiBaseUrl = environment.api.bb.url;
    const endpoint = '/ui/authorize';
    const redirectUri = this.document.location.origin + '/auth/redirect';
    const queryParams = new URLSearchParams('');
    queryParams.set('response_type', 'code');
    queryParams.set('client_id', environment.api.bb.client_id);
    queryParams.set('redirect_uri', redirectUri);
    queryParams.set('scope', 'openid-otp cpf');
    queryParams.set('state', 'state');
    const url = apiBaseUrl + endpoint + '?' + queryParams;
    return url;
  }

  public setup(setupData: Setup): Observable<SuccessAuth> {
    return this.loginService
      .loginBb(
        LoginBBRequest.fromJS({
          codigo: setupData.code,
          canalAcesso: setupData.source
        })
      )
      .pipe(
        map((response: LoginBBResponse) => this.parseToSuccessAuth(response)),
        catchError((error) => {
          if (error.error_code === ErrorCode.INVALID_USER) {
            this.notification.showError(
              AuthErrorMessage.MISSING_USER_DATA,
              'Ok',
              { duration: 0 }
            );
            this.router.navigate(['auth/error']);
          } else {
            this.notification.showError(AuthErrorMessage.GENERIC, 'Ok', {
              duration: 0
            });
          }
          return of(null);
        })
      );
  }

  public loginBB(authResult: SuccessAuth) {
    this.authService
      .getProfileBff(authResult.oauth2_token)
      .pipe(
        first(),
        catchError((error) => {
          if (error.error_code === ErrorCode.MISSING_USER_DATA) {
            this.notification.showError(
              AuthErrorMessage.MISSING_USER_DATA,
              'Ok',
              {
                duration: 0
              }
            );
            this.router.navigate(['auth/error']);
            return of(null);
          }
          return throwError(error);
        })
      )
      .subscribe((profile: Profile) => {
        this.authService.setPrivacyState(true);
        this.authService.storeUser(profile);
        this.authService.storeAuthResult(authResult);
        this.authService.loggedIn.next(true);

        if (this.openInsuranceService.isOpinJourney) {
          this.openInsuranceService.redirectToStartTransmissionJourney();
          return;
        }

        if (
          !authResult.data.flagPlanosAtualizados &&
          authResult.data.flagPossuiPlanos
        ) {
          this.router.navigate(['/plan/onboarding']);
          return;
        }
        if (
          profile.data.investor_profile_status ===
          Data19Investor_profile_status.PENDING_DEFINITION
        ) {
          this.router.navigate(['/profile/investor/pending']);
          return;
        } else {
          this.authService.profileUpdated.next(true);
          this.router.navigate(['/home']);
        }
      });
  }

  private parseToSuccessAuth(response: LoginBBResponse): SuccessAuth {
    return SuccessAuth.fromJS({
      oauth2_token: response.token,
      oauth2_refresh_token: response.refreshToken,
      oauth2_expires_in: response.tempoParaExpirar,
      oauth2_tokens_timestamp: response.timestamp,
      status: response.status,
      cpf: response.cpf,
      data: SuccessApiAuth.fromJS({
        tokenUsuario: response.dadosLogin.tokenUsuario,
        flagRegistroAtualizado: response.dadosLogin.flagRegistroAtualizado,
        flagPlanosAtualizados: response.dadosLogin.flagPlanosAtualizados,
        flagPossuiPlanos: response.dadosLogin.flagPossuiPlanos,
        codigo: response.dadosLogin.codigo
      })
    });
  }
}
