import { Injectable } from '@angular/core';
import { AuthService } from '@app/auth/auth.service';
import {
  SolicitationResponse as DepartureConfirmation,
  DepartureDateBff,
  DetailPlan as DeparturePlanDetails,
  SolicitationRequest as DepartureRequest,
  SimulationRequest as DepartureSimulationRequest,
  SimulationResponse as DepartureSimulationResponse,
  DetailPlan,
  HttpError,
  Plan,
  Plan as UpsellingListPlan
} from '@app/core/departure-date-bff';
import { PlanCard } from '@app/shared/components/registration-card/registration-card.model';
import { Prefix } from '@app/shared/models/prefix.model';
import { CardService } from '@app/shared/service/card.service';
import { SessiontorageData } from '@app/shared/utils/sessionStorage';

import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { ExceptionService } from './exception.service';
import {
  DepartureData,
  SessionStorageUpsellingId,
  UpBaseRoute
} from './upselling.model';
@Injectable({
  providedIn: 'root'
})
export class UpsellingService {
  public sessionStorageData = new SessiontorageData(Prefix.Upselling);
  extraContributionValue: number;
  lifeTimeRetirementId = 1;
  readonly instantiateBalance = 0;

  constructor(
    private authService: AuthService,
    private exceptionService: ExceptionService,
    private departureBff: DepartureDateBff,
    private cardService: CardService,
    private router: Router
  ) {}

  public getDeparturePlans(): Observable<PlanCard[]> {
    return this.departureBff.plansList(this.authService.getToken()).pipe(
      take(1),
      map((plans: UpsellingListPlan[]) =>
        plans.map((plan: UpsellingListPlan) =>
          this.cardService.convertToPlanCard(plan, 'simular')
        )
      )
    );
  }

  public setPlanData(plan: DepartureData): void {
    this.sessionStorageData.setData(
      SessionStorageUpsellingId.departureDateData,
      JSON.stringify(plan)
    );
  }

  public getPlanData(): DepartureData {
    return JSON.parse(
      this.sessionStorageData.getData(
        SessionStorageUpsellingId.departureDateData
      )
    );
  }

  public onSelection(plan: Plan): Observable<DetailPlan> {
    const convertedPlan = this.cardService.convertToPlanCard(plan);
    return this.getDeparturePlan(plan.registration).pipe(
      take(1),
      tap((details: DetailPlan) => {
        this.setPlanData({ plan: convertedPlan, details } as DepartureData);
        this.router.navigate([
          ...UpBaseRoute.departureDate,
          'simulator',
          plan.registration
        ]);
      })
    );
  }

  public getDeparturePlan(
    registration: string
  ): Observable<DeparturePlanDetails> {
    return this.departureBff
      .plan(this.authService.getToken(), registration)
      .pipe(take(1));
  }

  public getDepartureSimulation(
    registration: string,
    newAge: number
  ): Observable<DepartureSimulationResponse> {
    return this.departureBff
      .simulation(this.authService.getToken(), {
        newRetirementAge: newAge,
        registration
      } as DepartureSimulationRequest)
      .pipe(take(1));
  }

  public confirmDeparture(
    confirmationData: DepartureRequest
  ): Observable<DepartureConfirmation> {
    return this.departureBff
      .solicitation(this.authService.getToken(), confirmationData)
      .pipe(
        take(1),
        catchError((error: HttpError) =>
          this.exceptionService.handleTransactionExceptions(error)
        )
      );
  }
}
