import { Component } from '@angular/core';
import { MatDialogActions, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-redirect-modal',
  templateUrl: './redirect-modal.component.html',
  styleUrls: ['./redirect-modal.component.scss']
})
export class RedirectModalComponent {
  constructor(public dialogRef: MatDialogRef<MatDialogActions>) {}
}
