import { Injectable } from '@angular/core';
import { OsType } from './os.model';

@Injectable({
  providedIn: 'root'
})
export class OsService {
  readonly userAgent = window.navigator.userAgent;

  constructor() {}

  public identifyOS(): OsType {
    if (/Android/i.test(this.userAgent)) {
      return OsType.ANDROID;
    }

    if (/Linux/i.test(this.userAgent)) {
      return OsType.LINUX;
    }

    if (/Windows/.test(this.userAgent)) {
      return OsType.WINDOWS;
    }

    if (/Macintosh/.test(this.userAgent)) {
      return OsType.MAC_OS;
    }

    if (/iPad|iPhone|iPod/.test(this.userAgent)) {
      return OsType.IOS;
    }

    return OsType.UNKNOWN;
  }
}
