<h3>Cobertura de risco</h3>
<mat-card *ngIf="!riskCoverage?.length">
  <span>Não existem benefícios de risco contratados para esse plano</span>
</mat-card>
<mat-card *ngFor="let item of riskCoverage">
  <mat-card-content>
    <ng-container class="custom-table">
      <div class="custom-table__row">
        <span>Tipo:</span>
        <span class="custom-table__value">{{ item.name }}</span>
      </div>
      <div class="custom-table__row">
        <span>Benefício:</span>
        <span
          class="custom-table__value"
          [appPrivacy]="item.prize | currency"
        ></span>
      </div>
      <div class="custom-table__row">
        <span>Contribuição:</span>
        <span
          class="custom-table__value"
          [appPrivacy]="item.amount | currency"
        ></span>
      </div>
      <div class="custom-table__row">
        <span>Periodicidade:</span>
        <span class="custom-table__value">{{
          item.frequencyContribution
        }}</span>
      </div>
    </ng-container>
  </mat-card-content>
</mat-card>
