import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OpinLabelComponent } from './label.component';

@NgModule({
  declarations: [OpinLabelComponent],
  exports: [OpinLabelComponent],
  imports: [CommonModule]
})
export class OpinLabelModule {}
