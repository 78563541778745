import { Injectable } from '@angular/core';
import { OfficeHour } from '@app/surrender/withdraw.types';
import { Apollo, gql } from 'apollo-angular';
import { Observable, throwError } from 'rxjs';
import { catchError, pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {
  constructor(private apollo: Apollo) {}

  getSchedule(): Observable<OfficeHour> {
    return this.apollo
      .use('withdraw')
      .watchQuery({
        query: gql`
          query {
            officeHour {
              onOfficeHour
              clock
            }
          }
        `
      })
      .valueChanges.pipe(
        pluck('data', 'officeHour'),
        catchError(error => throwError(error))
      );
  }
}
