import { formatPercent } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { PlanSummary } from '@app/home/statement.model';
import { StatementService } from '@app/home/statement.service';
import { PlanService } from '@app/plan/plan.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-info-tile',
  templateUrl: './info-tile.component.html',
  styleUrls: ['./info-tile.component.scss']
})
export class InfoTileComponent implements OnInit {
  infoListArr: [string, number | string][];
  @Input() registration: string;
  @Input() cpf: string;
  @Input() planModality: string;
  @Input() planInfo;
  pdfUrl: string;
  showRegulamentarStatement: boolean;
  plan: PlanSummary;
  grantDate: string;

  constructor(
    private planService: PlanService,
    private ref: ChangeDetectorRef,
    private statementService: StatementService
  ) {}

  ngOnInit(): void {
    this.planInfo.subscribe((res) => {
      this.infoListArr = Object.keys(res).map((key) => [key, res[key]]);
      this.infoListArr.shift();

      if (this.infoListArr[3][0] === 'taf') {
        this.infoListArr[3][1] = formatPercent(
          +this.infoListArr[3][1] / 100,
          'pt-BR',
          '0.1-2'
        );
      }
    });
    this.populatePlanData();

    this.showRegulamentarStatement = !this.plan?.servicesToHide?.includes(
      'EXTRATO_REGULAMENTAR'
    );

    this.loadPDFDownloadUrl();
  }

  loadPDFDownloadUrl(): void {
    this.planService
      .getRegulatoryStatement(this.registration)
      .pipe(first())
      .subscribe((res) => {
        this.pdfUrl = `https://www1.brasilprev.com.br/redirecionar?url=${res.url}`;
        this.ref.markForCheck();
      });
  }

  public populatePlanData() {
    const data = this.statementService.getPlanData();
    this.plan = data.filter(
      (item) => item.registration === this.registration
    )[0];

    if (this.plan && this.plan.grantDate) {
      this.grantDate = this.plan.grantDate.slice(6, this.plan.grantDate.length);
    }
  }
}
