import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { ChartInterface, DoughnutChartInterface } from './chats';
import { Rentabilidade } from './detalheFundo';

@Injectable({
  providedIn: 'root'
})
export class InvestmentDetailService {
  data: any = [];
  meses: Array<string> = [
    'JAN',
    'FEV',
    'MAR',
    'ABR',
    'MAI',
    'JUN',
    'JUL',
    'AGO',
    'SET',
    'OUT',
    'NOV',
    'DEZ'
  ];
  colors = ['#0B5CA6', '#2790F1', '#87C1F7', '#1896AA', '#1FBFD7', '#F9CD56'];
  dataReferencia = new Date();

  constructor(private http: HttpClient) {}

  getData(nomeInvestimento: string): Observable<any> {
    const url = `${environment.api.digitalSiteBff}/v1/investimentos/investimento/${nomeInvestimento}`;
    return this.http.get(url);
  }

  getRentabilidade(rentabilidade): ChartInterface {
    rentabilidade = rentabilidade.map((item, i) => {
      const data = item.rentabilidadeFundo
        .slice(0, 12)
        .map((value) => value * 100);
      const label = item.ano;
      return {
        data,
        label,
        fill: false,
        showLine: true,
        backgroundColor: this.colors[i],
        pointBackgroundColor: this.colors[i],
        borderColor: this.colors[i],
        pointHoverBorderColor: this.colors[i],
        pointHoverBackgroundColor: this.colors[i],
        pointBorderColor: this.colors[i],
        pointBorderWidth: 1
      };
    });

    return {
      chartLegend: !!rentabilidade[0].label,
      chartType: 'line',
      chartData: { labels: this.obterMeses(), datasets: rentabilidade },
      chartLabels: this.obterMeses(),
      chartOptions: {
        responsive: true,
        maintainAspectRatio: true
      }
    };
  }

  getPatrimonio(patrimonio = [], isMobile = false): any {
    let labels = [];
    let valores = [];
    valores = patrimonio.map((item) => item.plm);
    labels = patrimonio
      .map((item) =>
        item.plmLabel.substr(item.plmLabel.length - 5).substr(0, 4)
      )
      .filter((label, i) => (valores[i] ? label : undefined));
    valores = valores.filter(Number);
    return {
      chartType: 'bar',
      chartLegend: false,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: true
      },
      chartData: [{ data: valores }],
      chartColors: [{ backgroundColor: this.colors }]
    };
  }

  getIndicadores(fundo, indicadores): ChartInterface {
    fundo = fundo
      .filter((itemFundo) => itemFundo.indicador === 'fundo')
      .map((item, y) => {
        const data = item.rentabilidade.map((obj) => obj.valor * 100);
        const label = item.indicador.toUpperCase();
        return {
          data,
          label,
          fill: false,
          backgroundColor: this.colors[y],
          pointBackgroundColor: this.colors[y],
          borderColor: this.colors[y],
          pointHoverBorderColor: this.colors[y],
          pointHoverBackgroundColor: this.colors[y],
          pointBorderColor: this.colors[y],
          pointBorderWidth: 1
        };
      });

    indicadores = indicadores.map((item, i) => {
      const data = item.rentabilidade.map((obj) => obj.valor * 100);
      const label = item.indicador.toUpperCase();

      return {
        data,
        label,
        fill: false,
        backgroundColor: this.colors[i + 1],
        pointBackgroundColor: this.colors[i + 1],
        borderColor: this.colors[i + 1],
        pointHoverBorderColor: this.colors[i + 1],
        pointHoverBackgroundColor: this.colors[i + 1],
        pointBorderColor: this.colors[i + 1],
        pointBorderWidth: 1
      };
    });

    indicadores = [...fundo, ...indicadores];
    const labels = ['Há 60m', 'Há 36m', 'Há 24m', 'Há 12m', 'Ano', 'Mês'];
    return {
      chartLegend: true,
      chartType: 'line',
      chartData: { labels, datasets: indicadores },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: true
      }
    };
  }

  getComposicaoRisco(composicao): DoughnutChartInterface {
    const label = composicao.map((item) => item.label);
    const data = composicao.map((item, i) => +(item.mesAtual * 100).toFixed(2));

    return {
      chartData: {
        labels: label,
        datasets: [{ data, backgroundColor: this.colors }]
      },
      chartType: 'doughnut',
      chartLegend: true,
      chartColors: [{ backgroundColor: this.colors }],
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom'
          }
        }
      }
    };
  }

  convertToDate(dateString): Date {
    const d = dateString.split('/');
    const data = new Date(d[2] + '/' + d[1] + '/' + d[0]);
    return data;
  }

  obterRentabilidade12MesesComLabels(rentabilidadeRaw: Rentabilidade[]): {
    labels: string[];
    patrimonio: number[];
  } {
    return {
      labels: this.obterMeses(),
      patrimonio: this.obterRentabilidadeFundoUltimos12Meses(rentabilidadeRaw)
    };
  }

  obterRentabilidadeFundoUltimos12Meses(
    rentabilidades: Rentabilidade[]
  ): number[] {
    const result: number[] = [];
    for (let i = 12; i > 0; i--) {
      const data = this.subtrairMes(this.dataReferencia, i);
      result.push(
        rentabilidades.find(
          (rentabilidade: Rentabilidade) =>
            rentabilidade.ano === data.getFullYear()
        )?.rentabilidadeFundo[data.getMonth()]
      );
    }
    return result;
  }

  obterMeses(): Array<string> {
    const dataReferencia = new Date(this.dataReferencia);
    const meses = [];
    let qtd = 12;
    while (qtd > 0) {
      const data = this.subtrairMes(dataReferencia, qtd);
      meses.push(this.meses[data.getMonth()]);
      qtd--;
    }
    return meses;
  }

  subtrairMes(date: Date, qtd: number): Date {
    const d = new Date(date);
    d.setMonth(d.getMonth() - qtd);
    return d;
  }
}
