import { WithdrawSuccessEventObjectModel } from '@app/surrender/withdraw.model';
import { HeadOfficeCapitals } from './messages.model';
import { Events } from './models/withdraw-events.model';

export const requiredFill = 'Esse campo é obrigatório!';
export const incorrectCPF = 'Digite um CPF válido!';
export const noPlan = 'Você não possuí plano cadastrado.';
export const refresh = 'Por favor, tente atualizar a página.';
export const messageNoMoviments = 'Você ainda não tem nenhuma movimentação!';
export const noValidEmail =
  'Seu e-mail não deve estar validado. Valide para prosseguir.';
export const noCPFOnBrasilprev = 'CPF não cadastrado!';
export const incorrectCode = 'Ops! Código errado!';
export const forwardedCode = 'Código reenviado';
export const validCode = 'Código validado';
export const passwordSuccessfullyRegistered =
  'Nova senha cadastrada com sucesso!';
export const expiredCode = 'Código expirado!';
export const noCPF = 'CPF não informado!';
export const invalidUser = 'Você já possui senha cadastrada.';
export const unavailableData =
  'Infelizmente não conseguimos prosseguir com os dados informados.';
export const headOfficeCapitals = {
  phoneText: '0800 729 7170',
  phoneNumber: '08007297170'
} as HeadOfficeCapitals;

export const withdrawSuccessMessages = {
  messages: {
    journey_1: {
      [Events.SUCCESS]: {
        icon: 'done',
        title: 'SOLICITAÇÃO DE RESGATE EFETUADA COM SUCESSO',
        description:
          'Em breve você receberá um e-mail com informações detalhadas do seu resgate.',
        warning:
          'A sua solicitação de resgate só poderá ser cancelada até as 23h59 do dia de hoje.'
      } as WithdrawSuccessEventObjectModel,
      [Events.DECLINE]: {
        icon: 'clear',
        title: 'SOLICITAÇÃO DE RESGATE CANCELADA',
        description: 'Você cancelou o resgate do seu plano'
      } as WithdrawSuccessEventObjectModel
    },
    journey_3: {
      [Events.SUCCESS]: {
        icon: 'done',
        title: 'CANCELAMENTO DE RESGATE EFETUADO COM SUCESSO!',
        description:
          'Em breve você receberá um e-mail com informações detalhadas do seu cancelamento.'
      } as WithdrawSuccessEventObjectModel,
      [Events.DECLINE]: {
        icon: 'clear',
        title: 'SOLICITAÇÃO DE CANCELAMENTO DE RESGATE CANCELADA',
        description:
          'Você cancelou a solicitação de cancelamento do resgate do seu plano'
      } as WithdrawSuccessEventObjectModel
    }
  }
};

export const headerTile = {
  balance: {
    title: 'Quanto eu tenho?',
    tooltip: `É o valor que você já tem acumulado neste plano, ou seja, a soma de contribuições e rendimentos, e a subtração
       de eventuais taxas e impostos que este plano já possa ter sofrido.`
  },
  balanceYield: {
    title: 'Quanto já rendeu?',
    tooltip:
      'É o valor que as contribuições geram, a partir dos aportes que são efetuados neste plano.'
  },
  projectedBalance: {
    tooltip:
      'Este é seu saldo projetado que você irá alcançar na data de saída do seu plano.'
  },
  projectedIncome: {
    title: 'Renda mensal projetada',
    tooltip:
      'Essa será a sua renda mensal que foi calculada com base no saldo projetado.'
  }
};
