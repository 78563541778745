import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PlanSummary } from '@app/home/statement.model';
import { StatementService } from '@app/home/statement.service';
import { format } from '@app/shared/date-fns';
import { ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { combineLatest } from 'rxjs';
import { PlanData } from '../plan.model';
import { PlanService } from '../plan.service';

@Component({
  selector: 'app-statement-summary-page',
  templateUrl: './statement-summary-page.component.html',
  styleUrls: ['./statement-summary-page.component.scss']
})
export class StatementSummaryPageComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective;
  registration: string;
  initialDate: string;
  grantDate: string;
  plan: PlanSummary;
  totalRelease: string;

  projectedIncome;
  projectedReservation;

  datasets = [
    {
      data: [],
      fill: true,
      backgroundColor: ['#E7EBF9'],
      borderColor: ['#465eff'],
      pointBackgroundColor: ['#465eff'],
      lineTension: 0.5,
    }
  ];
  labels = ['', ''];

  option = {
    backgroundColor: ['#E7EBF9'],
    borderColor: ['#E7EBF9'],
    elements: {
      point: {
        backgroundColor: ['#E7EBF9'],
        borderColor: ['#E7EBF9'],
        borderWidth: 0,
        radius: 5
      }
    },
    plugins: {
      legend: {
          display: false
      }
  }
  } as ChartOptions;

  constructor(
    private router: Router,
    private planService: PlanService,
    private statementService: StatementService
  ) {}

  ngOnInit(): void {
    this.registration = this.router.url.split('/')[2];

    this.initialDate = this.planService.getPlanData(PlanData.InitialDate);
    this.populatePlanData();

    const summary = this.planService.getSummaryBalance(
      this.registration,
      format({ date: this.initialDate, dateFormat: 'yyyy-MM-dd' }),
      format({ date: new Date(), dateFormat: 'yyyy-MM-dd' })
    );
    const incomeProjection = this.planService.getIncomeReserveProjection(
      this.registration
    );
    const planData = this.statementService.getPlansGraphql();

    combineLatest([summary, incomeProjection, planData]).subscribe(
      (res) => {
        if (res[2]) {
          this.plan = res[2].filter(
            (item) => item.registration === this.registration
          )[0];
          this.grantDate = this.plan?.grantDate?.slice(
            6,
            this.plan.grantDate.length
          );
        }
        this.totalRelease = res[0].summaryBalanceStatement?.totalRelease;
        this.projectedIncome = res[1].incomeValue;
        this.projectedReservation = res[1].reserveValue;
        this.datasets[0].data = [this.totalRelease, this.projectedReservation];
        this.labels = [new Date().getFullYear().toString(), this.grantDate];
      },
      (error) => console.error(error)
    );
  }

  public populatePlanData() {
    const data = this.statementService.getPlanData();
    if (data) {
      this.plan = data.filter(
        (item) => item.registration === this.registration
      )[0];
    }

    if (this.plan && this.plan.grantDate) {
      this.grantDate = this.plan.grantDate.slice(6, this.plan.grantDate.length);
    }
  }
}
