<div class="container">
  <mat-card>
    <h3>Identificação</h3>
    <ng-container *ngIf="!isLoading">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="stored-user" *ngIf="cpfObj.digitis">
          <mat-icon>how_to_reg</mat-icon>
          <div class="content">
            <span>CPF</span>
            <span>{{ cpfObj.formated }}</span>
          </div>
          <button
            mat-button
            color="primary"
            type="button"
            (click)="removeLoginData()"
          >
            Trocar
          </button>
        </div>

        <mat-form-field appearance="outline" *ngIf="!cpfObj.digitis">
          <mat-label>CPF</mat-label>
          <input
            #input
            matInput
            type="text"
            formControlName="cpf"
            name="cpf"
            required
            inputmode="numeric"
          />
          <mat-error *ngIf="form.controls['cpf'].invalid">
            <span
              *ngIf="form.controls['cpf'].errors?.required; else invalidCPF"
            >
              Esse campo é obrigatório!
            </span>
            <ng-template #invalidCPF>
              <span>Digite um CPF válido!</span>
            </ng-template>
          </mat-error>
        </mat-form-field>

        <mat-checkbox
          color="primary"
          formControlName="storeCPF"
          (change)="onCheckboxChange()"
          *ngIf="!cpfObj.digitis"
        >
          Lembrar dados
        </mat-checkbox>

        <div class="actions">
          <button
            class="main-button"
            [disabled]="isLoading"
            mat-raised-button
            color="primary"
            type="submit"
            title="Avançar"
          >
            Continuar
          </button>
          <a
            mat-button
            [routerLink]="['/auth/password-setup/new']"
            title="Criar senha"
          >
            Primeiro acesso
          </a>
        </div>
      </form>
      <mat-spinner *ngIf="isLoading"></mat-spinner>
    </ng-container>
  </mat-card>

  <div class="warning">
    <mat-icon>info</mat-icon>
    <h4>Aqui também dá pra usar o seu login e senha do Banco do Brasil</h4>
    <p>Acesse seus planos de previdência com os dados da sua conta</p>
    <button mat-button color="primary" (click)="openRedirectDialog()">
      Usar senha do Banco
    </button>
  </div>
</div>
