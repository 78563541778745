<fieldset>
  <div role="heading" aria-level="3">Fundos aplicados</div>
  <mat-form-field>
    <mat-label>pesquisar fundo de investimento...</mat-label>
    <mat-chip-grid #chipList aria-label="pesquisar fundo de investimento">
      <mat-chip-row *ngFor="let fund of funds" [removable]="removable" (removed)="removeFundList(fund)">
        {{ fund | uppercase }}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip-row>
      <input #fundInput #autocompleteTrigger="matAutocompleteTrigger" [formControl]="fundCtrl" [matAutocomplete]="auto"
        [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="addFundList()" />
    </mat-chip-grid>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedFund($event)">
      <mat-option *ngFor="let fund of filteredFunds | async" [value]="fund">
        <mat-checkbox color="primary" [checked]="funds.indexOf(fund) >= 0"></mat-checkbox>
        {{ fund | uppercase }}
      </mat-option>
    </mat-autocomplete>
    <button mat-icon-button matSuffix (click)="openListFunds(autocompleteTrigger)">
      <mat-icon>expand_more</mat-icon>
    </button>
  </mat-form-field>
</fieldset>

<canvas *ngIf="data.length > 0" #chart baseChart [datasets]="data" [type]="'line'" [labels]="labels" [options]="option">
</canvas>