import { Injectable } from '@angular/core';
import { Data19, Profiles, SuccessResponseProfileV2 } from '@app/core/bpApi';
import { Perfil, PerfilResponse } from '@app/core/login';
import { Profile } from './auth.model';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor() {}

  public parseToProfileV2(perfilResponse: PerfilResponse) {
    const data = perfilResponse.dadosPerfil;

    const parsedProfileV2 = SuccessResponseProfileV2.fromJS({
      status: perfilResponse.status,
      data: Data19.fromJS({
        id: data.id,
        cpf: data.cpf,
        name: data.nome,
        registration: data.matricula,
        email: data.email,
        ddd: data.ddd,
        telephone: data.telefone,
        is_superuser: data.flagSuperUsuario,
        is_active: data.flagAtivo,
        validated_email: data.flagEmailValidado,
        has_password: data.flagTemPassword,
        created_date: data.dataCriacao,
        modified_date: data.dataModificacao,
        civil_status: data.estadoCivil,
        image_url: data.urlImagem,
        birth_date: data.dataNascimento,
        nationality: data.nacionalidade,
        gender: data.genero,
        address: data.endereco.logradouro,
        address_number: data.endereco.numero,
        address_complement: data.endereco.complemento,
        neighbourhood: data.endereco.bairro,
        city: data.endereco.cidade,
        state: data.endereco.uf,
        zip_code: data.endereco.cep,
        flagPlanosAtualizados: data.flagPlanosAtualizados,
        is_updated: data.flagAtualiizado,
        profiles: this.parseEachToProfile(data.perfis)
      })
    });

    const profile = new Profile(parsedProfileV2);
    profile.data.socialName = data.nomeSocial;

    return profile;
  }

  parseEachToProfile(profiles: Perfil[]): Profiles[] {
    return profiles.map((profile) =>
      Profiles.fromJS({
        person_id: profile.idBup,
        name: profile.nome,
        cpf: profile.cpf,
        birth_date: profile.dataNascimento,
        civil_status_id: profile.idEstadoCivil,
        nationality_id: profile.idNacionalidade,
        nationality_name: profile.nacionalidade,
        document_type_id: profile.idTipoDocumento,
        document_type_name: profile.nomeTipoDocumento,
        gender_id: profile.idGenero,
        gender_name: profile.genero,
        financial_responsible_identifier:
          profile.indicadorResponsavelFinanceiro,
        address_id: profile.idEndereco,
        address_street: profile.logradouro,
        address_neighborhood: profile.bairro,
        address_city: profile.cidade,
        address_uf: profile.uf,
        address_zip_code: profile.cep,
        ddd: profile.ddd,
        telephone: profile.telefone,
        email: profile.email,
        created_date: profile.dataCriacao,
        modified_date: profile.dataModificacao
      })
    );
  }
}
