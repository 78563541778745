import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { environment } from '@env/environment';
import { API_BASE_URL, AuthService } from './bpApi';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [
    {
      provide: API_BASE_URL,
      useValue: environment.api.brasilprev.url
    },
    AuthService
  ],
  exports: []
})
export class BpApiModule {}
