import { Injectable } from '@angular/core';
import axios from 'axios';

import { environment } from '@env/environment';

import {
  CompleteConsent,
  CompleteConsentPayloadDTO,
  ConsentDTO,
  ErrorConsent,
  TransmissionSharingDTO
} from './opin-consent.model';

@Injectable()
export class OpinConsentService {
  private readonly api = axios.create({
    baseURL: `${environment.opin.api_opin}/opin-consent`
  });

  async getTransmissionManagementConsentById(
    consentId: string,
    token: string
  ): Promise<ConsentDTO> {
    const { data: consent } = await this.api.get<ConsentDTO>(
      `/transmission-management/consent/${consentId}`,
      {
        headers: {
          Authorization: token
        }
      }
    );

    return consent;
  }

  async fetchTransmissionConsentManage(
    cpf: string,
    token: string
  ): Promise<TransmissionSharingDTO[]> {
    const { data: consents } = await this.api.get<TransmissionSharingDTO[]>(
      `/transmission-management/consents`,
      {
        params: {
          cpf
        },
        headers: {
          Authorization: token
        }
      }
    );

    return consents;
  }

  async authenticateConsent(
    consentId: string,
    token: string
  ): Promise<ConsentDTO> {
    const { data: consent } = await this.api.get<ConsentDTO>(
      `/transmission/consent/${consentId}`,
      {
        headers: {
          Authorization: token
        }
      }
    );

    return consent;
  }

  async refuseConsent(consentId: string, token: string): Promise<ErrorConsent> {
    const { data: refusedConsent } = await this.api.put<ErrorConsent>(
      `/transmission/consent/${consentId}/refuse`,
      null,
      {
        headers: {
          Authorization: token
        }
      }
    );

    return refusedConsent;
  }

  async revokeTransmissionConsent(
    consentId: string,
    token: string
  ): Promise<void> {
    await this.api.patch(
      `/transmission-management/consent/${consentId}/revoke`,
      null,
      {
        headers: {
          Authorization: token
        }
      }
    );
  }

  async revokeReceptionConsent(
    consentId: string,
    token: string
  ): Promise<void> {
    await this.api.patch(
      `/reception-management/consent/${consentId}/revoke`,
      null,
      {
        headers: {
          Authorization: token
        }
      }
    );
  }

  async cancelConsent(
    consentId: string,
    resourceIds: string[],
    token: string
  ): Promise<ErrorConsent> {
    const { data: canceledConsent } = await this.api.put<ErrorConsent>(
      `/transmission/consent/${consentId}/cancel`,
      {
        resource_ids: resourceIds
      },
      {
        headers: {
          Authorization: token
        }
      }
    );

    return canceledConsent;
  }

  async completeConsent(
    { commandId, ...payload }: CompleteConsentPayloadDTO,
    token: string
  ): Promise<CompleteConsent> {
    const { data: completedConsent } = await this.api.put<CompleteConsent>(
      `/transmission/consent/${commandId}/confirm`,
      payload,
      {
        headers: {
          Authorization: token
        }
      }
    );

    return completedConsent;
  }
}
