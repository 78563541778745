<table mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="Mês">
    <th mat-header-cell *matHeaderCellDef>Mês</th>
    <td mat-cell *matCellDef="let element">{{ element.mes }}</td>
  </ng-container>

  <ng-container matColumnDef="Saldo">
    <th mat-header-cell *matHeaderCellDef>Saldo</th>
    <td mat-cell *matCellDef="let element">
      {{ element.saldo | currency : 'BRL' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="Rend. em R$">
    <th mat-header-cell *matHeaderCellDef>Rend. em R$</th>
    <td mat-cell *matCellDef="let element">
      {{ element.rend | currency : 'BRL' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="Rent. média">
    <th mat-header-cell *matHeaderCellDef>Rent. média</th>
    <td mat-cell *matCellDef="let element">
      {{ element.rent | percent : '1.2' }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
