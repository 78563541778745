import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OpinCardComponent } from './opin-card.component';

import { OpinIconModule } from '../../atoms/icon/icon.module';
import { OpinLabelModule } from '../../atoms/label/label.module';
import { OpinButtonModule } from '../../atoms/button/button.module';

@NgModule({
  exports: [OpinCardComponent],
  declarations: [OpinCardComponent],
  imports: [CommonModule, OpinIconModule, OpinLabelModule, OpinButtonModule]
})
export class OpinCardModule {}
