<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <strong>Para qual e-mail deseja enviar o boleto?</strong>
  <mat-form-field>
    <mat-label>E-mail</mat-label>
    <input #input matInput type="text" formControlName="email" name="email" />
    <mat-error *ngIf="form.controls['email'].invalid">
      <span *ngIf="form.controls['email'].errors?.required; else invalidEmail">
        Esse campo é obrigatório
      </span>
      <ng-template #invalidEmail>
        <span>Digite um e-mail válido</span>
      </ng-template>
    </mat-error>
  </mat-form-field>
  <button mat-raised-button color="primary" type="submit" title="Enviar">
    Enviar
  </button>
</form>
