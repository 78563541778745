import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-transfer-item',
  templateUrl: './transfer-item.component.html',
  styleUrls: ['./transfer-item.component.scss']
})
export class TransferItemComponent {
  @Input() transferItem;
  public isDetailsVisible = false;

  toggleDetails() {
    this.isDetailsVisible = !this.isDetailsVisible;
  }
}
