import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import {
  SuccessResponseUpdatePlan,
  SuccessResponseUpdatePlanStatus
} from '@app/core/bpApi';
import { PlanObjective } from '@app/plan/plan.model';
import { PlanService } from '@app/plan/plan.service';
import { from } from 'rxjs';
import {
  filter,
  finalize,
  first,
  mergeMap,
  switchMap,
  toArray
} from 'rxjs/operators';
import { StatementService } from './../../home/statement.service';
import { bodyUpdatePlan } from './../plan.model';

@Component({
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent {
  constructor(
    private planService: PlanService,
    private router: Router,
    private authService: AuthService,
    private statementService: StatementService
  ) {}

  onboarding$ = this.statementService.getPlansGraphql().pipe(
    first(),
    mergeMap((plans) => from(plans)),
    mergeMap((plan) =>
      this.planService.updatePlanGraphQL({
        numeroMatricula: plan.registration,
        nome: `Matricula: ${plan.registration}`,
        tipo: PlanObjective.RETIREMENT,
        valor: 0
      } as bodyUpdatePlan)
    ),
    filter(
      (res: SuccessResponseUpdatePlan) =>
        res.status === SuccessResponseUpdatePlanStatus.UPDATED
    ),
    toArray(),
    switchMap(() => this.authService.updateUserData()),
    finalize(() => {
      this.router.navigate(['/home']);
    })
  );
}
