import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route } from '@angular/router';

import { FeatureFlagService } from './feature-flag.service';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagGuard  {
  constructor(private readonly featureFlagService: FeatureFlagService) {}

  canLoad(route: Route): boolean {
    if (!route.data.flag) {
      throw new Error('Need to pass "flag" into data params from "Route"');
    } else {
      const { flag } = route.data;

      return this.featureFlagService.checkIsFeatureFlagActivateable(flag);
    }
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (!route.data.flag) {
      throw new Error('Need to pass "flag" into data params from "Route"');
    } else {
      const { flag } = route.data;

      return this.featureFlagService.checkIsFeatureFlagActivateable(flag);
    }
  }
}
