import { StatementDetail } from './statement-item/statement-item.model';
import { Moment } from 'moment';

export class PlanTracking {
  'planTrackingStatement': {
    year: number;
    yearBalance: number;
    yearProfitability: number;
    yearYield: number;
    monthsType: {
      month: number;
      monthBalance: number;
      monthProfitability: number;
      monthYield: number;
      referenceDate: Moment;
    };
  };
}

export enum PlanObjective {
  RETIREMENT = 'Aposentadoria',
  KIDS_FUTURE = 'Futuro dos filhos',
  SUCCESSION = 'Sucessão',
  EMERGENCY_RESERVE = 'Reserva de emergência',
  GOODS_ACQUISITION = 'Aquisição de bens'
}

export enum PlanData {
  InitialDate = 'initialDate',
  Balance = 'balance',
  BalanceYield = 'balanceYield',
  Taf = 'averageTaf',
  ProcessingDate = 'processingDate',
  ReferenceDate = 'referenceDate',
  GrantDate = 'grantDate',
  ModalityName = 'modalityName',
  Name = 'name',
  SinglePlan = 'singlePlan',
  StatusPlanoDetalhe = 'statusPlanoDetalhe',
  StatusPlano = 'statusPlano',
  IdStatusPlanoDetalhe = 'idStatusPlanoDetalhe',
  isChangeDepartureDateAvailable = 'isChangeDepartureDateAvailable',
  isDecumulationAvaliable = 'isDecumulationAvaliable',
  isGrantDateExpired = 'isGrantDateExpired',
  Registration = 'registration'
}

export interface SummaryBalance {
  totalRelease: number;
  totalReleaseCompany: number;
  totalReleaseParticipant: number;
  supplementaryReleases: {
    totalRelease: number;
    totalReleaseParticipant: number;
    totalReleaseCompany: number;
  };
}

export interface IncomeTax {
  incomeTaxStatement: {
    cpf: string;
    year: string;
    link: string;
  };
}

export interface ValidAndInvalidPlans<T> {
  valid: T[];
  invalid: T[];
}
export interface bodyUpdatePlan {
  numeroMatricula: string;
  nome: string;
  tipo: string;
  valor: number;
}
export class Summary {
  balance: number;
  balanceYield: number;
  plans: Plan[];
}
export class Plan {
  registration: string;
  modalityName: string;
  planType: string;
  planStatus: string;
  name: string;
  balance: number;
  balanceYield: number;
  icon: string;
  startDate: Date;
  objective: string;
  objectiveValue: number;
  objectiveRemainingValue: number;
  progress: number;
  statement: StatementDetail[];
  funds: Fund[];
  userFunds: Profitability[];
  availableFunds: Profitability[];
  projectedIncome: number;
  projectedBalance: number;
  grantDate: Date;
  referenceDate: Date;
  endDate: Date;
  juniorOwnership?: boolean;
}

export class Profitability {
  name: string;
  currencyYear: number;
  months12: number;
  months36: number;
  months60: number;
  volalitily: number;
  fmf: number;
}

export class AvailableInvestmentProfitability {
  'availableInvestmentProfitabilityStatement': {
    investmentId: string;
    name: string;
    description: string;
    investmentTaf: string;
    investmentCnpj: string;
    percentageAccumulatedYear: number;
    percentageAccumulated12: number;
    percentageAccumulated36: number;
    percentageAccumulated60: number;
    riskGrade: string;
  };
}

export class RiskCoverage {
  'riskCoverageStatement': {
    name: string;
    prize: number;
    amount: number;
    frequencyContribution: string;
  };
}

export class PlanInfo {
  'planInfoStatement': {
    concessionDate: Date;
    incomeType: string;
    taxationType: string;
    taf: number;
  };
}

export class InvestmentProfitability {
  'investmentProfitabilityStatement': {
    averagePercentageCurrentMonth: number;
    averagePercentagePastMonth: number;
    averagePercentageAccumulatedYear: number;
    averagePercentageAccumulated12: number;
    averagePercentageAccumulated24: number;
    averagePercentageAccumulated36: number;
    averagePercentageAccumulated48: number;
    averagePercentageAccumulated60: number;
    investmentList: [
      {
        investmentId: string;
        name: string;
        description: string;
        investmentTaf: string;
        investmentCnpj: string;
        percentageCurrentMonth: number;
        percentagePastMonth: number;
        percentageAccumulatedYear: number;
        percentageAccumulated12: number;
        percentageAccumulated24: number;
        percentageAccumulated36: number;
        percentageAccumulated48: number;
        percentageAccumulated60: number;
        riskGrade: string;
      }
    ];
  };
}

export class Fund {
  id: number;
  name: string;
  balance: number;
  balanceYield?: number;
  percentage: number;
  months12?: number;
  months36?: number;
  months60?: number;
  taf?: number;
  risk?: string;
}

export enum PlanData {
  Objective = 'objective'
}

export interface SummaryBalance {
  totalRelease: number;
  totalReleaseCompany: number;
  totalReleaseParticipant: number;
  supplementaryReleases: {
    totalRelease: number;
    totalReleaseParticipant: number;
    totalReleaseCompany: number;
  };
}

export interface IncomeTax {
  incomeTaxStatement: {
    cpf: string;
    year: string;
    link: string;
  };
}

export class Funds {
  'fundsStatement': {
    id: number;
    idInvestment: number;
    name: string;
    balance: number;
    balanceYield: number;
    percentage: number;
  };
}

export class Transactions {
  'transactionsStatement': {
    name: string;
    date: Date;
    total: number;
    totalParticipant: number;
    totalCompany: number;
    totalCharges: number;
    listInvestments: [
      {
        name: string;
        valueParticipant: number;
        valueCompany: number;
        amount: number;
      }
    ];
    listCharges: [
      {
        name: string;
        amount: number;
      }
    ];
  };
}

export interface ValidAndInvalidPlans<T> {
  valid: T[];
  invalid: T[];
}

export interface bodyUpdatePlan {
  numeroMatricula: string;
  nome: string;
  tipo: string;
  valor: number;
}

export class RegulatoryStatement {
  'regulatoryStatement': {
    status: string;
    url: string;
  };
}

export class IncomeReserveProjection {
  'incomeReserveProjectionStatement': {
    incomeValue: number;
    reserveValue: number;
  };
}
