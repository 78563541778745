import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorsService } from '@app/core/errors/errors-service/errors.service';
import { NotificationService } from '@app/core/notification/notification.service';

import { first } from 'rxjs/operators';

@Injectable()
export class ErrorsHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: Error | HttpErrorResponse) {
    const notificationService = this.injector.get(NotificationService);
    const errorsService = this.injector.get(ErrorsService);
    const router = this.injector.get(Router);

    if (!navigator.onLine) {
      notificationService.showError('Sem conexão com a internet');
    }

    errorsService
      .log(error)
      .pipe(first())
      .subscribe((errorWithContextInfo) => {
        router.navigate(['/error'], { queryParams: errorWithContextInfo });
      });
  }
}
