<mat-card>
  <mat-card-content>
    <ng-container>
      <p>Informações do plano</p>
      <app-info-list [info]="infoListArr"></app-info-list>
      <!-- <a
        class="pdf-download"
        mat-flat-button
        color="primary"
        href="{{ pdfUrl }}"
        target="_blank"
        [disabled]="!pdfUrl"
        *ngIf="showRegulamentarStatement"
      >
        Baixar extrato regulamentar
        <mat-icon>file_download</mat-icon>
      </a> -->
    </ng-container>
  </mat-card-content>
</mat-card>
