import { NgModule } from '@angular/core';

import { WindowService } from '@app/shared/service/window.service';
import { OpinAuthorizationService } from '../modules/consents/services/opin-authorization/opin-authorization.service';
import { OpinConsentService } from '../modules/consents/services/opin-consent/opin-consent.service';
import { TransmitterService } from '../modules/consents/services/transmitter/transmitter.service';
import { OpenInsuranceService } from '../open-insurance.service';

import atomModules from './components/atoms';
import moleculesModules from './components/molecules';
import organismsModules from './components/organisms';

const COMPONENTS = [atomModules, moleculesModules, organismsModules];

@NgModule({
  providers: [
    WindowService,
    TransmitterService,
    OpinConsentService,
    OpenInsuranceService,
    OpinAuthorizationService
  ],
  exports: [COMPONENTS],
  imports: [COMPONENTS]
})
export class OpenInsuranceSharedModule {}
