export const environment = {
  production: true,
  title: 'Portal Brasilprev - Área Segura',
  api: {
    bb: {
      url: '##__API_BASE_URL_BB__##',
      client_id: '##__CLIENT_ID_BB__##'
    },
    digitalSiteBff: '##__DIGITAL_SITE_BFF__##',
    brasilprev: {
      auth_url: '##__API_BASE_URL_AUTH__##',
      login_url: '##__API_BASE_URL_LOGIN__##',
      boleto_url: '##__API_BASE_URL_BOLETO__##',
      url: '##__API_BASE_URL__##',
      url_ecs: '##__API_BASE_URL_ECS__##',

      income_tax_report: '##__INCOME_TAX_REPORT__##'
    },

    graphql: {
      default: '##__GRAPHQL_DEFAULT_URL__##',
      statement: '##__GRAPHQL_STATEMENT_URL__##',
      withdraw: '##__GRAPHQL_WITHDRAW_URL__##'
    }
  },
  opin: {
    opin_authentication_url: '##__OPIN_AUTHENTICATION_URL__##',
    opin_auth: '##__OPIN_AUTH__##',
    api2_brasilprev: '##__API2_BRASILPREV__##',
    api_opin: '##__API_OPIN__##',
    callbackApplicationUri: '##__CALLBACK_APPLICATION_URI__##',
    opin_terms_of_use_and_privacy: '##__OPIN_TERMS_OF_USE_AND_PRIVACY__##'
  },
  decumulation: {
    chat: {
      live_agent_script_url: '##__DECUMULATION_CHAT_LIVE_AGENT_SCRIPT_URL__##',
      button_id: '##__DECUMULATION_CHAT_BUTTON_ID__##',
      implementation_id: '##__DECUMULATION_CHAT_IMPLEMENTATION_ID__##',
      org_id: '##__DECUMULATION_CHAT_ORG_ID__##',
      live_agent_chat_url: '##__DECUMULATION_CHAT_LIVE_AGENT_CHAT_URL__##',
      web_to_case_url: '##__DECUMULATION_CHAT_WEB_TO_CASE_URL__##'
    }
  },
  timeLimits: {
    default: {
      start: [22, 0, 0],
      end: [10, 0, 0]
    }
  },
  gtm_id: '##__GTM_ID__##',
  sentry: {
    dsn: '##__SENTRY_DSN__##'
  },
  features: {
    whatsapp_mfa_button: '##__FEATURE_TOGGLE_WHATSAPP__##'
  },
  recaptcha: {
    siteKey: '##__RECAPTCHA_SITE_KEY__##'
  }
};
