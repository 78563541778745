import { Directive, Input } from '@angular/core';
import { Routes } from '@angular/router';
import { PlanAboutComponent } from '../plan-about.component';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[routerLink]',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { '(click)': 'onClick()' }
})
export class RouterLinkStubDirective {
  @Input('routerLink') linkParams: any;
  navigatedTo: any = null;

  onClick() {
    this.navigatedTo = this.linkParams;
  }
}

export const MockRoutes: Routes = [
  {
    path: 'diversification/:registration/profitability-comparison',
    component: PlanAboutComponent
  }
];

export const MockRegistration = 'mock-registration';

export const MockActivatedRoute = {
  snapshot: { params: { registration: MockRegistration } }
};
