export enum DEFAULT_PERMISSION {
  'RESOURCES_READ' = 'RESOURCES_READ'
}

export enum PERSONAL_DATA_PERMISSION {
  'CUSTOMERS_PERSONAL_IDENTIFICATIONS_READ' = 'CUSTOMERS_PERSONAL_IDENTIFICATIONS_READ',
  'CUSTOMERS_PERSONAL_QUALIFICATION_READ' = 'CUSTOMERS_PERSONAL_QUALIFICATION_READ',
  'CUSTOMERS_PERSONAL_ADDITIONALINFO_READ' = 'CUSTOMERS_PERSONAL_ADDITIONALINFO_READ'
}

export enum LIFE_PENSION_PLAN_PERMISSION {
  'LIFE_PENSION_READ' = 'LIFE_PENSION_READ',
  'LIFE_PENSION_CLAIM' = 'LIFE_PENSION_CLAIM',
  'LIFE_PENSION_MOVEMENTS_READ' = 'LIFE_PENSION_MOVEMENTS_READ',
  'LIFE_PENSION_CONTRACTINFO_READ' = 'LIFE_PENSION_CONTRACTINFO_READ',
  'LIFE_PENSION_PORTABILITIES_READ' = 'LIFE_PENSION_PORTABILITIES_READ',
  'LIFE_PENSION_WITHDRAWALS_READ' = 'LIFE_PENSION_WITHDRAWALS_READ'
}

export enum PENSION_PLAN_PERMISSION {
  'PENSION_PLAN_READ' = 'PENSION_PLAN_READ',
  'PENSION_PLAN_CLAIM' = 'PENSION_PLAN_CLAIM',
  'PENSION_PLAN_MOVEMENTS_READ' = 'PENSION_PLAN_MOVEMENTS_READ',
  'PENSION_PLAN_CONTRACTINFO_READ' = 'PENSION_PLAN_CONTRACTINFO_READ',
  'PENSION_PLAN_PORTABILITIES_READ' = 'PENSION_PLAN_PORTABILITIES_READ',
  'PENSION_PLAN_WITHDRAWALS_READ' = 'PENSION_PLAN_WITHDRAWALS_READ'
}

export enum PERSON_PERMISSION {
  'DAMAGES_AND_PEOPLE_PERSON_READ' = 'DAMAGES_AND_PEOPLE_PERSON_READ',
  'DAMAGES_AND_PEOPLE_PERSON_CLAIM_READ' = 'DAMAGES_AND_PEOPLE_PERSON_CLAIM_READ',
  'DAMAGES_AND_PEOPLE_PERSON_PREMIUM_READ' = 'DAMAGES_AND_PEOPLE_PERSON_PREMIUM_READ',
  'DAMAGES_AND_PEOPLE_PERSON_POLICYINFO_READ' = 'DAMAGES_AND_PEOPLE_PERSON_POLICYINFO_READ'
}

export const CONSENT_PERMISSION = {
  ...PERSON_PERMISSION,
  ...DEFAULT_PERMISSION,
  ...PERSONAL_DATA_PERMISSION,
  ...PENSION_PLAN_PERMISSION,
  ...LIFE_PENSION_PLAN_PERMISSION
} as const;

export const PERMISSION_LABEL = {
  [CONSENT_PERMISSION.CUSTOMERS_PERSONAL_IDENTIFICATIONS_READ]: 'Identificação',
  [CONSENT_PERMISSION.CUSTOMERS_PERSONAL_QUALIFICATION_READ]: 'Qualificação',
  [CONSENT_PERMISSION.CUSTOMERS_PERSONAL_ADDITIONALINFO_READ]:
    'Informações complementares',
  [CONSENT_PERMISSION.DAMAGES_AND_PEOPLE_PERSON_CLAIM_READ]: 'Sinistro',
  [CONSENT_PERMISSION.DAMAGES_AND_PEOPLE_PERSON_POLICYINFO_READ]:
    'Informações da Apólice',
  [CONSENT_PERMISSION.DAMAGES_AND_PEOPLE_PERSON_PREMIUM_READ]: 'Prêmio',
  [CONSENT_PERMISSION.DAMAGES_AND_PEOPLE_PERSON_READ]: 'Seguros pessoais',
  [CONSENT_PERMISSION.LIFE_PENSION_CLAIM]: 'Concessão',
  [CONSENT_PERMISSION.LIFE_PENSION_CONTRACTINFO_READ]:
    'Informações contratuais',
  [CONSENT_PERMISSION.LIFE_PENSION_MOVEMENTS_READ]: 'Movimentações',
  [CONSENT_PERMISSION.LIFE_PENSION_PORTABILITIES_READ]: 'Portabilidades',
  [CONSENT_PERMISSION.LIFE_PENSION_READ]: 'Produtos de Sobrevivência',
  [CONSENT_PERMISSION.LIFE_PENSION_WITHDRAWALS_READ]: 'Resgates',
  [CONSENT_PERMISSION.PENSION_PLAN_CLAIM]: 'Sinistro',
  [CONSENT_PERMISSION.PENSION_PLAN_CONTRACTINFO_READ]:
    'Informações contratuais',
  [CONSENT_PERMISSION.PENSION_PLAN_MOVEMENTS_READ]: 'Movimentações',
  [CONSENT_PERMISSION.PENSION_PLAN_PORTABILITIES_READ]: 'Portabilidades',
  [CONSENT_PERMISSION.PENSION_PLAN_READ]: 'Produtos de Risco',
  [CONSENT_PERMISSION.PENSION_PLAN_WITHDRAWALS_READ]: 'Resgates'
} as const;

export const PERMISSION_DETAILS = {
  [CONSENT_PERMISSION.DAMAGES_AND_PEOPLE_PERSON_CLAIM_READ]: [
    'Identificador do processo de sinistro',
    'Status do sinistro',
    'Data de alteração do sinistro',
    'Data de ocorrência do sinistro',
    'Data de aviso do sinistro',
    'Detalhes de valores/limites'
  ],
  [CONSENT_PERMISSION.DAMAGES_AND_PEOPLE_PERSON_POLICYINFO_READ]: [
    'Tipo de documento emitido',
    'Número da matrícula',
    'Tipo de emissão',
    'Data de emissão do documento',
    'Data de início de vigência do documento',
    'Data de fim de vigência do documento',
    'Ocorrência de resgate',
    'Ocorrência de portabilidade',
    'Número da Proposta',
    'CPF do segurado',
    'Nome do segurado',
    'CEP',
    'Cidade',
    'Estado',
    'País',
    'Endereço',
    'Tipo de objeto segurado',
    'Descrição do objeto segurado',
    'Valor da Provisão Matemática de Benefícios a Conceder'
  ],
  [CONSENT_PERMISSION.DAMAGES_AND_PEOPLE_PERSON_PREMIUM_READ]: [
    'Quantidade de parcelas do prêmio do contrato',
    'Grupo e ramo da cobertura',
    'Código da cobertura',
    'Detalhes de valores/limites',
    'Data do movimento de prêmio',
    'Tipo do movimento',
    'Valor do movimento',
    'Data de vencimento da parcela'
  ],
  [CONSENT_PERMISSION.DAMAGES_AND_PEOPLE_PERSON_READ]: [
    'Nome do Produto',
    'Número da matrícula'
  ],
  [CONSENT_PERMISSION.LIFE_PENSION_CLAIM]: [
    'Status do Evento Gerador',
    'Data de aviso do Evento gerador',
    'Data do aviso à seguradora',
    'CPF do beneficiário',
    'Nome do beneficiário',
    'Classificação do beneficiário',
    'Data de nascimento do beneficiário',
    'Tipo de renda',
    'Valor de renda',
    'Número de benefícios a serem recebidos por ano',
    'Data de concessão',
    'Índice de atualização monetária',
    'Data da última atualização do benefício'
  ],
  [CONSENT_PERMISSION.LIFE_PENSION_CONTRACTINFO_READ]: [
    'Número da matrícula',
    'Tipo de contratação',
    'Data de Início da Vigência',
    'Data de Fim da Vigência',
    'Número da proposta',
    'CPF do segurado',
    'Nome do segurado',
    'Data de nascimento do segurado',
    'Gênero do segurado',
    'CEP',
    'Cidade',
    'Estado',
    'País',
    'Endereço',
    'Código da Cobertura',
    'Número do Processo Susep',
    'Modalidade de estruturação',
    'Valor do benefício da cobertura contratada',
    'Periodicidade de pagamento',
    'Nome da Cobertura',
    'Plano bloqueado/ não bloqueado',
    'Regime financeiro',
    'Forma de pagamento do Benefício',
    'Forma de tarifação',
    'Índice de Atualização da Cobertura',
    'Defasagem do índice de preços aplicado na atualização da renda'
  ],
  [CONSENT_PERMISSION.LIFE_PENSION_MOVEMENTS_READ]: [
    'Valores de contribuições/prêmios pagos',
    'Datas de Pagamentos das contribuições/prêmios',
    'Datas de Vencimentos das contribuições/prêmios',
    'Periodicidade da contribuições'
  ],
  [CONSENT_PERMISSION.LIFE_PENSION_PORTABILITIES_READ]: [
    'Ocorrência de portabilidade',
    'Direção da portabilidade (Entrada/Saída)',
    'Tipo de portabilidade (Total/Parcial)',
    'Valor da portabilidade',
    'Data da solicitação da portabilidade',
    'Data da efetivação da portabilidade'
  ],
  [CONSENT_PERMISSION.LIFE_PENSION_READ]: [
    'Nome do Produto',
    'Número da matrícula'
  ],
  [CONSENT_PERMISSION.LIFE_PENSION_WITHDRAWALS_READ]: [
    'Ocorrência de resgate',
    'Tipo de resgate (Total/Parcial)',
    'Valor da resgate',
    'Data da solicitação do resgate',
    'Data da efetivação do resgate',
    'Natureza do resgate'
  ],
  [CONSENT_PERMISSION.PENSION_PLAN_CLAIM]: [
    'Status do Evento Gerador',
    'Data de aviso do Evento gerador',
    'Data do aviso à seguradora',
    'CPF do beneficiário',
    'Nome do beneficiário',
    'Classificação do beneficiário',
    'Data de nascimento do beneficiário',
    'Tipo de renda',
    'Valor de renda',
    'Número de benefícios a serem recebidos por ano',
    'Data de concessão',
    'Índice de atualização monetária',
    'Data da última atualização do benefício'
  ],
  [CONSENT_PERMISSION.PENSION_PLAN_CONTRACTINFO_READ]: [
    'Número da matrícula',
    'Tipo de contratação',
    'Data de Início da Vigência',
    'Data de Fim da Vigência',
    'Número da proposta',
    'CPF do segurado',
    'Nome do segurado',
    'Data de nascimento do segurado',
    'Gênero do segurado',
    'CEP',
    'Cidade',
    'Estado',
    'País',
    'Endereço',
    'Código da Cobertura',
    'Número do Processo Susep',
    'Modalidade de estruturação',
    'Valor do benefício da cobertura contratada',
    'Periodicidade de pagamento',
    'Nome da Cobertura',
    'Plano bloqueado/ não bloqueado',
    'Regime financeiro',
    'Forma de pagamento do Benefício',
    'Forma de tarifação',
    'Índice de Atualização da Cobertura',
    'Defasagem do índice de preços aplicado na atualização da renda'
  ],
  [CONSENT_PERMISSION.PENSION_PLAN_MOVEMENTS_READ]: [
    'Valores de contribuições/prêmios pagos',
    'Datas de Pagamentos das contribuições/prêmios',
    'Datas de Vencimentos das contribuições/prêmios',
    'Periodicidade da contribuições'
  ],
  [CONSENT_PERMISSION.PENSION_PLAN_PORTABILITIES_READ]: [
    'Ocorrência de portabilidade',
    'Direção da portabilidade (Entrada/Saída)',
    'Tipo de portabilidade (Total/Parcial)',
    'Valor da portabilidade',
    'Data da solicitação da portabilidade',
    'Data da efetivação da portabilidade'
  ],
  [CONSENT_PERMISSION.PENSION_PLAN_READ]: [
    'Nome do Produto',
    'Número da matrícula'
  ],
  [CONSENT_PERMISSION.PENSION_PLAN_WITHDRAWALS_READ]: [
    'Ocorrência de resgate',
    'Tipo de resgate (Total/Parcial)',
    'Valor da resgate',
    'Data da solicitação do resgate',
    'Data da efetivação do resgate',
    'Natureza do resgate'
  ]
};

export const ERROR_COMMAND_MESSAGE = {
  CPF_MISMATCH:
    'O CPF informado na instituição solicitante é diferente do informado.',
  EXPIRED_CONSENT: 'O tempo para confirmar o consentimento expirou.',
  DEFAULT:
    'houve um erro durante o compartilhamento, e por motivos de segurança, estamos cancelando esta ação'
} as const;
