import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, finalize, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UrlHistoryService {
  private isPreviousUrlSameOrigin = false;

  constructor(private router: Router, private location: Location) {
    (this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      take(2),
      finalize(() => this.setIsPreviousUrlSameOrigin(true))
    ) as Observable<NavigationEnd>).subscribe(_ => {});
  }

  setIsPreviousUrlSameOrigin(b: boolean): void {
    this.isPreviousUrlSameOrigin = b;
  }

  back = (): void | Promise<boolean> =>
    this.isPreviousUrlSameOrigin
      ? this.location.back()
      : this.router.navigate(['/home']);
}
