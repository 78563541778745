<div class="container">
  <img
    class="logo"
    src="assets/svg/brasilprev-yellowbg.svg"
    alt="logo BrasilPrev"
  />
  <app-contacts></app-contacts>
  <div class="social-container" [class.hide-app]="isApp">
    <div class="downloads">
      <a
        href="https://apps.apple.com/br/app/brasilprev/id1545414496"
        target="_blank"
        ><img src="assets/svg/social/app-store.svg" alt=""
      /></a>
      <a
        href="https://play.google.com/store/apps/details?id=br.com.brasilprev.app.cliente"
        target="_blank"
        ><img src="assets/svg/social/play-store.svg" alt=""
      /></a>
    </div>
    <div class="networks">
      <a href="https://www.linkedin.com/company/brasilprev/" target="_blank"
        ><img src="assets/svg/social/linkedin.svg" alt=""
      /></a>
      <a href="https://www.facebook.com/brasilprev/" target="_blank"
        ><img src="assets/svg/social/facebook.svg" alt=""
      /></a>
      <a href="https://www.youtube.com/user/brasilprev" target="_blank"
        ><img src="assets/svg/social/youtube.svg" alt=""
      /></a>
      <a href="https://www.instagram.com/brasilprev/" target="_blank"
        ><img src="assets/svg/social/instagram.svg" alt=""
      /></a>
      <a href="https://www.tiktok.com/@brasilprev" target="_blank"
        ><img src="assets/svg/social/telegram.svg" alt=""
      /></a>
    </div>
    <div class="address">
      <strong>Brasilprev Seguros e Previdência S.A</strong>
      <p>CNPJ: 27.665.207/0001-31</p>
      <p>Rua Alexandre Dumas, 1671 - Chácara Santo Antônio</p>
      <p>São Paulo - SP, 04717-004</p>
    </div>
  </div>
</div>
