import { Component, Inject } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['../dialogs.component.scss']
})
export class SendEmailComponent {
  form: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public email: string,
    private fb: UntypedFormBuilder,
    public ref: MatDialogRef<SendEmailComponent>
  ) {
    this.form = this.fb.group({
      email: [email, [Validators.required, Validators.email]]
    });
  }

  public onSubmit() {
    this.ref.close(this.form.get('email').value);
  }
}
