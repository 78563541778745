import { Component, Input, OnInit } from '@angular/core';
import { NavigationItem } from '../header.model';
import { HeaderService } from '../header.service';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss']
})
export class ProfileMenuComponent implements OnInit {
  @Input() isProfileOpen: boolean;
  @Input() nameInitials: string;
  @Input() navigationList: NavigationItem[];

  constructor(private headerService: HeaderService) {}

  ngOnInit(): void {}

  toggleProfileVisibility(): void {
    this.headerService.toggleProfileVisibility(this.isProfileOpen);
  }

  handleNavigationItemAction(item: NavigationItem) {
    this.headerService.handleNavigationItemAction(item);
  }
}
