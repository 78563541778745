import { Injectable } from '@angular/core';

import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';

@Injectable()
export class PlanTradUnsignedGuard implements CanActivate {
  constructor(private authService: AuthService) {}

  canActivate(): Observable<boolean> {
    return this.authService.isPlanTradUnsigned.pipe(
      take(1),
      map((isBlocked: boolean) => {
        if (isBlocked) {
          this.authService.redirectToTradValidation();
        }
        return !isBlocked;
      })
    );
  }
}
