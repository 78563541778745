import { AfterViewInit, Component } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements AfterViewInit {
  isApp = false;
  constructor(private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.isApp = sessionStorage.getItem('platform') === 'APP';
      }
    });
  }

  ngAfterViewInit(): void {
    this.isApp = sessionStorage.getItem('platform') === 'APP';
  }
}
