export enum Steps {
  PASSWORD,
  ASK_CODE,
  CODE_VERIFICATION,
  WITHOUT_PASSWORD,
  PASSWORD_BLOCKED,
  NEW_PASSWORD,
  SUCCESS
}

export enum PasswordStatus {
  OK,
  BLOCKED,
  HAS_NOT,
  FORGOT
}

export class HasTokens {
  twoFactorCode: string;
  password: string;
}

export const enterKeyCode = 13;
