export class Field {
  label: string;
  value: any;
}

export class EnvelopeRequest {
  templateId: string;
  email: string;
  name: string;
  returnUrl: string;
  roleName: string;
  clientUserId: string;
  expireAfter: number;
  journey: number;
  idSolicitacao: string;

  customFields: Field[];
  fields: Field[];
}

export enum Events {
  SUCCESS = 'signing_complete',
  DECLINE = 'decline'
}
