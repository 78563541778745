import { OpinButtonModule } from './button/button.module';
import { OpinDividerComponentModule } from './divider/divider.module';
import { OpinIconModule } from './icon/icon.module';
import { OpinImageComponentModule } from './image/image.module';
import { OpinLabelModule } from './label/label.module';
import { OpinTextRedirectModule } from './text-redirect/text-redirect.module';
import { OpinTextWithBoldLettersModule } from './text-with-bold-letters/text-with-bold-letters.module';
import { OpinTextComponentModule } from './text/text.module';
import { OpinToggleSliderComponentModule } from './toggle-slider/toggle-slider.module';

const COMPONENTS = [
  OpinIconModule,
  OpinLabelModule,
  OpinButtonModule,
  OpinTextRedirectModule,
  OpinTextComponentModule,
  OpinDividerComponentModule,
  OpinTextWithBoldLettersModule,
  OpinToggleSliderComponentModule,
  OpinImageComponentModule
];

export default COMPONENTS;
