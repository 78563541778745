import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared';
import { ConsolidatedTableComponent } from './consolidated-table/consolidated-table.component';
import { EditComponent } from './edit/edit.component';
import { FilesPageComponent } from './files-page/files-page.component';
import { FundItemComponent } from './fund-item/fund-item.component';
import { FilterFundsModalComponent } from './funds-page/filter-funds-modal/filter-funds-modal.component';
import { FundsPageComponent } from './funds-page/funds-page.component';
import { HeaderTileDetaisComponent } from './header-tile-details/header-tile-details.component';
import { HeaderTileComponent } from './header-tile/header-tile.component';
import { IncomeTaxModalComponent } from './income-tax-modal/income-tax-modal.component';
import { InfoTileComponent } from './info-tile/info-tile.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { OpenPaymentsModalComponent } from './open-payments-modal/open-payments-modal.component';
import { PlanDataPageComponent } from './plan-data-page/plan-data-page.component';
import { PlanRoutingModule } from './plan-routing.module';
import { PlanTrackingStatementComponent } from './plan-tracking-statement/plan-tracking-statement.component';
import { PlanComponent } from './plan.component';
import { ProcessingDateComponent } from './processing-date/processing-date.component';
import { RiskPageComponent } from './risk-page/risk-page.component';
import { RiskStepperComponent } from './risk-tile/risk-stepper/risk-stepper.component';
import { RiskTileComponent } from './risk-tile/risk-tile.component';
import { SimulationComponent } from './simulation/simulation.component';
import { StatementItemComponent } from './statement-item/statement-item.component';
import { StatementSummaryPageComponent } from './statement-summary-page/statement-summary-page.component';
import { TransferItemComponent } from './transfer-item/transfer-item.component';
import { TransfersComponent } from './transfers/transfers.component';

@NgModule({
  declarations: [
    PlanComponent,
    EditComponent,
    OnboardingComponent,
    SimulationComponent,
    StatementItemComponent,
    TransfersComponent,
    TransferItemComponent,
    HeaderTileComponent,
    HeaderTileDetaisComponent,
    InfoTileComponent,
    RiskTileComponent,
    RiskStepperComponent,
    IncomeTaxModalComponent,
    OpenPaymentsModalComponent,
    ConsolidatedTableComponent,
    FundItemComponent,
    StatementSummaryPageComponent,
    FundsPageComponent,
    FilesPageComponent,
    RiskPageComponent,
    PlanDataPageComponent,
    FilterFundsModalComponent,
    ProcessingDateComponent,
    PlanTrackingStatementComponent
  ],
  imports: [CommonModule, PlanRoutingModule, SharedModule]
})
export class PlanModule {}
