import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Callback, MenuState } from './header.model';
import { HeaderService } from './header.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() nameInitials: string;
  menuState$: Observable<MenuState>;
  callback = Callback;

  constructor(private headerService: HeaderService) {
    this.menuState$ = this.headerService.getMenuState;
  }

  public toggleMenuVisibility(state: boolean): void {
    this.headerService.toggleMenuVisibility(state);
  }

  public navigateTo(url: string): void {
    this.headerService.navigateTo(url);
  }
}
