import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { NavigationError, Router } from '@angular/router';
import { Observable, Subject, of } from 'rxjs';
import { filter, pluck, switchMap, takeUntil } from 'rxjs/operators';
import { ErrorWithContext } from '../errors.model';

@Injectable()
export class ErrorsService implements OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private router: Router, private location: LocationStrategy) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationError),
        pluck('error'),
        switchMap(this.log),
        takeUntil(this.destroy$)
      )
      .subscribe((errorWithContext) => {
        this.router.navigate(['/error'], {
          queryParams: errorWithContext
        });
      });
  }

  log(error: any): Observable<ErrorWithContext> {
    console.error('Error:', error);
    const errorToSend = this.addContextInfo(error);
    return of(errorToSend);
  }

  addContextInfo(error: any): ErrorWithContext {
    const name = error.name || null;
    const appId = 'Brasilprev Portal';
    const user = sessionStorage.getItem('username');
    const time = new Date().getTime();
    const id = `${appId}-${user}-${time}`;
    const url =
      this.location instanceof PathLocationStrategy ? this.location.path() : '';
    const status = error.status || null;
    const message = error.message || JSON.stringify(error);

    return {
      name,
      appId,
      user,
      time,
      id,
      url,
      status,
      message
    } as ErrorWithContext;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
