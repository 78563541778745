export const OPEN_INSURANCE_ROUTE = 'open-insurance';

export const SHARED_CONSENTS_ROUTE = 'compartilhamentos';
export const CONSENTS_TRANSMITTER_ROUTE = 'consentimento-transmissora';

export const getSentSharedConsentResumeRoute = (consentId: string) =>
  `${SHARED_CONSENTS_ROUTES.SENT}/${consentId}`;

export const getReceivedSharedConsentResumeRoute = (consentId: string) =>
  `${SHARED_CONSENTS_ROUTES.RECEIVED}/${consentId}`;

export const CONSENTS_TRANSMITTER_ROUTES = {
  HOME: CONSENTS_TRANSMITTER_ROUTE,
  SUMMARY: `${CONSENTS_TRANSMITTER_ROUTE}/resumo`,
  FINALIZATION: `${CONSENTS_TRANSMITTER_ROUTE}/redirecionamento`
} as const;

export const SHARED_CONSENTS_ROUTES = {
  SENT: `${SHARED_CONSENTS_ROUTE}-enviados`,
  SENT_RESUME: getSentSharedConsentResumeRoute,
  RECEIVED: `${SHARED_CONSENTS_ROUTE}-recebidos`,
  RECEIVED_RESUME: getReceivedSharedConsentResumeRoute
} as const;

export const OPEN_INSURANCE_ROUTES = {
  HOME: OPEN_INSURANCE_ROUTE,
  [SHARED_CONSENTS_ROUTE]: SHARED_CONSENTS_ROUTES,
  [CONSENTS_TRANSMITTER_ROUTE]: CONSENTS_TRANSMITTER_ROUTES,
  to(path: string) {
    return `${OPEN_INSURANCE_ROUTE}/${path}`;
  }
} as const;
