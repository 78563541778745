<div [ngClass]="{ open: isThisSubmenuOpen }">
  <button mat-flat-button (click)="toggleVisibility()">
    {{ title }}
    <mat-icon>{{
      isThisSubmenuOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
    }}</mat-icon>
  </button>
  <ul
    [ngClass]="{
      fourColums: fourColums
    }"
  >
    <li *ngFor="let feature of features">
      <button mat-flat-button (click)="handleNavigationItemAction(feature)">
        {{ featTitle[feature] }}
      </button>
    </li>
  </ul>
</div>
