<div>
  <p>Informe de rendimentos</p>
  <mat-spinner [diameter]="30" *ngIf="!finishedRequest"></mat-spinner>
  <a
    mat-flat-button
    color="primary"
    [href]="pdfLink"
    target="_blank"
    *ngIf="finishedRequest"
    >Baixar</a
  >
</div>
