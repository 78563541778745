import { Component, Input, OnDestroy } from '@angular/core';

import { FeatTitle, Feature } from '@app/plan-manager/plan-manager.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MenuState } from '../header.model';
import { HeaderService } from '../header.service';

@Component({
  selector: 'app-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss']
})
export class ExpansionPanelComponent implements OnDestroy {
  @Input() title: string;
  @Input() registration: string;
  @Input() features: Feature[];
  @Input() fourColums = false;
  featTitle = FeatTitle;
  menuState: MenuState;
  isThisSubmenuOpen: boolean;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private headerService: HeaderService) {
    this.headerService.getMenuState
      .pipe(takeUntil(this.destroy$))
      .subscribe((menuState) => {
        this.menuState = menuState;

        if (this.menuState.isASubMenuOpen === false) {
          this.isThisSubmenuOpen = false;
        }
      });
  }

  public handleNavigationItemAction(feature: Feature) {
    this.headerService.featAction(feature, this.registration);
  }

  public toggleVisibility(): void {
    if (!this.menuState.isASubMenuOpen) {
      this.isThisSubmenuOpen = true;
      this.headerService.setSubmenuAsOpened(this.menuState);
    } else if (this.menuState.isASubMenuOpen && !this.isThisSubmenuOpen) {
      this.headerService.closeSubmenus(this.menuState);
      this.isThisSubmenuOpen = true;
      this.headerService.setSubmenuAsOpened(this.menuState);
    } else {
      this.headerService.closeSubmenus(this.menuState);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.unsubscribe();
  }
}
