import navigationSvgIcons from './navigation';
import actionSvgIcons from './action';
import communicationSvgIcons from './communication';
import signalingSvgIcons from './signaling';
import socialSvgIcons from './social';
import imageSvgIcons from './image';
import alertSvgIcons from './alert';
import notificationSvgIcons from './notification';
import editorSvgIcons from './editor';
import contentSvgIcons from './content';
import deviceSvgIcons from './device';
import fileSvgIcons from './file';

interface ISvgIcon {
  name: string;
  path: string;
}

const designSystemSvgIcons: ISvgIcon[] = [
  {
    name: 'loading-spinner',
    path: '/assets/design-system/icons/loading_spinner.svg'
  },
  ...navigationSvgIcons,
  ...actionSvgIcons,
  ...communicationSvgIcons,
  ...signalingSvgIcons,
  ...socialSvgIcons,
  ...imageSvgIcons,
  ...alertSvgIcons,
  ...notificationSvgIcons,
  ...editorSvgIcons,
  ...contentSvgIcons,
  ...deviceSvgIcons,
  ...fileSvgIcons
];

export default designSystemSvgIcons;
