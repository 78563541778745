import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-accessibility-service-channel-link',
  templateUrl: './accessibility-service-channel-link.component.html',
  styleUrls: ['./accessibility-service-channel-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccessibilityServiceChannelLinkComponent {
  @Input() channel: 'sac' | 'ouvidoria';
  @Input() color: 'primary' | 'secondary' | 'light';

  get iconPath() {
    switch (this.color) {
      case 'primary':
        return '/assets/svg/accessibility-service/accessibility-service-icon-bg-white.svg';
      case 'secondary':
        return '/assets/svg/accessibility-service/accessibility-service-icon-bg-black.svg';
      case 'light':
        return '/assets/svg/accessibility-service/accessibility-service-icon-bg-blue.svg';
      default:
        break;
    }
  }

  get title() {
    switch (this.channel) {
      case 'sac':
        return 'SAC Libras';
      case 'ouvidoria':
        return 'Ouvidoria Libras';
      default:
        break;
    }
  }

  get redirectUrl() {
    switch (this.channel) {
      case 'sac':
        return 'https://urldefense.com/v3/__https://icom.app/qM2nG__;!!DTmnSHdUk6gl!PXBbx5SB90IzmGx8qhwLJc-R4tNQByqaxqgBIEt3dZckJjicBIOozmMNX0rMWuvqu1Xu0ynXaX-NzVm2SJWeX3kkp4RP_A56fp2HI2s$';
      case 'ouvidoria':
        return 'https://urldefense.com/v3/__https://icom.app/u69rS__;!!DTmnSHdUk6gl!PXBbx5SB90IzmGx8qhwLJc-R4tNQByqaxqgBIEt3dZckJjicBIOozmMNX0rMWuvqu1Xu0ynXaX-NzVm2SJWeX3kkp4RP_A56P8aFD9w$';
      default:
        break;
    }
  }
}
