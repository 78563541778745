import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { AuthService } from '@app/auth/auth.service';

@Directive({
  selector: '[appPrivacy]'
})
export class PrivacyDirective implements OnChanges {
  private isPrivacyOn: boolean;
  @Input() private appPrivacy: string;

  constructor(private el: ElementRef, private authService: AuthService) {
    this.authService.isPrivacyOn().subscribe((isPrivacyOn: boolean) => {
      this.isPrivacyOn = isPrivacyOn;
      this.setValue();
    });
  }

  setValue() {
    this.el.nativeElement.innerText = this.isPrivacyOn
      ? '******'
      : this.appPrivacy;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.appPrivacy) {
      this.setValue();
    }
  }
}
