import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from '../../core/notification/notification.service';
import { PlanService } from '../plan.service';

@Component({
  selector: 'app-income-tax-modal',
  templateUrl: './income-tax-modal.component.html',
  styleUrls: ['./income-tax-modal.component.scss']
})
export class IncomeTaxModalComponent implements OnInit {
  finishedRequest = false;
  pdfLink = 'https://www1.brasilprev.com.br/redirecionar?url=';
  year = new Date().getFullYear() - 1;

  constructor(
    private planService: PlanService,
    public notificationService: NotificationService,
    public dialogRef: MatDialogRef<IncomeTaxModalComponent>
  ) {}

  ngOnInit() {
    const income = this.planService.getIncomeTax(this.year.toString());
    income.subscribe(
      (res) => {
        this.pdfLink += res.link;
        this.finishedRequest = true;
      },
      (error) => {
        this.notificationService.showError(error.message);
        this.dialogRef.close();
      },
      () => (this.finishedRequest = true)
    );
  }
}
