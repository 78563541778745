import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared';
import { ErrorsComponent } from './errors-component/errors.component';
import { ErrorsHandler } from './errors-handler/errors-handler';
import { ErrorsService } from './errors-service/errors.service';
import { ServerErrorInterceptor } from './server-errors-interceptor/server-errors.interceptor';

@NgModule({
  imports: [CommonModule, HttpClientModule, RouterModule, SharedModule],
  declarations: [ErrorsComponent],
  providers: [
    ErrorsService,
    {
      provide: ErrorHandler,
      useClass: ErrorsHandler
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true
    }
  ],
  exports: [ErrorsComponent]
})
export class ErrorsModule {}
