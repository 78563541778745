<div>
  <canvas
    baseChart
    [datasets]="doughnutChartData"
    [type]="doughnutChartType"
    [labels]="doughnutChartLabels"
    [options]="doughnutChartOptions"
  >
  </canvas>
</div>
