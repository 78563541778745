import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Setup, SuccessAuth } from '@app/core/authentication';
import { first, switchMap } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { OAuthService } from '../oauth.service';

@Component({
  selector: 'app-redirect-bb',
  templateUrl: './redirect-bb.component.html',
  styleUrls: ['./redirect-bb.component.scss']
})
export class RedirectBbComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private oAuthService: OAuthService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.route.queryParams
      .pipe(
        switchMap((userRedirectCode: Setup) => {
          const setup = {
            code: userRedirectCode.code,
            source: this.authService.getPlatformSource()
          } as Setup;
          return this.oAuthService.setup(setup);
        }),
        first()
      )
      .subscribe((response: SuccessAuth) => {
        this.oAuthService.loginBB(response);
      });
  }
}
