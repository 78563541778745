export const TEST_FLAG = 'TEST';
export const OPIN_ALLOWED_CPF_FLAG = 'opin-allowed-cpf';

export const FeatureFlags = {
  [TEST_FLAG]: false,
  diversification: false
} as const;

export type FeatureFlagsType = Record<FeatureFlagKeys, boolean>;

export type FeatureFlagKeys = keyof typeof FeatureFlags;
