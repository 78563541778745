<div class="container">
  <app-plan-tracking-statement
    [registration]="registration"
    [initialDate]="initialDate"
  ></app-plan-tracking-statement>
  <app-transfers
    class="transfers"
    [registration]="registration"
    [initialDate]="initialDate"
  ></app-transfers>
</div>

<div class="container">
  <div class="projection-chart">
    <h3>Projeção de renda</h3>
    <mat-card class="projection">
      <div class="projection__line">
        <span>
          Saldo aproximado em {{ grantDate }}:
          <mat-icon
            color="primary"
            [inline]="true"
            matTooltipPosition="after"
            [matTooltip]="
              'Este é seu saldo projetado que você irá alcançar na data de saída do seu plano.'
            "
            aria-label="Mais informações"
          >
            help
          </mat-icon>
        </span>
        <strong [appPrivacy]="projectedReservation | currency"></strong>
      </div>
      <div class="projection__line">
        <span>
          Mensal vitalícia:
          <mat-icon
            color="primary"
            [inline]="true"
            matTooltipPosition="after"
            [matTooltip]="
              'Essa será a sua renda mensal que foi calculada com base no saldo projetado.'
            "
            aria-label="Mais informações"
          >
            help
          </mat-icon>
        </span>
        <strong [appPrivacy]="projectedIncome | currency"></strong>
      </div>
    </mat-card>
    <mat-card class="projection">
      <canvas
        #chart
        baseChart
        [datasets]="datasets"
        [type]="'line'"
        [labels]="labels"
        [options]="option"
      >
      </canvas>
      <p class="projection__subtitle">
        Essa é a data de saída que você escolheu no momento da contratação do
        plano.
      </p>
      <p>
        A rentabilidade é calculada com base em 3,5% de juros reais ao ano. Os
        cálculos e as hipóteses financeiras aqui apresentadas são meras
        estimativas, não se constituindo em garantia ou obrigação da Brasilprev
        Seguros e Previdência.
      </p>
    </mat-card>
  </div>
</div>
