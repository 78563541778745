import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  Detail,
  Distribution,
  Eligibility,
  InvestmentPlanDetail,
  InvestmentsPlan,
  InvestorProfile,
  Plan,
  PlanDetail,
  PortfolioCompare,
  Rentability,
  SimulationDestinationDefineRequest,
  SimulationDestinationDefineResponse,
  SimulationRequest,
  SimulationResponse,
  SolicitationRequest,
  SolicitationResponse,
  Summary
} from '@app/core/new-diversification';
import { NotificationService } from '@app/core/notification/notification.service';
import { SummaryPortfolio } from '@app/diversification/components/summary-comparison-list/summary-comparison-list.component';
import { DiversificationServiceContract } from '@app/diversification/services/contracts/diversification.service';
import { DIVERSIFICATION_SERVICE_MESSAGES } from '@diversification/constants/messages/diversification-service';
import {
  MockDetail,
  MockDistributionSuggestedFalse,
  MockEligibilityAllTrue,
  MockInvestmentPlan,
  MockInvestorProfile,
  MockPlanDetail,
  MockPlanInvestmentRentability,
  MockPortfolioCompare,
  MockSimulationDestinationDefineResponse,
  MockSimulationResponse,
  MockSolicitationResponse,
  MockSummary,
  MockValidAndInvalidPlans
} from '@diversification/constants/mocks/plans';
import { DIVERSIFICATION_ROUTES } from '@diversification/constants/routes';
import { ValidAndInvalidPlansArray } from '@diversification/utils/get-valid-and-invalid-plans';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MockUserData } from './auth';

@Injectable({
  providedIn: 'root'
})
export class MockDiversificationService
  implements DiversificationServiceContract
{
  constructor(
    private router: Router,
    private notificationService: NotificationService
  ) {
    this.useMockService = false;
  }
  private plans: ValidAndInvalidPlansArray;
  private selectedPlan: Plan;
  private investments: InvestmentPlanDetail[];
  private simulationInvestmentsDestiny: InvestmentPlanDetail[];
  private simulationInvestmentsOrigin: InvestmentPlanDetail[];
  private simulationId: SimulationResponse['simulationId'];
  private simulationRequestPayload: SimulationRequest;
  private investorProfile: InvestorProfile;
  private elegibleProductsAmount: number;
  private suggestion: Distribution;
  private selectedPlanDetail: PlanDetail;
  private summary: Summary;
  private elegibility: Eligibility;
  private solicitationId: SolicitationResponse['solicitationId'];

  public useMockService: boolean;

  handleUndefinedKey(_error: Error) {
    this.router.navigate(DIVERSIFICATION_ROUTES.PLANS);
    this.notificationService.showError(
      DIVERSIFICATION_SERVICE_MESSAGES.UNDEFINED_KEY
    );
  }

  getUsername(): string {
    return MockUserData;
  }

  getSummaryPortfolios(): SummaryPortfolio[] {
    const { currentPortfolio, simulatedPortfolio } = this.getSummary();
    const plan = this.getSelectedPlanDetails();

    const portfolios: SummaryPortfolio[] = [
      { plan, heading: 'Plano atual', investments: currentPortfolio },
      { plan, heading: 'Plano realocado', investments: simulatedPortfolio }
    ];

    return portfolios;
  }

  getSolicitationId(): number {
    return this.solicitationId;
  }

  setSolicitationId(id: number): void {
    this.solicitationId = id;
  }

  getSimulationRequestPayload(): SimulationRequest {
    return this.simulationRequestPayload;
  }

  setSimulationRequestPayload(payload: SimulationRequest): void {
    this.simulationRequestPayload = payload;
  }

  getElegibility(): Eligibility {
    return this.elegibility;
  }

  setElegibility(elegibility: Eligibility): void {
    this.elegibility = elegibility;
  }

  getSelectedPlanDetails(): PlanDetail {
    return this.selectedPlanDetail;
  }

  setSelectedPlanDetails(detail: PlanDetail): void {
    this.selectedPlanDetail = detail;
  }

  getSuggestion(): Distribution {
    return this.suggestion;
  }

  setSuggestion(suggestion: Distribution): void {
    this.suggestion = suggestion;
  }

  getInvestorProfile(): InvestorProfile {
    return this.investorProfile;
  }

  setInvestorProfile(profile: InvestorProfile): void {
    this.investorProfile = profile;
  }

  getSimulationId(): number {
    return this.simulationId;
  }

  setSimulationId(id: number): void {
    this.simulationId = id;
  }

  getPlans(): ValidAndInvalidPlansArray {
    return this.plans;
  }

  setPlans(plans: ValidAndInvalidPlansArray) {
    this.plans = plans;
  }

  setSelectedPlan(plan: Plan): void {
    this.selectedPlan = plan;
  }

  getSelectedPlan(): Plan {
    return this.selectedPlan;
  }

  setInvestments(investments: InvestmentPlanDetail[]): void {
    this.investments = investments;
  }

  getInvestments(): InvestmentPlanDetail[] {
    return this.investments;
  }

  setSimulationInvestmentsDestiny(simulation: InvestmentPlanDetail[]): void {
    this.simulationInvestmentsDestiny = simulation;
  }

  getSimulationInvestmentsDestiny(): InvestmentPlanDetail[] {
    return this.simulationInvestmentsDestiny;
  }

  setSimulationInvestmentsOrigin(simulation: InvestmentPlanDetail[]): void {
    this.simulationInvestmentsOrigin = simulation;
  }

  getSimulationInvestmentsOrigin(): InvestmentPlanDetail[] {
    return this.simulationInvestmentsOrigin;
  }

  setElegibleProductsAmount(amount: number): void {
    this.elegibleProductsAmount = amount;
  }

  getElegibleProductsAmount(): number {
    return this.elegibleProductsAmount;
  }

  setSummary(summary: Summary): void {
    this.summary = summary;
  }

  getSummary(): Summary {
    return this.summary;
  }

  updateSimulationInvestmentsOrigin(investment?: InvestmentPlanDetail): void {
    const newSimulationInvestments = this.getSimulationInvestmentsOrigin().map(
      (item) => {
        if (item.id === investment.id) return investment;

        return item;
      }
    );

    this.setSimulationInvestmentsOrigin(newSimulationInvestments);
  }

  updateSimulationInvestmentsDestiny(investment?: InvestmentPlanDetail): void {
    const newSimulationInvestments = this.getSimulationInvestmentsDestiny().map(
      (item) => {
        if (item.id === investment.id) return investment;
        return item;
      }
    );

    this.setSimulationInvestmentsDestiny(newSimulationInvestments);
  }

  getSolicitationDetails$(): Observable<Detail> {
    return of(MockDetail);
  }

  getSummaryRentability$(_simulationId: number): Observable<PortfolioCompare> {
    return of(MockPortfolioCompare);
  }

  makeSolicitation$(
    _payload: SolicitationRequest
  ): Observable<SolicitationResponse> {
    return of(MockSolicitationResponse);
  }

  getSummary$(_simulationId: number): Observable<Summary> {
    return of(MockSummary).pipe(tap((summary) => this.setSummary(summary)));
  }

  defineSimulationDistribution$(
    _payload: SimulationDestinationDefineRequest
  ): Observable<SimulationDestinationDefineResponse> {
    return of(MockSimulationDestinationDefineResponse);
  }

  getDistributionSuggestion$(_simulationId: number): Observable<Distribution> {
    return of(MockDistributionSuggestedFalse).pipe(
      tap((suggestion) => {
        this.setSuggestion(suggestion);
        this.setSimulationInvestmentsDestiny(suggestion.investments);
      })
    );
  }

  simulateDistribution$(): Observable<SimulationResponse> {
    return of(MockSimulationResponse).pipe(
      tap(({ simulationId }) => {
        this.simulationId = simulationId;
      })
    );
  }

  getAvailableProducts$(): Observable<Eligibility> {
    return of(MockEligibilityAllTrue);
  }

  getInvestorProfile$(): Observable<InvestorProfile> {
    return of(MockInvestorProfile).pipe(
      tap((profile) => this.setInvestorProfile(profile))
    );
  }

  getInvestmentRentability$(): Observable<Rentability> {
    return of(MockPlanInvestmentRentability);
  }

  getPlanInvestments$(): Observable<InvestmentsPlan> {
    return of(MockInvestmentPlan).pipe(
      tap((investments) => {
        this.setInvestments([...investments.allocated, ...investments.others]);
      })
    );
  }

  getPlanDetails$(): Observable<PlanDetail> {
    return of(MockPlanDetail).pipe(
      tap((detail) => this.setSelectedPlanDetails(detail))
    );
  }

  getValidAndInvalidPlans$(): Observable<ValidAndInvalidPlansArray> {
    return of(MockValidAndInvalidPlans).pipe(
      tap((plans) => {
        this.plans = plans;
      })
    );
  }
}
