import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { OPEN_INSURANCE_ROUTES } from '@app/open-insurance/shared/constants/routes';
import { Feature } from '@app/plan-manager/plan-manager.model';
import { PlanManagerService } from '@app/plan-manager/plan-manager.service';
import { IncomeTaxModalComponent } from '@app/plan/income-tax-modal/income-tax-modal.component';
import { BehaviorSubject } from 'rxjs';
import { MenuState, NavigationItem } from './header.model';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  constructor(
    private authService: AuthService,
    private planManagerService: PlanManagerService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  public menuState: BehaviorSubject<MenuState> = new BehaviorSubject<MenuState>(
    new MenuState()
  );

  public toggleMenuVisibility(currentState: boolean) {
    this.menuState.next(new MenuState(!currentState, false));
  }

  public toggleProfileVisibility(currentState: boolean) {
    this.menuState.next(new MenuState(false, !currentState));
  }

  public closeMenus(): void {
    this.menuState.next(new MenuState());
  }

  public closeSubmenus(menuState: MenuState): void {
    this.menuState.next(new MenuState(menuState.isMenuOpen, false, false));
  }

  public setSubmenuAsOpened(menuState: MenuState): void {
    this.menuState.next(new MenuState(menuState.isMenuOpen, false, true));
  }

  public featAction(feature: Feature, registration: string) {
    this.planManagerService.handleMenuSelection(feature, registration);
    this.closeMenus();
  }

  public handleNavigationItemAction(item: NavigationItem): void {
    if (item.url) {
      this.router.navigate([item.url]);
    } else {
      this.callAction[item.callback]();
    }
    this.closeMenus();
  }

  public navigateTo(url: string): void {
    this.router.navigate([url]);
    this.closeMenus();
  }

  get getMenuState() {
    return this.menuState.asObservable();
  }

  public callAction = {
    OPIN: () => this.goToOpenInsurance(),
    INCOME_TAX: () => this.getIncomeTax(),
    LOGOUT: () => this.onLogout()
  };

  public goToOpenInsurance(): void {
    this.router.navigateByUrl(OPEN_INSURANCE_ROUTES.HOME);
  }

  public getIncomeTax(): void {
    this.dialog.open(IncomeTaxModalComponent, {
      width: '18.75rem'
    });
  }

  public onLogout(): void {
    this.authService.logout();
  }
}
