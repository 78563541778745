<div>
  <h2>
    {{ title }}
    <mat-icon
      [inline]="true"
      matTooltipPosition="after"
      [matTooltip]="tooltip"
      aria-label="Mais informações"
    >
      help
    </mat-icon>
  </h2>
  <strong *ngIf="value; else unavailable" [appPrivacy]="value | currency">
  </strong>
  <ng-template #unavailable>
    <strong>Indisponível</strong>
  </ng-template>
</div>
