import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';

@Injectable()
export class ProfileUpdatedGuard  {
  constructor(private authService: AuthService) {}

  canActivate(): Observable<boolean> {
    return this.authService.profileUpdated.pipe(
      take(1),
      map((isUpdated: boolean) => {
        if (!isUpdated) {
          this.authService.redirectToOnBoarding();
        }
        return isUpdated;
      })
    );
  }
}
