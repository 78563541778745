import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { OpinIconModule } from '../../atoms/icon/icon.module';
import { OpinInputComponent } from './input.component';

@NgModule({
  declarations: [OpinInputComponent],
  exports: [OpinInputComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    OpinIconModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class OpinInputModule {}
