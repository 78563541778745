import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isValidCpf } from '@brazilian-utils/validators';
import { Injectable } from '@angular/core';

@Injectable()
export class CpfUtils {
  isValid(cpf: string): boolean {
    return isValidCpf(cpf);
  }

  validateField(): ValidatorFn {
    return (cpf: AbstractControl): ValidationErrors | null => {
      if (
        cpf.value !== undefined &&
        cpf.value.length > 0 &&
        !this.isValid(cpf.value.replace(/\D/g, ''))
      ) {
        return { invalidCpf: true };
      }
      return null;
    };
  }

  format(cpf: string): string {
    if (this.isFormated(cpf)) {
      const digits = cpf.replace(/\D/g, '');
      const cpfFormated = digits.replace(
        /(\d{3})(\d{3})(\d{3})(\d{2})/,
        '$1.$2.$3-$4'
      );
      return cpfFormated;
    }
    return cpf;
  }

  toDigits(cpf: string): string {
    return cpf.replace(/\D/g, '');
  }

  isFormated(cpf: string): boolean {
    const cpfRegex = /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/;
    const digits = cpf.replace(/\D/g, '');
    return !cpfRegex.test(cpf) && digits.length === 11;
  }
}
