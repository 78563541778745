export class PlanSummary {
  registration: string;
  modalityName: string;
  planType: string;
  name: string;
  balance: number;
  balanceYield: number;
  objective: string;
  objectiveAccumulation: number;
  initialDate: string;
  taf?: number;
  planTypeId?: string;
  processingDate?: string;
  juniorOwnership?: boolean;
  grantDate: string;
  referenceDate: string;
  productId: number;
  showCertificate: boolean;
  servicesToHide: string[];
  idStatusPlano: string;
  statusPlano: string;
  idStatusPlanoDetalhe: string;
  statusPlanoDetalhe: string;
  totalSaldoBloqueado: string;
  totalSaldoDisponivel: string;
  isChangeDepartureDateAvailable: boolean;
  isDecumulationAvaliable: boolean;
  isGrantDateExpired: boolean;
}

export enum StatusPlanoPrevGarantia {
  idStatusPlano = 6,
  statusPlano = 'BLOQUEADA',
  idStatusPlanoDetalhe = 'PREV COMO GARANTIA TOTAL',
  statusPlanoDetalhe = 62,
  bloqueioTotal = 'PREV COMO GARANTIA TOTAL',
  bloqueioParcial = 'PREV COMO GARANTIA PARCIAL'
}

export class Response {
  plan: PlanSummary[];
}

export class PlanResult {
  planData?: any;
  incomeData?: any;
}

export enum PlanModality {
  VGBL = 'VGBL',
  PGBL = 'PGBL',
  TRADITIONAL = 'PREVIDÊNCIA TRADICIONAL'
}

export class Credentials {
  authorization: string;
}

export class ConsolidatedResponse {
  consolidatedBalanceStatement: ConsolidatedBalance;
}

export class ConsolidatedBalance {
  entriesTotalValue: number;
  yieldValue: number;
  participantTotalValue: number;
}
