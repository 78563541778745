<div *ngIf="onboarding$ | async as plan; else loading">
  <mat-spinner class="plan-spinner"></mat-spinner>
</div>
<ng-template #loading>
  <mat-card>
    <mat-card-content>
      <p>Estamos configurando sua conta. Vai levar pouco tempo!</p>
      <mat-spinner mode="indeterminate"></mat-spinner>
    </mat-card-content>
  </mat-card>
</ng-template>
