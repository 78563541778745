import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';

@Injectable()
export class UnsignedContractGuard  {
  constructor(private authService: AuthService) {}

  canActivate(): Observable<boolean> {
    return this.authService.isPlanUnsigned.pipe(
      take(1),
      map((isUnsigned: boolean) => {
        if (isUnsigned) {
          this.authService.redirectToValidation();
        }
        return !isUnsigned;
      })
    );
  }
}
