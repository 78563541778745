import { NgModule } from '@angular/core';
import { OpinIconModule } from '../icon/icon.module';
import { OpinTextRedirectComponent } from './text-redirect.component';

@NgModule({
  declarations: [OpinTextRedirectComponent],
  exports: [OpinTextRedirectComponent],
  imports: [OpinIconModule]
})
export class OpinTextRedirectModule {}
