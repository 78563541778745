import { Component, Input } from '@angular/core';
import { UrlHistoryService } from '@app/url-history.service';
import { BackButtonSettings } from './sub-header.model';

@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss']
})
export class SubHeaderComponent {
  @Input() backButtonSettings?: BackButtonSettings;

  constructor(private urlHistoryService: UrlHistoryService) {}

  get backButtonTitle() {
    return this.backButtonSettings?.title || 'Voltar';
  }

  back() {
    this.urlHistoryService.back();
  }
}
