<div class="container">
  <ng-container *ngIf="!isLoading">
    <p>
      Você aceita receber o código pelo Whatsapp oficial da Brasilprev no
      celular cadastrado?
    </p>
    <div class="actions">
      <button
        mat-button
        type="button"
        mat-dialog-close
        color="primary"
        title=""
      >
        Não
      </button>
      <button
        mat-button
        type="button"
        color="primary"
        title="Sim"
        (click)="onAcceptance()"
      >
        Sim
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="isLoading">
    <mat-spinner></mat-spinner>
  </ng-container>
</div>
