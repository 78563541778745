import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { IncomeTaxModalComponent } from '@app/plan/income-tax-modal/income-tax-modal.component';
import { PlanData } from '@app/plan/plan.model';
import { PlanService } from '@app/plan/plan.service';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { PDFDialogComponent } from './pdf-dialog/pdf-dialog.component';
import { PlanModality, PlanSummary, Response } from './statement.model';

@Injectable({
  providedIn: 'root'
})
export class StatementService {
  private readonly loadingError$: Subject<any> = new Subject<any>();

  constructor(
    private router: Router,
    private planService: PlanService,
    private authService: AuthService,
    private apollo: Apollo,
    public dialog: MatDialog
  ) {}

  public getPlansGraphql(): Observable<PlanSummary[]> {
    return this.apollo
      .use('statement')
      .watchQuery<Response>({
        query: gql`
          {
            plan {
              registration
              modalityName
              name
              planType
              balanceYield
              balance
              objective
              objectiveAccumulation
              initialDate
              taf
              processingDate
              juniorOwnership
              grantDate
              referenceDate
              productId
              showCertificate
              servicesToHide
              birthdayDate
              isUnderAge
              idStatusPlano
              statusPlano
              idStatusPlanoDetalhe
              statusPlanoDetalhe
              totalSaldoBloqueado
              totalSaldoDisponivel
              isDecumulationAvaliable
              isChangeDepartureDateAvailable
              isGrantDateExpired
            }
          }
        `
      })
      .valueChanges.pipe(
        take(1),
        map((result) => {
          if (result.data.plan == null) {
            return [];
          }
          return result.data.plan;
        })
      );
  }

  public checkUserStatusGraphql(): Observable<any> {
    return this.apollo
      .use('statement')
      .watchQuery<any>({
        query: gql`
          {
            personData {
              status
              data {
                is_blocked
              }
            }
          }
        `
      })
      .valueChanges.pipe(
        take(1),
        map((result) => result?.data?.personData?.data)
      );
  }

  hasLoadingError(): Observable<any> {
    return this.loadingError$;
  }

  public mountStatementPlan(plan: PlanSummary, singlePlan: boolean) {
    this.planService.cleanPlanData();

    this.planService.setPlanData(PlanData.Name, plan.name);
    this.planService.setPlanData(PlanData.ModalityName, plan.modalityName);
    this.planService.setPlanData(PlanData.InitialDate, plan.initialDate);
    this.planService.setPlanData(PlanData.Balance, plan.balance.toString());
    this.planService.setPlanData(
      PlanData.BalanceYield,
      plan.balanceYield.toString()
    );
    this.planService.setPlanData(PlanData.ProcessingDate, plan.processingDate);
    this.planService.setPlanData(PlanData.Taf, plan.taf.toString());
    this.planService.setPlanData(PlanData.GrantDate, plan.grantDate);
    this.planService.setPlanData(PlanData.ReferenceDate, plan.referenceDate);
    this.planService.setPlanData(
      PlanData.StatusPlanoDetalhe,
      plan.statusPlanoDetalhe
    );
    this.planService.setPlanData(
      PlanData.StatusPlanoDetalhe,
      plan.statusPlanoDetalhe
    );
    this.planService.setPlanData(PlanData.StatusPlano, plan.statusPlano);
    this.planService.setPlanData(
      PlanData.IdStatusPlanoDetalhe,
      plan.idStatusPlanoDetalhe
    );
    this.planService.setPlanData(
      PlanData.isChangeDepartureDateAvailable,
      plan.isChangeDepartureDateAvailable.toString()
    );
    this.planService.setPlanData(
      PlanData.isDecumulationAvaliable,
      plan.isDecumulationAvaliable.toString()
    );
    this.planService.setPlanData(
      PlanData.isGrantDateExpired,
      plan.isGrantDateExpired.toString()
    );
    this.planService.setPlanData(PlanData.Registration, plan.registration);

    if (singlePlan) {
      this.planService.setPlanData(PlanData.SinglePlan, singlePlan.toString());
    }
  }

  public navigateToStatement(registration: string): void {
    if (
      this.planService.getPlanData(PlanData.ModalityName) ===
      PlanModality.TRADITIONAL
    ) {
      this.openPDFDialog(registration);
    } else {
      this.router.navigate(['plan', registration, 'statement-summary']);
    }
  }

  public getIncomeTax(): void {
    this.dialog.open(IncomeTaxModalComponent, {
      width: '18.75rem'
    });
  }

  private openPDFDialog(registration: string) {
    this.dialog.open(PDFDialogComponent, {
      data: {
        registration
      }
    });
  }

  public setPlanData(plan: PlanSummary[]) {
    sessionStorage.setItem('statement: Plan', JSON.stringify(plan));
  }
  public getPlanData(): PlanSummary[] {
    return JSON.parse(sessionStorage.getItem('statement: Plan'));
  }
  public deletePlanData() {
    sessionStorage.removeItem('statement: Plan');
  }
}
