import {
  DebitData,
  DefinePaymentMethodRequest,
  DetailPlan,
  Plan,
  SimulationResponse,
  SolicitationPaymentDetail,
  SolicitationRequestDebitData,
  SolicitationResponse
} from '@app/core/extra-contribution-bff';

export enum PaymentMethodRoute {
  BOLETO = 'confirm-password',
  DEBITO = 'debit-confirmation',
  PIX = 'debit-confirmation'
}

export enum ConfirmTextButton {
  DEBITO = 'Concluir',
  PIX = 'Gerar código'
}

export enum GuideText {
  MOBILE = 'Copie e cole o código no seu aplicativo de pagamento.',
  DESKTOP = 'Utilize a câmera do seu celular para ler o QR Code:'
}

export enum ScreenType {
  MOBILE = 'MOBILE',
  DESKTOP = 'DESKTOP'
}

export enum IneligiblePlans {
  TRADICIONAL = 'notEligible'
}

export class ConfirmExtraContributionResponse {
  confirmationResponse: SolicitationResponse;
  confirmationDetails: SolicitationPaymentDetail;
}

export class ExtraContributionData {
  list?: Plan[];
  planDetail?: DetailPlan;
  simulation?: ExtraContributionSimulation;
  paymentMethod?: DefinePaymentMethodRequest;
  solicitationRequest?: SolicitationRequestDebitData;
  debitData?: DebitData | null;
  confirmExtraContributionResponse: ConfirmExtraContributionResponse;
  base64Boleto?: string;
}

export class ExtraContributionSimulation extends SimulationResponse {
  newContribution?: number;
}
