import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PDFDialogService } from './pdf-dialog.service';

@Component({
  selector: 'app-pdf-dialog',
  templateUrl: './pdf-dialog.component.html',
  styleUrls: ['./pdf-dialog.component.scss']
})
export class PDFDialogComponent implements OnInit {
  isLoading = true;
  registration: string;
  pdfLink: string;

  constructor(
    private dialogService: PDFDialogService,
    public dialogRef: MatDialogRef<PDFDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.registration = data.registration;
  }

  ngOnInit(): void {
    this.getRegStatement();
  }

  public onNoClick(): void {
    this.dialogRef.close(false);
  }

  public getRegStatement() {
    this.dialogService
      .getRegulatoryStatementPDFLink(this.registration)
      .subscribe(
        (res) => {
          this.pdfLink = res.url;
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          console.log(error);
        }
      );
  }
}
