import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AskCodeComponent } from './auth-code/ask-code/ask-code.component';
import { CheckCodeComponent } from './auth-code/check-code/check-code.component';
import { AuthComponent } from './auth.component';
import { CPFComponent } from './cpf/cpf.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { CodeVerificationComponent } from './new-password/code-verification/code-verification.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { SelectContactComponent } from './new-password/select-contact/select-contact.component';
import { SendCpfComponent } from './new-password/send-cpf/send-cpf.component';
import { SetPasswordComponent } from './new-password/set-password/set-password.component';
import { PasswordComponent } from './password/password.component';
import { RedirectBbComponent } from './redirect-bb/redirect-bb.component';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: '',
        component: CPFComponent,
        data: {
          isOpin: false
        }
      },
      {
        path: 'opin',
        component: CPFComponent,
        data: {
          isOpin: true
        }
      },
      {
        path: 'error',
        component: ErrorPageComponent
      },
      {
        path: 'password/:cpf',
        component: PasswordComponent
      },
      {
        path: 'redirect',
        component: RedirectBbComponent
      },
      {
        path: 'ask-code',
        component: AskCodeComponent
      },
      {
        path: 'verify-code',
        component: CheckCodeComponent
      },
      {
        path: 'password-setup/:type',
        component: NewPasswordComponent,
        children: [
          {
            path: '',
            component: SendCpfComponent
          },
          {
            path: 'select-contact',
            component: SelectContactComponent
          },
          {
            path: 'code-verification',
            component: CodeVerificationComponent
          },
          {
            path: 'set-password',
            component: SetPasswordComponent
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {}
