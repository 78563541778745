<h2>Saiba mais sobre o Fundo Carteira Prudente</h2>

<p>
  Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum quam excepturi
  sint ullam, sit accusamus rem modi aspernatur quibusdam ratione eligendi
  debitis deserunt maxime veniam. Odio illo quasi quae dolor?
</p>

<button
  mat-raised-button
  color="primary"
  type="button"
  title="Voltar"
  [routerLink]="['/diversification', registration, 'profitability-comparison']"
>
  <span>Voltar</span>
</button>
