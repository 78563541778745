import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { UserData } from '@app/core/auth/user.model';
import { CpfUtils } from '@app/core/validators/cpf.validator';
import { OpenInsuranceService } from '@app/open-insurance/open-insurance.service';
import { ScreenUtils } from '@app/shared/utils/screenUtils';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { CPF } from '../auth.model';
import { AuthService } from '../auth.service';
import { OAuthService } from '../oauth.service';
import { RedirectModalComponent } from '../redirect-modal/redirect-modal.component';

@Component({
  templateUrl: './cpf.component.html',
  styleUrls: ['../auth-components.scss']
})
export class CPFComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  form: UntypedFormGroup;
  isLoading: boolean;
  modalWidth: string;
  cpfObj = {
    digitis: this.authService.getLoginData(),
    formated: this.cpfUtils.format(this.authService.getLoginData())
  } as CPF;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    private cpfUtils: CpfUtils,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private screenUtils: ScreenUtils,
    private oauthService: OAuthService,
    private openInsuranceService: OpenInsuranceService
  ) {}

  ngOnInit() {
    this.authService.setPrivacyState(false);
    this.defineModalSize();
    this.buildForm();
    this.checkAuthenticationFromOpenInsurance();
  }

  private checkAuthenticationFromOpenInsurance() {
    const code = this.route.snapshot.queryParamMap.get('codigo');
    const hasCode = !!code;

    if (!hasCode) return;

    this.authService.isAuthenticationByOpenInsurance = hasCode;
    this.openInsuranceService.setCommandID(code);
  }

  ngOnDestroy() {
    this.snackBar.dismiss();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private buildForm(): void {
    this.form = this.fb.group({
      cpf: [
        this.cpfObj.digitis,
        [Validators.required, this.cpfUtils.validateField()]
      ],
      storeCPF: [false, [Validators.required]]
    });

    this.form
      .get('cpf')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((cpf) => {
        if (this.cpfUtils.isFormated(cpf)) {
          const formatedCpf = this.cpfUtils.format(cpf);
          this.authService.setUserData(UserData.Cpf, cpf);
          this.form.get('cpf').setValue(formatedCpf);
        }
      });
  }

  public onCheckboxChange() {}

  public removeLoginData() {
    this.authService.deleteLoginData();
    this.cpfObj = new CPF();
    this.form.get('cpf').setValue('');
  }

  defineModalSize(): void {
    this.modalWidth = this.screenUtils.isMobile ? '90%' : '50%';
  }

  openRedirectDialog(): void {
    const dialogRef = this.dialog.open(RedirectModalComponent, {
      width: this.modalWidth
    });
    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe((response) => {
        if (response) {
          this.isLoading = true;
          window.open(this.oauthService.getBBAuthUrl(), '_self');
        }
      });
  }

  public onSubmit(): void {
    if (this.form.valid) {
      this.isLoading = true;
      const cpf = this.cpfUtils.toDigits(this.form.value.cpf);

      if (this.form.get('storeCPF').value) {
        this.authService.setLoginData(cpf);
      }

      this.router.navigate(['auth', 'password', cpf]);
    }
  }
}
