import { Injectable } from '@angular/core';

import { AuthService } from '@app/auth/auth.service';
import { TokenPrefix } from '@app/core/auth/auth.model';
import { UserData } from '@app/core/auth/user.model';
import { environment } from '@env/environment';
import axios from 'axios';
import {
  ConsentDTO,
  TransmissionSharingDTO
} from '../consents/services/opin-consent/opin-consent.model';
import { OpinConsentService } from '../consents/services/opin-consent/opin-consent.service';
import { ReceiverConsent } from './consent-management.model';

@Injectable({
  providedIn: 'root'
})
export class ConsentManagementService {
  private readonly api = axios.create({
    baseURL: `${environment.opin.api_opin}/opin-consent`
  });

  API_OPIN = environment.opin.api_opin;

  constructor(
    private readonly authService: AuthService,
    private readonly opinConsentService: OpinConsentService
  ) {}

  async getSentSharings(): Promise<TransmissionSharingDTO[]> {
    const cpf = this.authService.getUserData(UserData.Cpf);
    const tokenUsuario = this.authService.getToken(TokenPrefix.Bearer);

    const consents =
      await this.opinConsentService.fetchTransmissionConsentManage(
        cpf,
        tokenUsuario
      );

    return consents;
  }

  async getReceivedSharings(): Promise<ReceiverConsent[]> {
    const userToken = this.authService.getToken(TokenPrefix.Bearer);
    const consents = await this.fetchReceivedConsentManage(userToken);

    return consents;
  }

  async getSharingById(consent_id: string): Promise<ConsentDTO> {
    const userToken = this.authService.getToken(TokenPrefix.Bearer);

    const consent =
      await this.opinConsentService.getTransmissionManagementConsentById(
        consent_id,
        userToken
      );

    return consent;
  }

  async revokeTransmissionConsentByID(consentID: string): Promise<void> {
    const userToken = this.authService.getToken(TokenPrefix.Bearer);

    await this.opinConsentService.revokeTransmissionConsent(
      consentID,
      userToken
    );
  }

  async revokeReceptionConsentByID(consentID: string): Promise<void> {
    const userToken = this.authService.getToken(TokenPrefix.Bearer);

    await this.opinConsentService.revokeReceptionConsent(consentID, userToken);
  }

  async fetchReceivedConsentManage(token: string): Promise<ReceiverConsent[]> {
    const { data: consents } = await this.api.get<ReceiverConsent[]>(
      `/reception-management/consents-received`,
      {
        headers: {
          Authorization: token
        }
      }
    );

    return consents;
  }

  async getReceveidConsentById(
    token: string,
    consentId: string
  ): Promise<ReceiverConsent> {
    const { data: consent } = await this.api.get<ReceiverConsent>(
      `/reception-management/consents-received/${consentId}`,
      {
        headers: {
          Authorization: token
        }
      }
    );

    return consent;
  }
}
