import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthErrorMessage } from '@app/auth/auth.model';
import { AuthService } from '@app/auth/auth.service';
import { TokenPrefix } from '@app/core/auth/auth.model';
import {
  AuthenticationService,
  ErrorResponseError_code as ErrorCode,
  SuccessTransactionPwd,
  SuccessTransactionPwdExists,
  TransactionPassword as nswagTransactionPassword
} from '@app/core/authentication';
import { NotificationService } from '@app/core/notification/notification.service';
import { UrlHistoryService } from '@app/url-history.service';
import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { PasswordStatus, Steps } from './transaction-password.model';

@Injectable({
  providedIn: 'root'
})
export class TransactionPassword {
  passwordStatus: PasswordStatus;
  selectedIndex: Steps;
  password: string;
  twoFactorCode: string;
  hasAuthError = new Subject<boolean>();

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private authService: AuthService,
    private urlHistoryService: UrlHistoryService,
    private notificationService: NotificationService
  ) {}

  get creatingPassword() {
    return this.passwordStatus !== PasswordStatus.OK;
  }

  getPasswordStatus(): Observable<SuccessTransactionPwdExists> {
    return this.authenticationService
      .exists(this.authService.getToken(TokenPrefix.Bearer))
      .pipe(
        tap(status => {
          if (status.is_password_blocked) {
            this.passwordStatus = PasswordStatus.BLOCKED;
            this.selectedIndex = Steps.PASSWORD_BLOCKED;
          } else if (!status.has_transaction_pwd) {
            this.passwordStatus = PasswordStatus.HAS_NOT;
            this.selectedIndex = Steps.WITHOUT_PASSWORD;
          } else {
            this.passwordStatus = PasswordStatus.OK;
            this.selectedIndex = Steps.PASSWORD;
          }
        })
      );
  }

  newTransactionPassword(
    code: string,
    newPassword: string
  ): Observable<SuccessTransactionPwd> {
    return this.authenticationService
      .transactionPassword(
        { code, new_password: newPassword } as nswagTransactionPassword,
        this.authService.getToken(TokenPrefix.Bearer)
      )
      .pipe(
        take(1),
        catchError(err => {
          if (err.error_code === ErrorCode.FIELDS_ERROR) {
            this.notificationService.showWarning(
              AuthErrorMessage.NEW_PASSWORD_ERROR,
              'Ok',
              { duration: 0 }
            );
          } else if (err.error_code === ErrorCode.INVALID_CODE) {
            this.notificationService.showWarning(
              AuthErrorMessage.INVALID_CODE,
              'Ok',
              { duration: 0 }
            );
            this.hasAuthError.next(true);
            this.selectedIndex = Steps.ASK_CODE;
          } else if (err.error_code === ErrorCode.BLOCKED_CODE) {
            this.notificationService.showError(
              AuthErrorMessage.BLOCKED_CODE,
              'Ok',
              { duration: 0 }
            );
            this.router.navigate(['/home']);
          } else {
            this.notificationService.showError(AuthErrorMessage.GENERIC, 'Ok', {
              duration: 0
            });
          }
          return EMPTY;
        })
      );
  }

  cancel() {
    this.urlHistoryService.back();
  }

  reset(isBlocked: boolean = false) {
    this.hasAuthError.next(true);

    if (isBlocked) {
      this.passwordStatus = PasswordStatus.BLOCKED;
      this.selectedIndex = Steps.PASSWORD_BLOCKED;
    } else {
      this.selectedIndex = Steps.PASSWORD;
    }
  }
}
