import { Prefix } from '../models/prefix.model';
export class SessiontorageData {
  prefix: Prefix;

  constructor(prefix: Prefix) {
    this.prefix = prefix;
  }
  setData(key: string, value: string): void {
    sessionStorage.setItem(`${this.prefix}: ${key}`, value);
  }
  getData(key: string): string {
    return sessionStorage.getItem(`${this.prefix}: ${key}`);
  }
  deleteData(key: string): void {
    sessionStorage.removeItem(`${this.prefix}: ${key}`);
  }
}
