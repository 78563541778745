import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SetPasswordTitle } from '@app/auth/auth.model';
import { AuthService } from '@app/auth/auth.service';
import { AuthData } from '@app/core/auth/auth.model';

@Component({
  selector: 'app-select-contact',
  templateUrl: './select-contact.component.html',
  styleUrls: ['./select-contact.component.scss']
})
export class SelectContactComponent implements OnInit {
  setPasswordTitle = SetPasswordTitle;
  chosenChannel: string;

  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit() {
    this.back = this.back.bind(this);
    this.chosenChannel = this.authService.getAuthData(AuthData.ChosenChannel);
  }

  handleCodeSent(): void {
    this.router.navigate([
      `auth/password-setup/${this.chosenChannel}/code-verification`
    ]);
  }

  back(): void {
    this.router.navigate([`auth/password-setup/${this.chosenChannel}`]);
  }
}
