import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DetailPlan } from '@app/core/extra-contribution-bff';
import {
  PlanSummary,
  StatusPlanoPrevGarantia
} from '@app/home/statement.model';
import { StatementService } from '@app/home/statement.service';
import { ChartColor } from '@app/shared/components/charts/chart/chart.model';
import { format } from '@app/shared/date-fns';
import { ExtraContributionData } from '@app/upselling/extra-contribution/extra-contribution.model';
import { ExtraContributionService } from '@app/upselling/extra-contribution/extra-contribution.service';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { PlanData } from '../plan.model';
import { PlanService } from '../plan.service';

@Component({
  selector: 'app-funds-page',
  templateUrl: './funds-page.component.html',
  styleUrls: ['./funds-page.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      )
    ])
  ]
})
export class FundsPageComponent implements OnInit {
  registration: string;
  funds$: Observable<any>;
  legendColors = [];
  initialDate: string;
  dataSource;
  totalRelease: string;
  plan: PlanSummary;
  displayedColumns = [
    'name',
    'risk',
    'balance',
    'balanceYield',
    'details',
    'aporte',
    'expand'
  ];

  investmentProfitability;
  availableInvestmentProfitability;
  referenceDate;
  expandedElement;
  statusPlanoPrevGarantia = StatusPlanoPrevGarantia;
  hasParcialBloq: boolean;

  constructor(
    private router: Router,
    private planService: PlanService,
    private extraCService: ExtraContributionService,
    private statementService: StatementService
  ) {}

  ngOnInit(): void {
    this.registration = this.router.url.split('/')[2];

    this.initialDate = this.planService.getPlanData(PlanData.InitialDate);
    this.funds$ = this.planService.getFunds(
      this.registration,
      format({ date: this.initialDate, dateFormat: 'yyyy-MM-dd' }),
      format({ date: new Date(), dateFormat: 'yyyy-MM-dd' })
    );
    this.dataSource = this.funds$;

    this.planService
      .getSummaryBalance(
        this.registration,
        format({ date: this.initialDate, dateFormat: 'yyyy-MM-dd' }),
        format({ date: new Date(), dateFormat: 'yyyy-MM-dd' })
      )
      .pipe(first())
      .subscribe((res: any) => {
        this.totalRelease = res.summaryBalanceStatement?.totalRelease;
      });

    this.investmentProfitability = this.planService.getInvestmentProfitability(
      this.registration,
      format({ date: this.initialDate, dateFormat: 'yyyy-MM-dd' }),
      format({ date: new Date(), dateFormat: 'yyyy-MM-dd' })
    );

    this.availableInvestmentProfitability =
      this.planService.getAvailableInvestmentProfitability(
        this.registration,
        format({ date: this.initialDate, dateFormat: 'yyyy-MM-dd' }),
        format({ date: new Date(), dateFormat: 'yyyy-MM-dd' })
      );

    this.referenceDate = this.planService
      .getPlanData(PlanData.ReferenceDate)
      .slice(6, 10);

    const data = this.statementService.getPlanData();
    this.plan = data.filter(
      (item) => item.registration === this.registration
    )[0];

    this.hasParcialBloq = this.getStatusPlan();
  }

  private getStatusPlan(): boolean {
    return (
      this.planService.getPlanData(PlanData.StatusPlano) ===
        this.statusPlanoPrevGarantia.statusPlano &&
      this.planService.getPlanData(PlanData.IdStatusPlanoDetalhe) ===
        this.statusPlanoPrevGarantia.bloqueioParcial
    );
  }

  setLegendColors(colors: ChartColor[]): void {
    this.legendColors = colors.map((color) => color.value);
  }

  public navigateToExtraContribution() {
    this.extraCService
      .getPlanDetails(this.registration)
      .subscribe((planDetail: DetailPlan) => {
        this.extraCService.setPlanData({
          planDetail: {
            ...planDetail,
            planGoal: this.planService.getObjectiveImage(this.plan.objective)
          }
        } as ExtraContributionData);
        this.router.navigate([
          `upselling/extra-contribution/simulator/${this.registration}`
        ]);
      });
  }
}
