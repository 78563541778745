import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { environment } from '@env/environment';
import { API_BASE_URL, Service } from './decumulationApi';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DecumulationAuthInterceptor } from './decumulation.interceptor';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [
    Service,
    {
      provide: API_BASE_URL,
      useValue: `${environment.api.brasilprev.url}`
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DecumulationAuthInterceptor,
      multi: true
    }
  ],
  exports: []
})
export class DecumulationApiModule {}
