<div class="container">
  <h3>Arquivos e documentos</h3>

  <mat-card class="files">
    <div class="file__line">
      <div>
        <p>Extrato regulamentar</p>
        <span>Arquivo PDF</span>
      </div>
      <div>
        <a
          class="pdf-download"
          mat-flat-button
          color="primary"
          href="{{ pdfUrl }}"
          target="_blank"
          [disabled]="!pdfUrl"
          *ngIf="showRegulamentarStatement"
        >
          Baixar
          <mat-icon>file_download</mat-icon>
        </a>
      </div>
    </div>
  </mat-card>
</div>
