<header>
  <img src="/assets/svg/brasilprev-yellowbg.svg" alt="Brasilprev logo" />

  <div class="type-menu">
    <button mat-button (click)="isTypeMenuOpen = !isTypeMenuOpen">
      Portal pessoa física
      <mat-icon>{{ isTypeMenuOpen ? 'expand_less' : 'expand_more' }}</mat-icon>
    </button>
    <div class="type-menu-container" [ngClass]="{ open: isTypeMenuOpen }">
      <button
        mat-button
        (click)="navigateToExternalURL('https://portalpj.brasilprev.com.br')"
      >
        Portal para empresas
      </button>
    </div>
  </div>
</header>
