export class User {
  userName: string;
  password: string;
}
export class AuthResult {
  username: string;
  code: string;
  flagUpdatedRegister: string;
  authToken: string;
  refreshToken: string;
}

export enum UserData {
  Id = 'id',
  Cpf = 'cpf',
  Name = 'name',
  SocialName = 'socialName',
  Registration = 'registration',
  Email = 'email',
  Ddd = 'ddd',
  Telephone = 'telephone',
  ModifiedDate = 'modified_date',
  CivilStatus = 'civil_status',
  ImageUrl = 'image_url',
  BirthDate = 'birth_date',
  Nationality = 'nationality',
  Gender = 'gender',
  Address = 'address',
  AddressNumber = 'address_number',
  AddressComplement = 'address_complement',
  Neighbourhood = 'neighbourhood',
  City = 'city',
  State = 'state',
  ZipCode = 'zip_code',
  FlagPlanosAtualizados = 'flagPlanosAtualizados',
  NameInitials = 'nameInitials',
  InvestorProfile = 'investor_profile',
  InvestorProfileStatus = 'investor_profile_status',
  ProfileStatus = 'profile_status',
  CurrentOS = 'current_os'
}

export class UserContacts {
  email: string;
  sms: string;
}
