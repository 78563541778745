const notificationSvgIcons = [
  {
    name: 'event-available',
    path: '/assets/design-system/icons/notification/event_available.svg'
  },
  {
    name: 'support-agent',
    path: '/assets/design-system/icons/notification/support_agent.svg'
  }
];

export default notificationSvgIcons;
