import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OpinTextWithBoldLettersComponent } from './text-with-bold-letters.component';

@NgModule({
  declarations: [OpinTextWithBoldLettersComponent],
  exports: [OpinTextWithBoldLettersComponent],
  imports: [CommonModule]
})
export class OpinTextWithBoldLettersModule {}
