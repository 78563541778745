const actionSvgIcons = [
  {
    name: 'done',
    path: '/assets/design-system/icons/action/done.svg'
  },
  {
    name: 'account-balance',
    path: '/assets/design-system/icons/action/account_balance.svg'
  },
  {
    name: 'home',
    path: '/assets/design-system/icons/action/home.svg'
  },
  {
    name: 'delete-forever',
    path: '/assets/design-system/icons/action/delete_forever.svg'
  },
  {
    name: 'info-outline',
    path: '/assets/design-system/icons/action/info_outline.svg'
  },
  {
    name: 'check-circle-outline',
    path: '/assets/design-system/icons/action/check_circle_outline.svg'
  },
  {
    name: 'alarm-on',
    path: '/assets/design-system/icons/action/alarm_on.svg'
  },
  {
    name: 'verified',
    path: '/assets/design-system/icons/action/verified.svg'
  },
  {
    name: 'date-range',
    path: '/assets/design-system/icons/action/date_range.svg'
  }
];

export default actionSvgIcons;
