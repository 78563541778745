import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
@Injectable()
export class ScreenUtils {
  constructor(private breakpointObserver: BreakpointObserver) {}

  get isMobile(): boolean {
    return this.breakpointObserver.isMatched('(max-width: 767px)');
  }
}
