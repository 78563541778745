<app-sub-header [backButtonSettings]="backButtonSettings">
  <span class="subtitle">Planos</span>
  <span class="title"> Simulação </span>
</app-sub-header>
<ng-template #formSimulation>
  <mat-card>
    <h3>
      Para essa simulação vamos considerar seu perfil de investidor como
      {{ investorProfile }}
    </h3>
    <p>
      Você pode alterar seu perfil de investidor
      <a [routerLink]="'/profile/investor'">aqui</a>
    </p>
    <form [formGroup]="simulationForm" (ngSubmit)="onSubmit()">
      <mat-form-field>
        <mat-label>Qual seu objetivo?</mat-label>
        <mat-select formControlName="objective">
          <mat-option
            *ngFor="let planObjective of planObjectiveValueList"
            [value]="planObjective"
          >
            {{ getPlanObjective(planObjective) }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="simulationForm.get('objective').hasError('required')">
          Esse campo é obrigatório!
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>
          <span *ngIf="simulationForm.get('objective').value !== 'RETIREMENT'">
            Tempo de investimento
          </span>

          <span *ngIf="simulationForm.get('objective').value === 'RETIREMENT'">
            Idade de aposentadoria
          </span>
        </mat-label>
        <input
          matInput
          type="number"
          formControlName="investmentDuration"
          inputmode="numeric"
        />
        <span matSuffix>Anos</span>
        <mat-error
          *ngIf="simulationForm.get('investmentDuration').hasError('required')"
        >
          Esse campo é obrigatório!
        </mat-error>
        <mat-error
          *ngIf="simulationForm.get('investmentDuration').hasError('min')"
        >
          <span *ngIf="simulationForm.get('objective').value !== 'RETIREMENT'">
            O tempo mínimo de investimento é de 1 ano.
          </span>
          <span *ngIf="simulationForm.get('objective').value === 'RETIREMENT'">
            A idade deve ser maior do que a sua atual, ou seja à partir
            {{ this.age + 1 }} anos.
          </span>
        </mat-error>
        <mat-error
          *ngIf="simulationForm.get('investmentDuration').hasError('max')"
        >
          <span *ngIf="simulationForm.get('objective').value !== 'RETIREMENT'"
            >A idade de saída máxima é de 99 anos, neste caso, seu tempo de
            contribuição precisa ser no máximo até
            {{ 99 - this.age }} anos.</span
          >
          <span *ngIf="simulationForm.get('objective').value === 'RETIREMENT'">
            O máximo permitido para a idade de saída é de 99 anos.
          </span>
        </mat-error>
      </mat-form-field>
      <mat-icon
        [inline]="true"
        matTooltipPosition="after"
        [matTooltip]="objectiveNotRetirementMsg"
        aria-label="Mais informações"
        *ngIf="simulationForm.get('objective').value !== 'RETIREMENT'"
      >
        help
      </mat-icon>
      <mat-icon
        [inline]="true"
        matTooltipPosition="after"
        [matTooltip]="objectiveRetirementMsg"
        aria-label="Mais informações"
        *ngIf="simulationForm.get('objective').value === 'RETIREMENT'"
      >
        help
      </mat-icon>
      <mat-form-field>
        <mat-label>Contribuição mensal</mat-label>
        <span matPrefix>R$ &nbsp;</span>
        <input
          matInput
          type="number"
          formControlName="monthlyContribution"
          inputmode="numeric"
        />
        <mat-error
          *ngIf="simulationForm.get('monthlyContribution').hasError('required')"
        >
          Esse campo é obrigatório!
        </mat-error>
        <mat-error
          *ngIf="simulationForm.get('monthlyContribution').hasError('min')"
        >
          Na Brasilprev você pode iniciar seus investimentos a partir de
          R$200,00.
        </mat-error>
      </mat-form-field>
      <mat-icon
        [inline]="true"
        matTooltipPosition="after"
        matTooltip="Na Brasilprev você pode iniciar seus investimentos a partir de R$200,00. Todo mês você receberá um boleto para sua contribuição mensal, e também pode alterá-la a qualquer momento após a contratação."
        aria-label="Mais informações"
      >
        help
      </mat-icon>
      <mat-form-field>
        <mat-label>Quanto gostaria de ter</mat-label>
        <span matPrefix>R$ &nbsp;</span>
        <input
          matInput
          type="number"
          formControlName="objectiveAmount"
          inputmode="numeric"
        />
      </mat-form-field>
      <mat-icon
        [inline]="true"
        matTooltipPosition="after"
        matTooltip="A quantia de dinheiro que deseja acumular. Se não souber ainda, tudo bem, este campo é opcional."
        aria-label="Mais informações"
      >
        help
      </mat-icon>

      <button mat-raised-button color="primary" type="submit" title="Simular">
        Simular
      </button>
    </form>
  </mat-card>
</ng-template>
<mat-card *ngIf="simulation$ | async as simulation; else formSimulation">
  <mat-card-header>
    <mat-card-title>
      {{ getPlanObjective(simulationForm.get('objective').value) }}
      {{ simulation.dataSaidaISO | date : 'yyyy' }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <header>
      <img
        src="{{ getPlanObjectiveImage(simulationForm.get('objective').value) }}"
        alt=""
      />
      <p>
        <strong>{{ userName }}</strong
        >,<br />
        Daqui <strong>{{ this.remainingYears }} anos</strong> você terá
        acumulado por volta de
        <strong>
          {{ simulation.valorSaldoProjetado | currency : 'BRL' }}
        </strong>
        , contribuindo com
        <strong>
          {{
            simulationForm.get('monthlyContribution').value | currency : 'BRL'
          }}
        </strong>
        mensalmente até lá.
      </p>
    </header>
    <section>
      <h2>Características do seu plano</h2>
      <dl>
        <dt>Plano</dt>
        <dd>
          {{ simulation.nomePlano }} <br />
          <span
            >Taxa de carregamento
            {{ simulation.taxaCarregamentoPre / 100 | percent }}</span
          >
          <mat-icon
            [inline]="true"
            matTooltipPosition="after"
            matTooltip="Ela serve para cobrir despesas de corretagem e administração. Incide somente em caso de portabilidade ou resgates. É decrescente em função do tempo de permanência no plano, podendo chegar a zero. Ou seja, quanto maior o tempo de permanência, menor será a taxa."
            aria-label="Mais informações"
          >
            help
          </mat-icon>
        </dd>
        <dt>Tipo de tributação</dt>
        <dd>
          <ng-container *ngIf="simulation.codigoTipoTributacao === 1">
            Regressiva definitiva
            <mat-icon
              [inline]="true"
              matTooltipPosition="after"
              matTooltip="A tabela regressiva é a escolha certa para o investidor que tem a perspectiva de resgatar o dinheiro apenas a longo prazo; quanto mais tempo permanecer no plano, menor será a alíquota do Imposto de Renda."
              aria-label="Mais informações"
            >
              help
            </mat-icon>
          </ng-container>
          <ng-container *ngIf="simulation.codigoTipoTributacao === 2">
            Progressiva
            <mat-icon
              [inline]="true"
              matTooltipPosition="after"
              matTooltip="A tributação progressiva é a mesma que determina a alíquota do Imposto de Renda sobre o seu
                    salário. Na prática, o que determina a alíquota sobre o plano de
                    previdência é o valor a ser resgatado ou transformado em renda + 15%
                    que fica retido na fonte. Quanto menos se têm, menos se paga. Ao escolher essa tributação você tem 1 mês a partir da contratação
                    para trocar para regressiva."
              aria-label="Mais informações"
            >
              help
            </mat-icon>
          </ng-container>
        </dd>
        <dt>Fundos de investimento</dt>
        <dd>
          Baseado no seu perfil {{ investorProfile }},
          <ng-container *ngIf="investorProfile === 'Conservador'">
            seu principal objetivo com a previdência é preservar o valor
            acumulado, então vou sugerir uns fundos bem seguros para você!
          </ng-container>
          <ng-container *ngIf="investorProfile === 'Moderado'">
            você já entende um pouco sobre investimentos, então escolhi uns
            fundos para você ir arriscando aos poucos!
          </ng-container>
          <ng-container *ngIf="investorProfile === 'Arrojado'">
            vou te sugerir fundos com rendimentos acima da média, mesmo que isso
            signifique correr algum risco para aproveitar os bons momentos!
          </ng-container>
          <ul>
            <li *ngFor="let investment of simulation.listaInvestimentos">
              {{ investment.percentualDistribuicao / 100 | percent }}
              {{ investment.nomeFundo }} <br />
              <span>
                Taxa de administração
                {{ investment.taxaAdministracaoFinanceira / 100 | percent }}
              </span>
              <mat-icon
                [inline]="true"
                matTooltipPosition="after"
                matTooltip="É cobrada pela tarefa de administrar o dinheiro do fundo de investimento exclusivo, criado para o seu plano. Como é descontada diariamente do valor total da reserva, a rentabilidade informada sobre seus fundos é sempre líquida, ou seja, você vê os valores com a taxa de administração já debitada."
                aria-label="Mais informações"
              >
                help
              </mat-icon>
            </li>
          </ul>
        </dd>
        <dt>Data de saída</dt>
        <dd>
          {{ simulation.dataSaidaISO | date }}
          <p>
            Para obtenção de renda, a idade mínima é 50 anos. Mas não se
            preocupe, você pode resgatar seu valor acumulado a qualquer momento.
          </p>
        </dd>
        <dt>Carência</dt>
        <dd>
          <table>
            <thead>
              <tr>
                <th>Tipo</th>
                <th>Resgate</th>
                <th>Portabilidade</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Inicial</td>
                <td>180 dias</td>
                <td>60 dias</td>
              </tr>
              <tr>
                <td>Entre eventos</td>
                <td>60 dias</td>
                <td>60 dias</td>
              </tr>
            </tbody>
          </table>
        </dd>
      </dl>
    </section>
    <footer>
      <p>
        O regulamento deste produto encontra-se registrados na Susep de acordo
        com o número de processo constante na proposta e pode ser consultado
        aqui.
      </p>
      <p>
        A simulação leva em consideração sua data de nascimento, podendo assim
        apresentar uma pequena diferença entre o tempo de investimento
        solicitado.
      </p>
      <p>
        A rentabilidade é calculada com base em 3,5% de juros reais ao ano. Os
        cálculos e as hipóteses financeiras aqui apresentadas são meras
        estimativas, não se constituindo em garantia ou obrigação da Brasilprev
        Seguros e Previdência S/A.
      </p>
    </footer>
  </mat-card-content>
</mat-card>
