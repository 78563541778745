<app-sub-header *ngIf="!isLoading" [backButtonSettings]="backButtonSettings">
  <span class="title">Editar Objetivo</span>
</app-sub-header>
<div>
  <mat-card>
    <mat-card-content>
      <form [formGroup]="objectiveForm" (submit)="onSubmit()">
        <p>
          Dê um nome e estipule um valor para o objetivo, quando melhor
          definido,<br />
          mais fácil de alcançá-lo!
        </p>
        <mat-form-field>
          <mat-label>Qual seu objetivo?</mat-label>
          <mat-select formControlName="objective">
            <mat-option
              *ngFor="let objective of getObjectiveList()"
              [value]="objective"
              >{{ objective }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Qual nome você quer dar a ele?</mat-label>
          <input
            matInput
            placeholder="Viagem dos sonhos"
            formControlName="name"
          />
          <mat-error *ngIf="objectiveForm.controls['name'].errors?.maxlength">
            <span> Número de Caracteres ultrapassa 100 </span>
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Quanto você quer acumular?</mat-label>
          <span matPrefix>R$ &nbsp;</span>
          <input
            matInput
            formControlName="amount"
            inputmode="numeric"
            type="number"
          />
          <mat-error *ngIf="objectiveForm.controls['amount'].errors?.maxlength">
            <span> Número de Caracteres ultrapassa 20 </span>
          </mat-error>
        </mat-form-field>
        <button
          *ngIf="!isLoading"
          mat-raised-button
          color="primary"
          type="submit"
        >
          Salvar
        </button>
      </form>
      <mat-spinner *ngIf="isLoading"></mat-spinner>
    </mat-card-content>
  </mat-card>
</div>
