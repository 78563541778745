<app-check-twofa-token
  title="Código de segurança"
  [text]="
    'Acabamos de te enviar um código de segurança, verifique seu ' +
    contactMethod +
    '.'
  "
  [back]="back"
  (hasCode)="handleCodeVerified($event)"
  [isLoading]="isLoading"
></app-check-twofa-token>
