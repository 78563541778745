import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PDFDialogService {
  constructor(private apollo: Apollo) {}

  public getRegulatoryStatementPDFLink(registration: string): Observable<any> {
    return this.apollo
      .use('statement')
      .watchQuery<any>({
        query: gql`
          query regulatoryStatement($registration: String!) {
            regulatoryStatement(registration: $registration) {
              status
              url
            }
          }
        `,
        variables: {
          registration
        }
      })
      .valueChanges.pipe(
        map((result) => result.data.regulatoryStatement),
        take(1)
      );
  }
}
