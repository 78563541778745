<div class="container">
  <mat-card>
    <h3>
      {{
        chosenChannel === 'recover'
          ? 'Esqueceu sua senha?'
          : 'Ainda não tem senha?'
      }}
    </h3>
    <form [formGroup]="sendCpfForm" (ngSubmit)="onSubmit()">
      <ng-container *ngIf="!isLoading">
        <p>
          {{
            chosenChannel === 'recover'
              ? 'Sem problemas! Insira seu CPF para localizar seu usuário.'
              : 'Então vamos criar uma! Digite seu CPF.'
          }}
        </p>
        <mat-form-field appearance="outline">
          <mat-label>CPF</mat-label>
          <input
            matInput
            type="text"
            formControlName="cpf"
            required
            inputmode="numeric"
          />
          <mat-error *ngIf="sendCpfForm.controls['cpf'].errors">
            <span *ngIf="sendCpfForm.controls['cpf'].errors?.required">
              Esse campo é obrigatório!
            </span>
            <span *ngIf="sendCpfForm.controls['cpf'].errors?.invalidCpf">
              Digite um CPF válido!
            </span>
          </mat-error>
        </mat-form-field>
        <div class="actions">
          <button
            class="main-button"
            mat-raised-button
            color="primary"
            type="submit"
            title="Avança para a seleção de contato"
          >
            Enviar
          </button>
          <button
            mat-button
            type="button"
            color="primary"
            [routerLink]="['/auth']"
          >
            Voltar
          </button>
        </div>
      </ng-container>
    </form>
    <mat-spinner *ngIf="isLoading"></mat-spinner>
  </mat-card>
</div>
