import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import {
  DefineDistributionRequest as DistributionRequest,
  DefineDistributionResponse as DistributionResponse,
  ExtraContributionBff,
  HttpError,
  InvestmentDetail,
  PaymentMethod,
  DefinePaymentMethodRequest as PaymentMethodRequest,
  DefinePaymentMethodResponse as PaymentMethodResponse,
  Plan,
  DetailPlan as PlanDetails,
  SimulationRequest,
  SimulationResponse,
  SolicitationPaymentDetail,
  SolicitationRequestDebitData,
  SolicitationResponse,
  TicketPdfBase64
} from '@app/core/extra-contribution-bff';
import { NotificationService } from '@app/core/notification/notification.service';
import { PlanCard } from '@app/shared/components/registration-card/registration-card.model';
import { Prefix } from '@app/shared/models/prefix.model';
import { CardService } from '@app/shared/service/card.service';
import { SessiontorageData } from '@app/shared/utils/sessionStorage';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';
import { ExceptionService } from '../exception.service';
import { SessionStorageUpsellingId, UpBaseRoute } from '../upselling.model';
import {
  ConfirmExtraContributionResponse,
  ExtraContributionData
} from './extra-contribution.model';

@Injectable({
  providedIn: 'root'
})
export class ExtraContributionService {
  public sessionStorageData = new SessiontorageData(Prefix.Upselling);
  public extraContributionId = SessionStorageUpsellingId.extraContributionData;

  constructor(
    private router: Router,
    private authService: AuthService,
    private cardService: CardService,
    private extraContributionBff: ExtraContributionBff,
    private exceptionService: ExceptionService,
    private notificationService: NotificationService
  ) {}

  public setPlanData(plan: ExtraContributionData): void {
    this.sessionStorageData.setData(
      this.extraContributionId,
      JSON.stringify(plan)
    );
  }

  public getPlanData(): ExtraContributionData {
    return JSON.parse(
      this.sessionStorageData.getData(this.extraContributionId)
    );
  }

  public deletePlanData(): void {
    this.sessionStorageData.deleteData(this.extraContributionId);
  }

  public getPlans(): Observable<PlanCard[]> {
    return this.extraContributionBff
      .plansList(this.authService.getToken())
      .pipe(
        take(1),
        map((plans: Plan[]) =>
          plans.map((plan: Plan) =>
            this.cardService.convertToPlanCard(plan, 'simular')
          )
        )
      );
  }

  public onSelection(plan: Plan): Observable<PlanDetails> {
    const convertedPlan = this.cardService.convertToPlanCard(plan);
    return this.getPlanDetails(plan.registration).pipe(
      take(1),
      tap((planDetail: PlanDetails) => {
        this.setPlanData({
          planDetail: {
            ...planDetail,
            planGoal: convertedPlan.image
          }
        } as ExtraContributionData);

        this.router.navigate([
          ...UpBaseRoute.extraContribution,
          'simulator',
          plan.registration
        ]);
      })
    );
  }

  public getPlanDetails(registration: string): Observable<PlanDetails> {
    return this.extraContributionBff
      .plan(this.authService.getToken(), registration)
      .pipe(take(1));
  }

  public getSimulation(
    requestData: SimulationRequest
  ): Observable<SimulationResponse> {
    return this.extraContributionBff
      .simulation(this.authService.getToken(), requestData)
      .pipe(
        map((simulation: SimulationResponse) =>
          SimulationResponse.fromJS({
            ...simulation,
            extraContributionDistribution: this.parseFundsPercentageToInteger(
              simulation.extraContributionDistribution
            )
          })
        ),
        take(1)
      );
  }

  public parseFundsPercentageToInteger(
    funds: InvestmentDetail[]
  ): InvestmentDetail[] {
    return funds.map((fund: InvestmentDetail) =>
      InvestmentDetail.fromJS({
        ...fund,
        percentage: +(fund.percentage * 100).toFixed(2)
      })
    );
  }

  public confirmFundsDistribution(
    distributionRequest: DistributionRequest
  ): Observable<DistributionResponse> {
    return this.extraContributionBff
      .defineDistribution(this.authService.getToken(), distributionRequest)
      .pipe(take(1));
  }

  public getPaymentMethods(simulationId: number): Observable<PaymentMethod[]> {
    return this.extraContributionBff
      .paymentMethodList(this.authService.getToken(), simulationId)
      .pipe(take(1));
  }

  public setPaymentMethod(
    paymentMethodRequest: PaymentMethodRequest
  ): Observable<PaymentMethodResponse> {
    return this.extraContributionBff
      .definePaymentMethod(this.authService.getToken(), paymentMethodRequest)
      .pipe(take(1));
  }

  public confirmExtraContribution(
    debitRequest: SolicitationRequestDebitData
  ): Observable<ConfirmExtraContributionResponse> {
    return this.extraContributionBff
      .solicitation(this.authService.getToken(), debitRequest)
      .pipe(
        catchError((error: HttpError) =>
          this.exceptionService.handleTransactionExceptions(error)
        ),
        mergeMap((confirmationResponse: SolicitationResponse) =>
          this.extraContributionBff
            .solicitationPaymentDetail(
              this.authService.getToken(),
              confirmationResponse.solicitationId
            )
            .pipe(
              catchError(() => EMPTY),
              map((confirmationDetails: SolicitationPaymentDetail) => {
                const confirmExtraContributionResponse = {
                  confirmationResponse,
                  confirmationDetails
                } as ConfirmExtraContributionResponse;

                this.setPlanData({
                  ...this.getPlanData(),
                  confirmExtraContributionResponse: {
                    ...confirmExtraContributionResponse
                  }
                } as ExtraContributionData);

                return confirmExtraContributionResponse;
              })
            )
        ),
        take(1)
      );
  }

  public getBoletoBase64(solicitationId: number): Observable<TicketPdfBase64> {
    return this.extraContributionBff
      .solicitationTicketDownload(this.authService.getToken(), solicitationId)
      .pipe(
        catchError(() => {
          this.notificationService.showError(
            'Não foi possível gerar o PDF do boleto, caso persista, entre em contato com nossa central de relacionamento.',
            'Ok',
            { duration: 0 }
          );
          return EMPTY;
        }),
        tap(
          (response: TicketPdfBase64) =>
            this.setPlanData({
              ...this.getPlanData(),
              base64Boleto: response.base64
            } as ExtraContributionData),
          take(1)
        )
      );
  }
}
