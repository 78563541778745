<div class="container">
  <mat-card>
    <h3>
      <span *ngIf="!passwordChanged">Nova senha</span>
      <span *ngIf="passwordChanged">
        Senha
        <span *ngIf="chosenChannel !== 'recover'">criada</span>
        <span *ngIf="chosenChannel === 'recover'">alterada</span>
        com sucesso!
      </span>
    </h3>
    <form [formGroup]="setPasswordForm" (ngSubmit)="onSubmit()">
      <ng-container *ngIf="!passwordChanged && !isLoading">
        <p>Crie sua senha numérica com 8 dígitos.</p>
        <mat-form-field>
          <mat-label>Digite sua nova senha</mat-label>
          <input
            matInput
            maxlength="8"
            [type]="isPasswordHidden ? 'password' : 'text'"
            formControlName="password"
            inputmode="numeric"
          />
          <button
            type="button"
            mat-icon-button
            matSuffix
            (click)="togglePasswordVisibility()"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="isPasswordHidden"
          >
            <mat-icon>{{
              isPasswordHidden ? 'visibility_off' : 'visibility'
            }}</mat-icon>
          </button>
          <mat-error *ngIf="formErrors">
            <span *ngIf="formErrors?.required"> campo obrigatório. </span>
            <span *ngIf="formErrors?.minlength || formErrors.maxlength">
              a senha deve conter 8 digitos
            </span>
            <span *ngIf="formErrors?.pattern">
              a senha deve conter apenas números
            </span>
            <span *ngIf="formErrors?.sequentialNumbers">
              a senha não pode conter números em ordem crescente ou decrescente
            </span>
            <span *ngIf="formErrors?.repeatedNumbers">
              a senha não pode conter números repetidos sequencialmente
            </span>
          </mat-error>
        </mat-form-field>
      </ng-container>
      <mat-spinner *ngIf="isLoading"></mat-spinner>
      <div class="actions">
        <ng-container *ngIf="!passwordChanged">
          <button
            class="main-button"
            mat-raised-button
            color="primary"
            type="submit"
            title="Confirma a troca de senha"
          >
            Enviar
          </button>
          <button
            mat-button
            color="primary"
            type="button"
            title="Voltar"
            (click)="back()"
          >
            Voltar
          </button>
        </ng-container>
        <ng-container *ngIf="passwordChanged">
          <button
            class="main-button"
            mat-raised-button
            color="primary"
            type="button"
            title="Avança para a página de login"
            [routerLink]="['/']"
          >
            Logar no portal
          </button>
        </ng-container>
      </div>
    </form>
  </mat-card>
  <div class="warning">
    <mat-icon>info</mat-icon>
    <h4>Sua nova senha deve seguir os critérios abaixo:</h4>
    <ul>
      <li>Ter 8 caracteres numéricos diferentes;</li>
      <li>Não utilizar sua data de nascimento;</li>
      <li>
        Não usar números em ordem crescente ou decrescente. Ex: 123 ou 321
      </li>
      <li>Não usar números repetidos sequencialmente. Ex: 1111.</li>
    </ul>
  </div>
</div>
