export enum itemKeyList {
  'concessionDate' = 'Data de saída:',
  'incomeType' = 'Tipo de renda:',
  'taxationType' = 'Tributação:',
  'taf' = 'TAF Média:',
  'name' = 'Tipo: ',
  'prize' = 'Benefício: ',
  'amount' = 'Contribuição: ',
  'frequencyContribution' = 'Periodicidade: '
}
