export enum OsType {
  ANDROID = 'ANDROID',
  LINUX = 'LINUX',
  WINDOWS = 'WINDOWS',
  MAC_OS = 'MAC_OS',
  IOS = 'IOS',
  UNKNOWN = 'UNKNOWN'
}

export enum OsStoreUrl {
  ANDROID = 'https://play.google.com/store/apps/details?id=br.com.brasilprev.app.cliente',
  IOS = 'https://apps.apple.com/br/app/brasilprev/id1545414496'
}
