<div class="content-filter">
  <mat-icon class="close-button" [mat-dialog-close]="filter_data">
    close
  </mat-icon>
  <mat-dialog-content>
    <h2 class="content-filter__title">Ordenar</h2>

    <section>
      <mat-radio-group class="order-section" [(ngModel)]="filter_data.order">
        <mat-radio-button
          #maior_rentabilidade
          name="order"
          color="primary"
          value="maior_rentabilidade"
          >Maior rentabilidade</mat-radio-button
        >
        <mat-radio-button name="order" color="primary" value="riskOrder"
          >Menor risco</mat-radio-button
        >
        <mat-radio-button name="order" color="primary" value="name"
          >Nome (A-Z)</mat-radio-button
        >
      </mat-radio-group>

      <div class="other-rentabilities" *ngIf="maior_rentabilidade.checked">
        <mat-radio-group [(ngModel)]="filter_data.rentabilities">
          <mat-radio-button
            name="rentabilities"
            color="primary"
            value="percentageAccumulated60"
            >60 meses</mat-radio-button
          >
          <mat-radio-button
            name="rentabilities"
            color="primary"
            value="percentageAccumulated48"
            >48 meses</mat-radio-button
          >
          <mat-radio-button
            name="rentabilities"
            color="primary"
            value="percentageAccumulated36"
            >36 meses</mat-radio-button
          >
          <mat-radio-button
            name="rentabilities"
            color="primary"
            value="percentageAccumulated24"
            >24 meses</mat-radio-button
          >
          <mat-radio-button
            name="rentabilities"
            color="primary"
            value="percentageAccumulated12"
            >12 meses</mat-radio-button
          >
          <mat-radio-button
            name="rentabilities"
            color="primary"
            value="percentageAccumulatedYear"
          >
            {{ data.referenceDate | date : 'yyyy' }}</mat-radio-button
          >
          <mat-radio-button
            name="rentabilities"
            color="primary"
            value="percentagePastMonth"
            >Mês anterior</mat-radio-button
          >
          <mat-radio-button
            name="rentabilities"
            color="primary"
            value="percentageCurrentMonth"
            >Mês atual</mat-radio-button
          >
        </mat-radio-group>
      </div>
    </section>

    <h2 class="content-filter__title">Filtrar</h2>

    <p class="content-filter__subtitle">Riscos</p>
    <section class="filter-section">
      <mat-checkbox
        [(ngModel)]="filter_data.risk.muito_baixo"
        name="risk"
        color="primary"
        value="muito_baixo"
        >Muito baixo</mat-checkbox
      >
      <mat-checkbox
        [(ngModel)]="filter_data.risk.baixo"
        name="risk"
        color="primary"
        value="baixo"
        >Baixo</mat-checkbox
      >
      <mat-checkbox
        [(ngModel)]="filter_data.risk.medio"
        name="risk"
        color="primary"
        value="medio"
        >Médio</mat-checkbox
      >
      <mat-checkbox
        [(ngModel)]="filter_data.risk.alto"
        name="risk"
        color="primary"
        value="alto"
        >Alto</mat-checkbox
      >
      <mat-checkbox
        [(ngModel)]="filter_data.risk.muito_alto"
        name="risk"
        color="primary"
        value="muito_alto"
        >Muito alto</mat-checkbox
      >
    </section>

    <p class="content-filter__subtitle">Categorias</p>
    <section class="filter-section">
      <mat-checkbox
        [(ngModel)]="filter_data.categories.renda_fixa"
        name="categories"
        color="primary"
        value="renda_fixa"
        >Renda fixa</mat-checkbox
      >
      <mat-checkbox
        [(ngModel)]="filter_data.categories.multimercado"
        name="categories"
        color="primary"
        value="multimercado"
        >Multimercado</mat-checkbox
      >
      <mat-checkbox
        [(ngModel)]="filter_data.categories.exclusivo"
        name="categories"
        color="primary"
        value="exclusivo"
        >Exclusivo</mat-checkbox
      >
      <mat-checkbox
        [(ngModel)]="filter_data.categories.ciclo_de_vida"
        name="categories"
        color="primary"
        value="ciclo_de_vida"
        >Ciclo de vida</mat-checkbox
      >
    </section>

    <button mat-button color="primary" (click)="onNoClick()">Limpar</button>
    <button
      mat-raised-button
      color="primary"
      type="button"
      [mat-dialog-close]="filter_data"
    >
      Aplicar filtros
    </button>
  </mat-dialog-content>
</div>
