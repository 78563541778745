import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { InvoiceService } from '@app/boletos/plan.service';
import { CertificateService } from '@app/certificate/certificate.service';
import { PlanService as CheckupService } from '@app/checkup/services/plan.service';
import { Planos } from '@app/core/boleto';
import {
  Service as CheckupBff,
  Plan as CheckupPlan
} from '@app/core/checkup-bff';
import { Plano } from '@app/core/contribution-manager-bff';
import { DepartureDateBff } from '@app/core/departure-date-bff';
import { ExtraContributionBff } from '@app/core/extra-contribution-bff';
import { NewContributionBff, Plan } from '@app/core/new-contribution-bff';
import {
  Plan as RelocationPlan,
  RelocationService
} from '@app/core/new-diversification';
import { DiversificationService } from '@app/diversification/services/diversification.service';
import { getValidAndInvalidPlans } from '@app/diversification/utils/get-valid-and-invalid-plans';
import { PlanModality, PlanSummary } from '@app/home/statement.model';
import { StatementService } from '@app/home/statement.service';
import { PlanService } from '@app/plan/plan.service';
import { PlanData } from '@app/plan/plan.model';
import { ExtraContributionData } from '@app/upselling/extra-contribution/extra-contribution.model';
import { ExtraContributionService } from '@app/upselling/extra-contribution/extra-contribution.service';
import { ContributionManagerService } from '@app/upselling/new-contribution/contribution-manager.service';
import { PlanData as NewContributionPlanData } from '@app/upselling/new-contribution/new-contribution.model';
import { PlansService } from '@app/upselling/new-contribution/plans.service';
import { DepartureData } from '@app/upselling/upselling.model';
import { UpsellingService } from '@app/upselling/upselling.service';
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import {
  catchError,
  finalize,
  map,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import {
  FeatData,
  FeatEligibleCall,
  FeatSelectionCall,
  Feature,
  SelectedPlanData
} from './plan-manager.model';

@Injectable({
  providedIn: 'root'
})
export class PlanManagerService {
  constructor(
    private router: Router,
    private authService: AuthService,
    private newContributionBff: NewContributionBff,
    private newContributionService: PlansService,
    private contributionManager: ContributionManagerService,
    private extraContributionBff: ExtraContributionBff,
    private extraContributionService: ExtraContributionService,
    private departureBff: DepartureDateBff,
    private departureService: UpsellingService,
    private relocationBff: RelocationService,
    private relocationService: DiversificationService,
    private certificateService: CertificateService,
    private statementService: StatementService,
    private boletoService: InvoiceService,
    private checkupBff: CheckupBff,
    private checkupService: CheckupService,
    private planService: PlanService
  ) {}

  public selectedPlanData: BehaviorSubject<SelectedPlanData> =
    new BehaviorSubject<SelectedPlanData>({});

  get getSelectedPlanData(): Observable<SelectedPlanData> {
    return this.selectedPlanData.asObservable();
  }

  public listNewContributionPlans(): Observable<Plan[]> {
    const planList = this.newContributionBff.plansList(
      this.authService.getToken()
    );
    let data = this.newContributionService.getPlanData();
    if (!data?.list) {
      return this.contributionManager.list().pipe(
        take(1),
        switchMap((ePlans: Plano) => {
          data = {
            manageContributionList: ePlans
          } as NewContributionPlanData;

          this.newContributionService.setPlanData(data);
          return planList;
        }),
        catchError(() => planList),
        tap((plans: Plan[]) =>
          this.newContributionService.setPlanData({
            ...data,
            list: plans
          } as NewContributionPlanData)
        )
      );
    } else {
      return of(data.list);
    }
  }

  public listExtraContributionPlans(): Observable<Plan[]> {
    const data = this.extraContributionService.getPlanData();
    if (!data?.list) {
      return this.extraContributionBff
        .plansList(this.authService.getToken())
        .pipe(
          catchError(() => EMPTY),
          take(1),
          tap((plans: Plan[]) =>
            this.extraContributionService.setPlanData({
              list: plans
            } as ExtraContributionData)
          )
        );
    } else {
      return of(data.list);
    }
  }

  public listDeparturePlans(): Observable<Plan[]> {
    const data = this.departureService.getPlanData();
    if (!data?.list) {
      return this.departureBff.plansList(this.authService.getToken()).pipe(
        catchError(() => EMPTY),
        take(1),
        tap((plans: Plan[]) =>
          this.departureService.setPlanData({ list: plans } as DepartureData)
        )
      );
    } else {
      return of(data.list);
    }
  }

  public listReloacationPlans(): Observable<RelocationPlan[]> {
    const data = this.relocationService.getPlanData();
    if (!data) {
      return this.relocationBff.plansList(this.authService.getToken()).pipe(
        catchError(() => EMPTY),
        take(1),
        tap((plans: RelocationPlan[]) => {
          this.relocationService.setPlans(getValidAndInvalidPlans(plans));
          this.relocationService.setPlanData(plans);
        })
      );
    } else {
      return of(data);
    }
  }

  public listBoletoPlans(): Observable<Planos[]> {
    return this.boletoService.listPlans().pipe(catchError(() => EMPTY));
  }

  public listCheckupPlans(): Observable<CheckupPlan[]> {
    const data = this.checkupService.getPlanData();
    if (!data) {
      return this.checkupBff.plansList(this.authService.getToken()).pipe(
        catchError(() => EMPTY),
        take(1),
        tap((plans: CheckupPlan[]) => {
          this.checkupService.setPlanData(plans);
        })
      );
    } else {
      return of(data);
    }
  }

  public isMonthlyProfitabilityEligible(
    registration: string
  ): Observable<Plan> {
    let isEligible = true;
    return of(Plan.fromJS({ registration })).pipe(
      tap(() => {
        const planModality = this.planService.getPlanData(
          PlanData.ModalityName
        );
        if (planModality === PlanModality.TRADITIONAL) {
          isEligible = false;
        }
      }),
      finalize(() =>
        this.handleFeatLists(Feature.MONTHLY_PROFITABILITY, isEligible)
      )
    );
  }

  public isIncomeTaxEligible(registration: string): Observable<Plan> {
    let hasPlan;
    return of(Plan.fromJS({ registration })).pipe(
      tap((plan: Plan) => (hasPlan = Boolean(plan))),
      finalize(() => this.handleFeatLists(Feature.INCOME_TAX, hasPlan))
    );
  }

  public isNewContributionEligible(registration: string): Observable<Plan> {
    let hasPlan = false;
    return this.listNewContributionPlans().pipe(
      map((plans: Plan[]) =>
        plans.find(
          (plan: Plan) => plan.registration === registration && plan.isEligible
        )
      ),
      tap((plan: Plan) => {
        hasPlan = Boolean(plan);
      }),
      finalize(() => this.handleFeatLists(Feature.NEW_CONTRIBUTION, hasPlan))
    );
  }

  public isExtraContributionEligible(registration: string): Observable<Plan> {
    let hasPlan = false;
    return this.listExtraContributionPlans().pipe(
      map((plans: Plan[]) =>
        plans.find(
          (plan: Plan) => plan.registration === registration && plan.isEligible
        )
      ),
      tap((plan: Plan) => {
        hasPlan = Boolean(plan);
      }),
      finalize(() => this.handleFeatLists(Feature.EXTRA_CONTRIBUTION, hasPlan))
    );
  }

  public isDepartureEligible(registration: string): Observable<Plan> {
    let hasPlan = false;
    return this.listDeparturePlans().pipe(
      map((plans: Plan[]) =>
        plans.find(
          (plan: Plan) => plan.registration === registration && plan.isEligible
        )
      ),
      tap((plan: Plan) => {
        hasPlan = Boolean(plan);
      }),
      finalize(() => this.handleFeatLists(Feature.DEPARTURE_DATE, hasPlan))
    );
  }

  public isRelocationEligible(registration: string): Observable<Plan> {
    let hasPlan = false;
    return this.listReloacationPlans().pipe(
      map(
        (plans: Plan[]) =>
          plans.find(
            (plan: Plan) =>
              plan.registration === registration && plan.isEligible
          ) as Plan
      ),
      tap((plan: Plan) => {
        hasPlan = Boolean(plan);
      }),
      finalize(() => this.handleFeatLists(Feature.RELOCATION, hasPlan))
    );
  }

  public isBoletoEligible(registration: string): Observable<Plan> {
    let hasPlan = false;
    return this.listBoletoPlans().pipe(
      map(
        (plans: Planos[]) =>
          plans.find(
            (plan: Planos) =>
              plan.matricula === registration &&
              this.isntNullOrUndefined(plan.boletos)
          ) as Planos
      ),
      tap((plan: Planos) => {
        hasPlan = Boolean(plan);
      }),
      finalize(() => this.handleFeatLists(Feature.BOLETOS, hasPlan))
    );
  }

  public isCheckupEligible(registration: string): Observable<CheckupPlan> {
    let hasPlan = false;
    return this.listCheckupPlans().pipe(
      map(
        (plans: CheckupPlan[]) =>
          plans.find(
            (plan: CheckupPlan) =>
              plan.registration === registration && plan.elegible
          ) as CheckupPlan
      ),
      tap((plan: CheckupPlan) => {
        hasPlan = Boolean(plan);
      }),
      finalize(() => this.handleFeatLists(Feature.CHECKUP, hasPlan))
    );
  }

  public isCertificateEligible(registration: string) {
    let hasPlan = false;
    return of(this.planService.getHomePlans()).pipe(
      catchError(() => EMPTY),
      take(1),
      map((plans: PlanSummary[]) =>
        plans?.find(
          (plan: PlanSummary) =>
            plan.registration === registration && plan.showCertificate
        )
      ),
      tap((plan: PlanSummary) => {
        hasPlan = Boolean(plan);
      }),
      finalize(() => this.handleFeatLists(Feature.CERTIFICATE, hasPlan))
    );
  }

  public onNewContributionSelection(plan: Plan): Observable<any> {
    if (
      this.contributionManager.isPlanaManagerEligible(
        plan.registration,
        this.newContributionService.getPlanData()
      )
    ) {
      return this.contributionManager.onSelection(plan);
    } else {
      return this.newContributionService.onSelection(plan);
    }
  }

  public onExtraContributionSelection(plan: Plan): Observable<any> {
    return this.extraContributionService.onSelection(plan);
  }

  public onDepartureDateSelection(plan: Plan): Observable<any> {
    return this.departureService.onSelection(plan);
  }

  public onRelocationSelection(plan: Plan): Observable<any> {
    return this.relocationService.onSelection(plan);
  }

  public onBoletoSelection(plan: Planos): Observable<any> {
    return this.boletoService.onSelection(plan.matricula);
  }

  public onCheckupSelection(plan: CheckupPlan): Observable<any> {
    this.router.navigate(['checkup', plan.registration]);
    return of();
  }

  public onCertificateSelection(planData: FeatData): Observable<any> {
    return this.certificateService.onSelection(planData.registration);
  }

  public onSurrenderSelection(planData: FeatData): Observable<any> {
    if (planData.registration) {
      this.router.navigate(['surrender', 'info', planData.registration]);
    } else {
      this.router.navigate(['surrender', 'info']);
    }
    return of().pipe(take(1));
  }

  public onVerifySurrenderSelection(_: FeatData): Observable<any> {
    this.router.navigate(['surrender', 'tracking']);
    return of().pipe(take(1));
  }

  public onDefineIncomeSelection(_: FeatData): Observable<any> {
    this.router.navigate(['decumulation', 'plan-selection']);
    return of().pipe(take(1));
  }

  public onIncomeTaxSelection(): Observable<any> {
    return of(this.statementService.getIncomeTax());
  }

  public onBalanceSelection(planData: FeatData): Observable<any> {
    this.statementService.navigateToStatement(planData.registration);
    return of().pipe(take(1));
  }

  public isntNullOrUndefined(data: any): boolean {
    return data !== null && data !== undefined;
  }

  public handleFeatLists(featName: Feature, hasFeat: boolean): void {
    this.getSelectedPlanData
      .pipe(take(1))
      .subscribe((selectedPlanData: SelectedPlanData) => {
        const planData = selectedPlanData;
        if (hasFeat) {
          this.addFeat(planData, featName);
        } else {
          this.removeFeat(planData, featName);
        }
      });
  }

  public removeFeat(planData: SelectedPlanData, featName: Feature) {
    this.selectedPlanData.next({
      registration: planData.registration,
      cardsFeatList: planData.cardsFeatList.filter(
        (feat: Feature) => feat !== featName
      ),
      menuFeatList: planData.menuFeatList
    } as SelectedPlanData);
  }
  public addFeat(planData: SelectedPlanData, featName: Feature) {
    this.selectedPlanData.next({
      registration: planData.registration,
      cardsFeatList: planData.cardsFeatList,
      menuFeatList: [...planData.menuFeatList, featName]
    } as SelectedPlanData);
  }

  public hasFeatOn(feature: Feature, featList: Feature[]): boolean {
    return Boolean(featList.find((feat: Feature) => feat === feature));
  }

  public handleMenuSelection(feature: Feature, registration: string) {
    if (FeatEligibleCall[feature]) {
      this[FeatEligibleCall[feature]](registration)
        .pipe(switchMap((data: any) => this[FeatSelectionCall[feature]](data)))
        .subscribe();
    } else {
      this[FeatSelectionCall[feature]]({ registration } as any);
    }
  }
}
