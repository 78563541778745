import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable()
export class InputUtils {
  matches(value: string): ValidatorFn {
    return (input: AbstractControl): ValidationErrors | null => {
      if (input.value === value) {
        return null;
      } else {
        return { differentValue: true };
      }
    };
  }
}
