import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { UserData } from '@app/core/auth/user.model';
import { SuccessGetContacts } from '@app/core/authentication';
import { UrlHistoryService } from '@app/url-history.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-ask-code',
  templateUrl: './ask-code.component.html',
  styleUrls: ['./ask-code.component.scss']
})
export class AskCodeComponent implements OnInit {
  userContacts$: Observable<SuccessGetContacts>;

  constructor(
    private urlHistoryService: UrlHistoryService,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.userContacts$ = this.getUserContacts();

    this.back = this.back.bind(this);
  }

  getUserContacts() {
    const email = this.authService.getUserData(UserData.Email);
    const telephone = this.authService.getUserData(UserData.Telephone);

    return of({ email, telephone } as SuccessGetContacts);
  }

  handleCodeSent() {
    this.router.navigate(['/auth/verify-code']);
  }

  back(): void {
    this.urlHistoryService.back();
  }
}
