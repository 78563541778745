import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { environment } from '@env/environment';
import { API_BASE_URL, Service } from './checkupBff';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [
    Service,
    {
      provide: API_BASE_URL,
      useValue: `${environment.api.brasilprev.url}/checkup-bff`.replace(
        '/p',
        ''
      )
    }
  ],
  exports: []
})
export class CheckupBffModule {}
