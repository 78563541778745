import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { UserData } from '@app/core/auth/user.model';
import { CpfUtils } from '@app/core/validators/cpf.validator';
import { PlanSummary } from '@app/home/statement.model';
import { StatementService } from '@app/home/statement.service';
import { format } from '@app/shared/date-fns';
import { PlanData } from '../plan.model';
import { PlanService } from '../plan.service';

@Component({
  selector: 'app-plan-data-page',
  templateUrl: './plan-data-page.component.html',
  styleUrls: ['./plan-data-page.component.scss']
})
export class PlanDataPageComponent implements OnInit {
  registration: string;
  initialDate: string;
  plan: PlanSummary;
  userCpf: string;
  planInfo;

  constructor(
    private router: Router,
    private planService: PlanService,
    private statementService: StatementService,
    private cpfUtils: CpfUtils,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.registration = this.router.url.split('/')[2];

    this.initialDate = this.planService.getPlanData(PlanData.InitialDate);
    this.populatePlanData();
    this.userCpf = this.cpfUtils.toDigits(
      this.authService.getUserData(UserData.Cpf)
    );

    this.planInfo = this.planService.getPlanInfo(
      this.registration,
      format({ date: this.initialDate, dateFormat: 'yyyy-MM-dd' }),
      format({ date: new Date(), dateFormat: 'yyyy-MM-dd' })
    );
  }

  public populatePlanData() {
    const data = this.statementService.getPlanData();
    this.plan = data.filter(
      (item) => item.registration === this.registration
    )[0];
  }
}
