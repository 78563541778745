import { Component, Input, OnInit } from '@angular/core';
import { format } from '@app/shared/date-fns';
import { messageNoMoviments } from '@app/shared/message';
import { makeDateFromBrazilianDate } from '@app/shared/utils/date';
import {
  differenceInMilliseconds,
  endOfYear,
  getYear,
  startOfYear
} from 'date-fns';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PlanService } from '../plan.service';

@Component({
  selector: 'app-transfers',
  templateUrl: './transfers.component.html',
  styleUrls: ['./transfers.component.scss']
})
export class TransfersComponent implements OnInit {
  @Input() registration: string;
  @Input() initialDate: string;

  year$: BehaviorSubject<string> = new BehaviorSubject(
    format({ date: new Date(), dateFormat: 'dd/MM/yyyy' })
  );

  planTransfers$: Observable<any> = this.year$.pipe(
    switchMap((year) =>
      this.planService.getTransactions(
        this.registration,
        format({
          date: startOfYear(makeDateFromBrazilianDate(year)),
          dateFormat: 'yyyy-MM-dd'
        }),
        format({
          date: endOfYear(makeDateFromBrazilianDate(year)),
          dateFormat: 'yyyy-MM-dd'
        })
      )
    )
  );

  years: Array<number> = [];
  messageNoMoviments = messageNoMoviments;

  constructor(private planService: PlanService) {}

  ngOnInit(): void {
    this.years = this.mountTransferYears();
  }

  public mountTransferYears(): Array<number> {
    const start = getYear(makeDateFromBrazilianDate(this.initialDate));
    const end = getYear(new Date());
    const milliseconds = differenceInMilliseconds(end, start);
    const yearsBetween = [];
    for (let year = 0; year <= milliseconds; year++) {
      yearsBetween.push(start + year);
    }
    return yearsBetween;
  }

  public nextYear(year: number) {
    if (year) {
      this.year$.next(
        format({
          date: startOfYear(new Date(year, 0, 1)),
          dateFormat: 'dd/MM/yyyy'
        })
      );
    }
  }
}
