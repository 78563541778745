import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { AuthData } from '@app/core/auth/auth.model';
import { UserData } from '@app/core/auth/user.model';
import { ErrorResponse } from '@app/core/authentication';
import { finalize } from 'rxjs/operators';
import { Channel } from '../auth.model';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-ask-twofa-token',
  templateUrl: './ask-twofa-token.component.html',
  styleUrls: ['../auth-components.scss']
})
export class AskTwofaTokenComponent implements OnInit {
  @Input() passwordRecovery = false;
  @Input() title: string;
  @Input() text: string;
  @Input() back: () => void;
  @Output() codeSent = new EventEmitter();
  userEmail: string;
  userTelephone: string;
  contact = new UntypedFormControl('', [Validators.required]);
  hasSubmitted = false;
  isLoading = false;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.setUserContacts();
  }

  public setUserContacts(): void {
    this.userEmail = this.authService.getUserData(UserData.Email);

    this.userTelephone = this.authService.getUserData(UserData.Telephone)
      ? `${this.getUserDdd()}${this.authService.getUserData(
          UserData.Telephone
        )}`
      : undefined;

    if (!(this.userEmail && this.userTelephone)) {
      this.contact.setValue(this.userEmail ? Channel.EMAIL : Channel.SMS);
    }
  }

  public getUserDdd(): string {
    const telephoneHasDdd =
      this.authService.getUserData(UserData.Telephone)?.length > 9;

    return telephoneHasDdd ? '' : this.authService.getUserData(UserData.Ddd);
  }

  public onSubmit(): void {
    this.hasSubmitted = true;
    if (this.contact.valid) {
      this.isLoading = true;

      this.authService
        .askCode(this.passwordRecovery ? Channel.SMS : this.contact.value)
        .pipe(finalize(() => this.codeSent.emit()))
        .subscribe((response: ErrorResponse | null) => {
          this.isLoading = false;

          if (response === null) {
            this.authService.setAuthData(
              AuthData.ContactMethod,
              this.contact.value
            );

            this.codeSent.emit();

            this.hasSubmitted = false;
          }
        });
    }
  }
}
