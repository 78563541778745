<article>
  <section>
    <h5>Central de relacionamento</h5>
    <strong class="yellow"
      >Dúvidas cadastrais, contratuais e consultas aos planos.</strong
    >
    <strong
      >Atendimento das 8h às 20h, de segunda a sexta-feira, exceto
      feriados.</strong
    >
    <span>Capitais e regiões metropolitanas: <strong>4004 7170</strong></span>
    <span>Demais localidades: <strong>0800 729 7170</strong></span>
  </section>
  <section>
    <h5>SAC</h5>
    <strong class="yellow"
      >Informações gerais, sugestões, reclamações e cancelamentos.</strong
    >
    <strong
      >Atendimento das 8h às 20h, de segunda a sexta-feira, exceto
      feriados.</strong
    >
    <span>Qualquer localidade: <strong>0800 729 7191</strong></span>
  </section>
  <section>
    <h5>Para pessoas com deficiência auditiva ou de fala</h5>
    <strong></strong>
    <strong
      >Atendimento das 8h às 20h, de segunda a sexta-feira, exceto
      feriados.</strong
    >
    <app-accessibility-service-channel-link
      channel="sac"
      color="light"
    ></app-accessibility-service-channel-link>
  </section>
  <section class="wpp">
    <h5>Whatsapp Brasilprev</h5>
    <strong
      >Nosso canal do Whatsapp foi criado para te ajudar em todos os
      momentos</strong
    >
    <strong>Clique abaixo</strong>
    <a
      href="https://bit.ly/3G53lsW"
      target="_blank"
      title="Entrar em contato via Whatsapp"
      ><span>+55 11 4004 7170</span
      ><img src="/assets/svg/social/whatsapp.svg" alt=""
    /></a>
    <img class="qrcode" src="/assets/svg/social/wpp-qrcode.svg" alt="" />
  </section>
  <section>
    <h5>Ouvidoria</h5>
    <strong class="yellow"
      >Quando suas necessidades não foram atendidas anteriormente de forma
      satisfatória.</strong
    >
    <strong
      >Atendimento das 9h às 18h, de segunda a sexta-feira, exceto
      feriados.</strong
    >
    <span>Qualquer localidade: <strong>0800 727 7109</strong></span>
    <span>WhatsApp: <strong>(11) 2540 2015</strong></span>
    <span>E-mail: <strong>ouvidoriaexterna&#64;brasilprev.com.br</strong></span>
    <h5>Para pessoas com deficiência auditiva ou de fala</h5>
    <strong
      >Atendimento das 9h às 18h, de segunda a sexta-feira, exceto
      feriados.</strong
    >
    <app-accessibility-service-channel-link
      channel="ouvidoria"
      color="light"
    ></app-accessibility-service-channel-link>
  </section>
</article>
