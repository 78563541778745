import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { OpinSharingCardComponent } from './sharing-card.component';

import { OpinButtonModule } from '../../atoms/button/button.module';
import { OpinDividerComponentModule } from '../../atoms/divider/divider.module';
import { OpinIconModule } from '../../atoms/icon/icon.module';
import { OpinLabelModule } from '../../atoms/label/label.module';
import { OpinButtonWithIconComponentModule } from '../button-with-icon/button-with-icon.module';

@NgModule({
  declarations: [OpinSharingCardComponent],
  exports: [OpinSharingCardComponent],
  imports: [
    CommonModule,
    OpinButtonModule,
    OpinIconModule,
    OpinLabelModule,
    OpinDividerComponentModule,
    OpinButtonWithIconComponentModule
  ]
})
export class OpinSharingCardComponentModule {}
