import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-plan-about',
  templateUrl: './plan-about.component.html',
  styleUrls: ['./plan-about.component.scss']
})
export class PlanAboutComponent implements OnInit {
  registration: string;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.registration = this.activatedRoute.snapshot.params.registration;
  }
}
