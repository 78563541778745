<div class="container">
  <div class="funds">
    <h3>Meus fundos</h3>
    <mat-card>
      <mat-card-content class="fund-content">
        <ng-container *ngIf="funds$ | async as funds; else loading">
          <div class="chart">
            <app-doughnut-chart
              [funds]="funds"
              (chartColors)="setLegendColors($event)"
            ></app-doughnut-chart>
            <div class="total-release">
              <strong>Saldo Bruto</strong>
              <p
                class="total-release__title"
                [appPrivacy]="totalRelease | currency"
              ></p>
            </div>
          </div>

          <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Nome do fundo</th>
              <td mat-cell *matCellDef="let element; let i = dataIndex">
                <span class="fund-name">
                  <span
                    class="chart-line-color"
                    [ngStyle]="{ background: legendColors[i] }"
                  ></span>
                  {{ element.name }}
                </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="risk">
              <th mat-header-cell *matHeaderCellDef>Risco</th>
              <td mat-cell *matCellDef="let element">
                <span class="risk-badge" *ngIf="element.riskGrade">
                  {{ element.riskGrade }}
                </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="balance">
              <th mat-header-cell *matHeaderCellDef>Valor do fundo</th>
              <td
                mat-cell
                *matCellDef="let element"
                [appPrivacy]="element.balance | currency"
              ></td>
            </ng-container>
            <ng-container matColumnDef="balanceYield">
              <th mat-header-cell *matHeaderCellDef>Quanto já rendeu</th>
              <td
                mat-cell
                *matCellDef="let element"
                [appPrivacy]="element.balanceYield | currency"
              ></td>
            </ng-container>
            <ng-container matColumnDef="details">
              <th mat-header-cell *matHeaderCellDef>Mais detalhes</th>
              <td mat-cell *matCellDef="let element">
                <a
                  [href]="
                    'https://bp-arquivos-fundos.brasilprev.com.br/informacoes/' +
                    element.name.replaceAll(' ', '_').concat('.pdf')
                  "
                  target="_blank"
                  >Boletim informativo</a
                >
              </td>
            </ng-container>
            <ng-container matColumnDef="aporte">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <button
                  mat-raised-button
                  color="primary"
                  type="button"
                  title="Contribuição extra"
                  (click)="navigateToExtraContribution()"
                >
                  Aporte extra
                </button>
              </td>
            </ng-container>
            <ng-container matColumnDef="expand">
              <th
                mat-header-cell
                *matHeaderCellDef
                [ngClass]="{ 'hide-expand-button': !hasParcialBloq }"
              ></th>
              <td
                mat-cell
                *matCellDef="let element"
                [ngClass]="{ 'hide-expand-button': !hasParcialBloq }"
              >
                <button
                  mat-icon-button
                  aria-label="expand row"
                  (click)="
                    expandedElement =
                      expandedElement === element ? null : element;
                    $event.stopPropagation()
                  "
                >
                  <mat-icon *ngIf="expandedElement !== element"
                    >keyboard_arrow_down</mat-icon
                  >
                  <mat-icon *ngIf="expandedElement === element"
                    >keyboard_arrow_up</mat-icon
                  >
                </button>
              </td>
            </ng-container>

            <ng-container matColumnDef="expandedDetail">
              <td
                mat-cell
                *matCellDef="let element"
                [attr.colspan]="displayedColumns.length"
              >
                <div
                  class="example-element-detail"
                  [@detailExpand]="
                    element === expandedElement ? 'expanded' : 'collapsed'
                  "
                >
                  <div class="balance-detail desktop-view">
                    <div>
                      <strong> Saldo Dispo. </strong>
                      <p [appPrivacy]="element?.saldoDisponivel | currency"></p>
                    </div>

                    <div>
                      <strong> Saldo Bloq. </strong>
                      <p [appPrivacy]="element?.saldoBloqueado | currency"></p>
                    </div>
                  </div>

                  <div class="mobile-view">
                    <div>
                      <span>Risco:</span>
                      <span class="risk-badge" *ngIf="element.riskGrade">
                        {{ element.riskGrade }}
                      </span>
                    </div>
                    <div>
                      <span>Quanto já rendeu:</span>
                      <span
                        [appPrivacy]="element.balanceYield | currency"
                      ></span>
                    </div>
                    <div *ngIf="hasParcialBloq">
                      <strong> Saldo Dispo. </strong>
                      <span [appPrivacy]="element?.saldoDisponivel | currency">
                      </span>
                    </div>
                    <div *ngIf="hasParcialBloq">
                      <strong> Saldo Bloq. </strong>
                      <span [appPrivacy]="element?.saldoBloqueado | currency">
                      </span>
                    </div>
                    <div>
                      <span>Mais detalhes:</span>
                      <a
                        [href]="
                          'https://bp-arquivos-fundos.brasilprev.com.br/informacoes/' +
                          element.name.replaceAll(' ', '_').concat('.pdf')
                        "
                        target="_blank"
                        >Boletim informativo</a
                      >
                    </div>
                    <div>
                      <button
                        mat-raised-button
                        color="primary"
                        type="button"
                        title="Contribuição extra"
                        (click)="navigateToExtraContribution()"
                      >
                        Aporte extra
                      </button>
                    </div>
                  </div>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr
              mat-row
              *matRowDef="let element; columns: displayedColumns"
              class="example-element-row"
              [class.example-expanded-row]="expandedElement === element"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: ['expandedDetail']"
              class="example-detail-row"
            ></tr>
          </table>
        </ng-container>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<ng-template #loading>
  <mat-spinner [diameter]="50"></mat-spinner>
</ng-template>

<div class="container">
  <app-profitability
    [referenceDate]="referenceDate"
    [profitability]="investmentProfitability"
    [availableProfitability]="availableInvestmentProfitability"
    [registration]="registration"
  ></app-profitability>
</div>
