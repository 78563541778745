<div class="profitability">
  <h3>Rentabilidade por período dos meus fundos</h3>
  <mat-card>
    <mat-card-content>
      <div class="content-table">
        <table
          class="investment-list"
          mat-table
          [dataSource]="investmentList"
          multiTemplateDataRows
        >
          <ng-container matColumnDef="name" sticky>
            <th mat-header-cell *matHeaderCellDef>Nome do fundo</th>
            <td mat-cell *matCellDef="let element">
              <p>{{ element.category }}</p>
              {{ element.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="riskGrade">
            <th mat-header-cell *matHeaderCellDef>Risco</th>
            <td mat-cell *matCellDef="let element" [attr.data-label]="'Risco'">
              <span class="risk-badge" *ngIf="element.riskGrade">
                {{ element.riskGrade }}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="currentMonth">
            <th mat-header-cell *matHeaderCellDef>Mês atual</th>
            <td
              mat-cell
              *matCellDef="let element"
              [attr.data-label]="'Mês atual'"
            >
              {{ element.percentageCurrentMonth | number : '1.0-2' }}%
            </td>
          </ng-container>
          <ng-container matColumnDef="pastMonth">
            <th mat-header-cell *matHeaderCellDef>Mês anterior</th>
            <td
              mat-cell
              *matCellDef="let element"
              [attr.data-label]="'Mês anterior'"
            >
              {{ element.percentagePastMonth | number : '1.0-2' }}%
            </td>
          </ng-container>
          <ng-container matColumnDef="currencyYear">
            <th mat-header-cell *matHeaderCellDef>
              {{ referenceDate | date : 'yyyy' }}
            </th>
            <td mat-cell *matCellDef="let element" [attr.data-label]="'Ano'">
              {{ element.percentageAccumulatedYear | number : '1.0-2' }}%
            </td>
          </ng-container>
          <ng-container matColumnDef="months12">
            <th mat-header-cell *matHeaderCellDef>12M</th>
            <td mat-cell *matCellDef="let element" [attr.data-label]="'12M'">
              {{ element.percentageAccumulated12 | number : '1.0-2' }}%
            </td>
          </ng-container>
          <ng-container matColumnDef="months24">
            <th mat-header-cell *matHeaderCellDef>24M</th>
            <td mat-cell *matCellDef="let element" [attr.data-label]="'24M'">
              {{ element.percentageAccumulated24 | number : '1.0-2' }}%
            </td>
          </ng-container>
          <ng-container matColumnDef="months36">
            <th mat-header-cell *matHeaderCellDef>36M</th>
            <td mat-cell *matCellDef="let element" [attr.data-label]="'36M'">
              {{ element.percentageAccumulated36 | number : '1.0-2' }}%
            </td>
          </ng-container>
          <ng-container matColumnDef="months48">
            <th mat-header-cell *matHeaderCellDef>48M</th>
            <td mat-cell *matCellDef="let element" [attr.data-label]="'48M'">
              {{ element.percentageAccumulated48 | number : '1.0-2' }}%
            </td>
          </ng-container>
          <ng-container matColumnDef="months60">
            <th mat-header-cell *matHeaderCellDef>60M</th>
            <td mat-cell *matCellDef="let element" [attr.data-label]="'60M'">
              {{ element.percentageAccumulated60 | number : '1.0-2' }}%
            </td>
          </ng-container>
          <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">
              &nbsp;
            </th>
            <td mat-cell *matCellDef="let element; let idx = dataIndex">
              <button
                mat-icon-button
                class="show-funds-detail"
                *ngIf="idx > 0"
                aria-label="expand row"
                (click)="
                  expandedElement =
                    expandedElement === element ? null : element;
                  $event.stopPropagation()
                "
              >
                <span class="material-icons" *ngIf="expandedElement !== element"
                  >keyboard_arrow_down</span
                >
                <span class="material-icons" *ngIf="expandedElement === element"
                  >keyboard_arrow_up</span
                >
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <td
              mat-cell
              *matCellDef="let element"
              [attr.colspan]="displayedColumnsProfitability.length"
            >
              <div
                class="element-detail"
                [@detailExpand]="
                  element === expandedElement ? 'expanded' : 'collapsed'
                "
              >
                <a
                  [href]="
                    'https://bp-arquivos-fundos.brasilprev.com.br/informacoes/' +
                    element.name.replaceAll(' ', '_').concat('.pdf')
                  "
                  target="_blank"
                  mat-button
                  color="primary"
                >
                  <img
                    src="../../../../assets/svg/download.svg"
                    alt="icone filtrar"
                  />
                  Baixar boletim informativo
                </a>

                <a
                  [href]="
                    'https://www1.brasilprev.com.br/rentabilidade/' +
                    element.name.replaceAll(' ', '_')
                  "
                  mat-button
                  color="primary"
                  target="_blank"
                  >Acessar mais detalhes</a
                >

                <button
                  mat-raised-button
                  color="primary"
                  type="button"
                  title="Contribuição extra"
                  (click)="navigateToExtraContribution()"
                >
                  Investir
                  <span class="material-icons"> chevron_right </span>
                </button>
              </div>
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumnsProfitability"
          ></tr>
          <tr
            mat-row
            *matRowDef="let element; columns: displayedColumnsProfitability"
            class="element-row"
            [class.expanded-row]="expandedElement === element"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: ['expandedDetail']"
            class="detail-row"
          ></tr>
        </table>
      </div>
    </mat-card-content>
  </mat-card>

  <h3>Rentabilidade por período dos fundos disponíveis para investir</h3>
  <p>Filtre os seus resultados:</p>
  <div class="filter-container">
    <div class="search">
      <mat-form-field appearance="outline">
        <mat-label>
          <img src="../../../../assets/svg/search.svg" alt="icone filtrar" />
          Buscar nome do fundo
        </mat-label>
        <input
          matInput
          (keyup)="applyFilter($event)"
          placeholder="Ex. FIX III"
          #input
        />
      </mat-form-field>
    </div>
    <div class="filter">
      <button mat-button color="primary" (click)="openFilterModal()">
        Filtrar e ordenar
        <span class="filter-badge" *ngIf="countFilter">{{ countFilter }}</span>
        <img src="../../../../assets/svg/filter.svg" alt="icone filtrar" />
      </button>
    </div>
    <div class="funds-compare">
      <button mat-button color="primary" (click)="showInvestmentComparator()">
        {{ isComparatorVisible ? 'Fechar comparador' : 'Comparar fundos' }}
        <img src="../../../../assets/svg/compare.svg" alt="" />
      </button>
    </div>
  </div>
  <app-investment-comparator
    *ngIf="isComparatorVisible"
    [investmentsNames]="investmentsNames"
  ></app-investment-comparator>

  <mat-card>
    <mat-card-content>
      <div class="content-table">
        <table
          *ngIf="availableInvestmentList"
          mat-table
          [dataSource]="availableInvestmentList"
          multiTemplateDataRows
        >
          <ng-container matColumnDef="name" sticky>
            <th class="table-header" mat-header-cell *matHeaderCellDef>
              Fundos disponíveis
            </th>
            <td mat-cell *matCellDef="let element">
              <p>{{ element.category }}</p>
              {{ element.name }}
            </td>
          </ng-container>
          <ng-container matColumnDef="riskGrade">
            <th mat-header-cell *matHeaderCellDef>Risco</th>
            <td mat-cell *matCellDef="let element" [attr.data-label]="'Risco'">
              <span class="risk-badge" *ngIf="element.riskGrade">
                {{ element.riskGrade }}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="currentMonth">
            <th mat-header-cell *matHeaderCellDef>Mês atual</th>
            <td
              mat-cell
              *matCellDef="let element"
              [attr.data-label]="'Mês atual'"
            >
              {{ element.percentageCurrentMonth | number : '1.0-2' }}%
            </td>
          </ng-container>
          <ng-container matColumnDef="pastMonth">
            <th mat-header-cell *matHeaderCellDef>Mês anterior</th>
            <td
              mat-cell
              *matCellDef="let element"
              [attr.data-label]="'Mês anterior'"
            >
              {{ element.percentagePastMonth | number : '1.0-2' }}%
            </td>
          </ng-container>
          <ng-container matColumnDef="currencyYear">
            <th mat-header-cell *matHeaderCellDef>
              {{ referenceDate | date : 'yyyy' }}
            </th>
            <td mat-cell *matCellDef="let element" [attr.data-label]="'Ano'">
              {{ element.percentageAccumulatedYear | number : '1.0-2' }}%
            </td>
          </ng-container>
          <ng-container matColumnDef="months12">
            <th mat-header-cell *matHeaderCellDef>12M</th>
            <td mat-cell *matCellDef="let element" [attr.data-label]="'12M'">
              {{ element.percentageAccumulated12 | number : '1.0-2' }}%
            </td>
          </ng-container>
          <ng-container matColumnDef="months24">
            <th mat-header-cell *matHeaderCellDef>24M</th>
            <td mat-cell *matCellDef="let element" [attr.data-label]="'24M'">
              {{ element.percentageAccumulated24 | number : '1.0-2' }}%
            </td>
          </ng-container>
          <ng-container matColumnDef="months36">
            <th mat-header-cell *matHeaderCellDef>36M</th>
            <td mat-cell *matCellDef="let element" [attr.data-label]="'36M'">
              {{ element.percentageAccumulated36 | number : '1.0-2' }}%
            </td>
          </ng-container>
          <ng-container matColumnDef="months48">
            <th mat-header-cell *matHeaderCellDef>48M</th>
            <td mat-cell *matCellDef="let element" [attr.data-label]="'48M'">
              {{ element.percentageAccumulated48 | number : '1.0-2' }}%
            </td>
          </ng-container>
          <ng-container matColumnDef="months60">
            <th mat-header-cell *matHeaderCellDef>60M</th>
            <td mat-cell *matCellDef="let element" [attr.data-label]="'60M'">
              {{ element.percentageAccumulated60 | number : '1.0-2' }}%
            </td>
          </ng-container>

          <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">
              &nbsp;
            </th>
            <td mat-cell *matCellDef="let element; let idx = dataIndex">
              <button
                mat-icon-button
                class="show-funds-detail"
                aria-label="expand row"
                (click)="
                  expandedElement =
                    expandedElement === element ? null : element;
                  $event.stopPropagation()
                "
              >
                <span class="material-icons" *ngIf="expandedElement !== element"
                  >keyboard_arrow_down</span
                >
                <span class="material-icons" *ngIf="expandedElement === element"
                  >keyboard_arrow_up</span
                >
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <td
              mat-cell
              *matCellDef="let element"
              [attr.colspan]="displayedColumnsProfitability.length"
            >
              <div
                class="element-detail"
                [@detailExpand]="
                  element === expandedElement ? 'expanded' : 'collapsed'
                "
              >
                <a
                  [href]="
                    'https://bp-arquivos-fundos.brasilprev.com.br/informacoes/' +
                    element.name.replaceAll(' ', '_').concat('.pdf')
                  "
                  target="_blank"
                  mat-button
                  color="primary"
                >
                  <img
                    src="../../../../assets/svg/download.svg"
                    alt="icone filtrar"
                  />
                  Baixar boletim informativo
                </a>

                <a
                  [href]="
                    'https://www1.brasilprev.com.br/rentabilidade/' +
                    element.name.replaceAll(' ', '_')
                  "
                  mat-button
                  color="primary"
                  target="_blank"
                  >Acessar mais detalhes</a
                >

                <button
                  mat-raised-button
                  color="primary"
                  type="button"
                  title="Contribuição extra"
                  (click)="navigateToExtraContribution()"
                >
                  Investir
                  <span class="material-icons"> chevron_right </span>
                </button>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let element; columns: displayedColumns"
            class="element-row"
            [class.expanded-row]="expandedElement === element"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: ['expandedDetail']"
            class="detail-row"
          ></tr>
        </table>
      </div>
    </mat-card-content>
  </mat-card>
  <button
    mat-button
    color="primary"
    class="show-more"
    (click)="showMoreFunds()"
    *ngIf="isShowMoreLinesVisible"
  >
    <img src="../../../../assets/svg/plus.svg" alt="" />
    Ver mais fundos
  </button>
</div>
