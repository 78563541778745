import { NgModule } from '@angular/core';

import { OpinButtonModule } from '../../atoms/button/button.module';
import { OpinTextComponentModule } from '../../atoms/text/text.module';
import { OpinSharingHeaderComponent } from './sharing-header.component';

@NgModule({
  declarations: [OpinSharingHeaderComponent],
  exports: [OpinSharingHeaderComponent],
  imports: [OpinTextComponentModule, OpinButtonModule]
})
export class OpinSharingHeaderModule {}
