import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import {
  DetailPlan,
  NewContributionBff,
  Plan
} from '@app/core/new-contribution-bff';
import { PlanCard } from '@app/shared/components/registration-card/registration-card.model';
import { Prefix } from '@app/shared/models/prefix.model';
import { CardService } from '@app/shared/service/card.service';
import { SessiontorageData } from '@app/shared/utils/sessionStorage';
import { Observable, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { SessionStorageUpsellingId, UpBaseRoute } from '../upselling.model';
import { PlanData } from './new-contribution.model';

@Injectable({
  providedIn: 'root'
})
export class PlansService {
  public sessionStorageData = new SessiontorageData(Prefix.Upselling);
  public keyId = SessionStorageUpsellingId.newContributionData;

  constructor(
    private newContributionBff: NewContributionBff,
    private authService: AuthService,
    private cardService: CardService,
    private router: Router
  ) {}

  public getPlanCards(): Observable<PlanCard[]> {
    return this.newContributionBff.plansList(this.authService.getToken()).pipe(
      take(1),
      map((plans: Plan[]) =>
        plans.map((plan: Plan) =>
          this.cardService.convertToPlanCard(plan, 'Simular')
        )
      )
    );
  }

  public onSelection(plan: Plan): Observable<any> {
    this.setPlanData({
      ...this.getPlanData(),
      plan: this.cardService.convertToPlanCard(plan)
    } as PlanData);
    this.router.navigate([
      ...UpBaseRoute.newContribution,
      'simulator',
      plan.registration
    ]);
    return of().pipe(take(1));
  }

  private getAndStoreDetails(registration: string) {
    return this.newContributionBff
      .plan(this.authService.getToken(), registration)
      .pipe(
        take(1),
        tap((details: DetailPlan) =>
          this.setPlanData({ ...this.getPlanData(), details } as PlanData)
        )
      );
  }

  public getDetails(registration: string): Observable<DetailPlan> {
    return this.checkIfIsEmptyOrDifferentFrom(registration, this.getPlanData())
      ? this.getAndStoreDetails(registration)
      : of(this.getPlanData().details).pipe(take(1));
  }

  public setPlanData(planData: PlanData): void {
    this.sessionStorageData.setData(this.keyId, JSON.stringify(planData));
  }

  public getPlanData(): PlanData {
    return JSON.parse(this.sessionStorageData.getData(this.keyId));
  }

  public deletePlanData(): void {
    this.sessionStorageData.deleteData(this.keyId);
  }

  public checkIfIsEmptyOrDifferentFrom(
    registration: string,
    planData: PlanData
  ): boolean {
    return (
      planData.details === null ||
      planData.details?.registration !== registration
    );
  }
}
