export function makeDateFromBrazilianDate(date: string): Date {
  const containsDash = date.includes('-');

  if (containsDash) return makeDateWithCustomSeparator(date, '-');

  return makeDateWithCustomSeparator(date, '/');
}

function makeDateWithCustomSeparator(date: string, separator: string): Date {
  const [day, month, year] = date.split(separator);
  return new Date(`${+year}/${+month}/${+day}`);
}
