import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-ext-header',
  templateUrl: './ext-header.component.html',
  styleUrls: ['./ext-header.component.scss']
})
export class ExtHeaderComponent implements OnInit {
  isTypeMenuOpen: boolean;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  ngOnInit(): void {}

  navigateToExternalURL(url: string) {
    this.document.location.href = url;
  }
}
