import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { AuthService } from '@app/auth/auth.service';
import { ComponentData } from '@app/open-insurance/open-insurance.model';
import { OpenInsuranceService } from '@app/open-insurance/open-insurance.service';
import {
  CONSENTS_TRANSMITTER_ROUTE,
  OPEN_INSURANCE_ROUTE,
  OPEN_INSURANCE_ROUTES
} from '@app/open-insurance/shared/constants/routes';
import { CompleteConsentPayloadDTO } from '../opin-consent/opin-consent.model';
import { OpinConsentService } from '../opin-consent/opin-consent.service';
import { TokenPrefix } from '@app/core/auth/auth.model';
import { AxiosError } from 'axios';
import { ConsentDTO, ErrorConsent } from '../opin-consent/opin-consent.model';
import { ERROR_COMMAND_MESSAGE } from '../opin-consent/opin-consent.enum';

@Injectable()
export class TransmitterService implements OnDestroy {
  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly opinConsentService: OpinConsentService,
    private readonly openInsuranceService: OpenInsuranceService
  ) {}

  completeSharingFallbackURL: string;

  endFallbackURL: string;
  cancel: () => Promise<void>;

  private destroy$ = new Subject();

  private _componentData: ComponentData[];
  private _storagedConsent: ConsentDTO;

  private handleErrorCommand(error: any) {
    if (error instanceof AxiosError) {
      const {
        response: { data }
      } = error;

      const errorMessage =
        ERROR_COMMAND_MESSAGE[data.type] ?? ERROR_COMMAND_MESSAGE.DEFAULT;

      this.cancelConsentByError({
        fallbackURL: data.redirect.redirectTo,
        institution: {
          name: data.tpp.name,
          thumbnail: data.tpp.logoUrl
        },
        message: errorMessage
      });
    } else {
      throw error;
    }
  }

  async getConsent(): Promise<ConsentDTO> {
    try {
      const authenticatedConsent =
        await this.opinConsentService.authenticateConsent(
          this.openInsuranceService.commandID,
          this.authService.getToken(TokenPrefix.Bearer)
        );

      return authenticatedConsent;
    } catch (error) {
      if (error instanceof AxiosError) {
        const { tpp, message, redirect } = error.response.data as ErrorConsent;

        this.cancelConsentByError({
          fallbackURL: redirect.redirectTo,
          institution: {
            name: tpp.name,
            thumbnail: tpp.logoUrl
          },
          message:
            ERROR_COMMAND_MESSAGE[message] ?? ERROR_COMMAND_MESSAGE.DEFAULT
        });

        return;
      }

      throw error;
    }
  }

  cancelConsentByError(data: {
    fallbackURL: string;
    institution: {
      name: string;
      thumbnail: string;
    };
    message: string;
  }) {
    this.endFallbackURL = data.fallbackURL;

    this.router.navigateByUrl(
      `${OPEN_INSURANCE_ROUTE}/${OPEN_INSURANCE_ROUTES[CONSENTS_TRANSMITTER_ROUTE].FINALIZATION}`,
      {
        state: {
          refused: true,
          title: 'Algo errado aconteceu!',
          message: data.message,
          brand: {
            name: data.institution.name,
            logo: data.institution.thumbnail
          }
        }
      }
    );
  }

  get storagedComponentData(): ComponentData[] | undefined {
    return this._componentData;
  }

  set storagedComponentData(value: ComponentData[]) {
    this._componentData = value;
  }

  get storagedConsent(): ConsentDTO | undefined {
    return this._storagedConsent;
  }

  set storagedConsent(value: ConsentDTO) {
    this._storagedConsent = value;
  }

  async confirm(
    data: Omit<CompleteConsentPayloadDTO, 'commandId'>,
    brand: ConsentDTO['participant']
  ): Promise<void> {
    try {
      const completedConsent = await this.opinConsentService.completeConsent(
        {
          ...data,
          commandId: this.openInsuranceService.commandID
        },
        this.authService.getToken(TokenPrefix.Bearer)
      );

      this.completeSharingFallbackURL = completedConsent.redirect.redirectTo;

      this.router.navigateByUrl(
        `${OPEN_INSURANCE_ROUTE}/${OPEN_INSURANCE_ROUTES[CONSENTS_TRANSMITTER_ROUTE].FINALIZATION}`,
        {
          state: {
            refused: false,
            brand
          }
        }
      );
    } catch (error) {
      this.handleErrorCommand(error);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
