import { Component, Input } from '@angular/core';
import { AuthService } from '@app/auth/auth.service';
import {
  Feature,
  SelectedPlanData
} from '@app/plan-manager/plan-manager.model';
import { PlanManagerService } from '@app/plan-manager/plan-manager.service';
import { Observable } from 'rxjs';
import { Callback, MenuGroups, menuGroups } from '../header.model';
import { HeaderService } from '../header.service';

@Component({
  selector: 'app-header-navigation',
  templateUrl: './header-navigation.component.html',
  styleUrls: ['./header-navigation.component.scss']
})
export class HeaderNavigationComponent {
  @Input() isMenuOpen: boolean;
  callback = Callback;
  feature = Feature;
  menuGroups = MenuGroups;
  selectedPlanData$: Observable<SelectedPlanData>;

  constructor(
    private headerService: HeaderService,
    private planManagerService: PlanManagerService,
    private authService: AuthService
  ) {
    this.selectedPlanData$ = this.planManagerService.getSelectedPlanData;
  }

  public navigateTo(url: string): void {
    this.headerService.navigateTo(url);
  }

  public handleNavigationItemAction(feature: Feature, registration: string) {
    this.headerService.featAction(feature, registration);
  }

  public mountMenuGroup(group: MenuGroups, featList: Feature[]): Feature[] {
    return menuGroups[group].filter((feat: Feature) =>
      this.hasFeatOn(feat, featList)
    ) as Feature[];
  }

  public hasFeatOn(feature: Feature, featList: Feature[]): boolean {
    return this.planManagerService.hasFeatOn(feature, featList);
  }
}
