<header>
  <div class="start">
    <button
      *ngIf="backButtonSettings"
      mat-button
      (click)="back()"
      [title]="backButtonTitle"
    >
      <mat-icon>navigate_before</mat-icon>
      <span class="back-button-title">{{ backButtonTitle }}</span>
    </button>
  </div>
  <div class="center">
    <h1><ng-content select=".title"></ng-content></h1>
    <h2><ng-content select=".subtitle"></ng-content></h2>
  </div>
  <div class="end">
    <ng-content select=".actions"></ng-content>
  </div>
</header>
