import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RadioComponent } from './radio/radio.component';
import { RadioGroupComponent } from './radio-group/radio-group.component';

@NgModule({
  declarations: [RadioComponent, RadioGroupComponent],
  exports: [RadioComponent, RadioGroupComponent],
  imports: [CommonModule]
})
export class RadioModule {}
