<div class="container">
  <mat-card>
    <h3>{{ title }}</h3>
    <form (ngSubmit)="onSubmit()" *ngIf="!isLoading; else loading">
      <mat-form-field appearance="outline">
        <mat-label>Código</mat-label>
        <input
          #codeInput
          matInput
          type="text"
          [formControl]="code"
          inputmode="numeric"
          maxlength="6"
          required
          autofocus
        />
        {{ codeInput.focus() }}
        <mat-error *ngIf="code.invalid">{{ getErrorMessage() }}</mat-error>
      </mat-form-field>
      <div class="actions--grid">
        <a
          mat-button
          class="first-action"
          color="primary"
          tabindex="0"
          (click)="back()"
          (keypress)="linkPress($event)"
        >
          <img src="assets/svg/login/sms.svg" alt="" /> Reenviar código
        </a>
        <button
          *ngIf="isWhatsappButtonEnabled"
          mat-button
          class="second-action"
          type="button"
          color="primary"
          title=""
          (click)="onHandleWpp()"
        >
          <img src="assets/svg/login/wpp.svg" alt="" />Enviar via Whatsapp
        </button>
        <button
          mat-raised-button
          class="main-button"
          color="primary"
          type="submit"
          title="Confirmar"
          [disabled]="isLoading"
        >
          Continuar
        </button>
        <button
          mat-button
          type="button"
          color="primary"
          title="Voltar"
          (click)="back()"
        >
          Voltar
        </button>
      </div>
    </form>
    <ng-template #loading>
      <mat-spinner></mat-spinner>
    </ng-template>
  </mat-card>
  <div class="warning">
    <mat-icon>info</mat-icon>
    <p class="no-margin">
      O código pode levar alguns minutos para chegar. Se o erro persistir, entre
      em contato com a nossa central de relacionamento. (Números disponíveis
      abaixo).
    </p>
  </div>
</div>
