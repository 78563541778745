import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Investment } from '@app/core/checkup-bff';
import { Fund } from '@app/plan/plan.model';
import { ChartColor } from '@app/shared/components/charts/chart/chart.model';
import { ChartOptions, ChartType } from 'chart.js';

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss']
})
export class DoughnutChartComponent {
  @Input()
  set funds(funds: Fund[] | Investment[]) {
    this.formatChartData(funds);
  }

  @Output() chartColors = new EventEmitter<ChartColor[]>();
  colors: ChartColor[];
  defaultColors = [
    '#465eff',
    '#00ACC1',
    '#FFB300',
    '#D81B60',
    '#5E35B1',
    '#F4511E',
    '#6D4C41',
    '#C0CA33',
    '#43A047',
    '#646464',
    '#465eff',
    '#00ACC1',
    '#FFB300',
    '#D81B60',
    '#5E35B1',
    '#F4511E',
    '#6D4C41',
    '#C0CA33',
    '#43A047',
    '#646464',
    '#465eff',
    '#00ACC1',
    '#FFB300',
    '#D81B60',
    '#5E35B1',
    '#F4511E',
    '#6D4C41',
    '#C0CA33',
    '#43A047',
    '#646464',
    '#465eff',
    '#00ACC1',
    '#FFB300',
    '#D81B60',
    '#5E35B1',
    '#F4511E',
    '#6D4C41',
    '#C0CA33',
    '#43A047',
    '#646464',
    '#465eff',
    '#00ACC1',
    '#FFB300',
    '#D81B60',
    '#5E35B1',
    '#F4511E',
    '#6D4C41',
    '#C0CA33',
    '#43A047',
    '#646464',
    '#465eff',
    '#00ACC1',
    '#FFB300',
    '#D81B60',
    '#5E35B1',
    '#F4511E',
    '#6D4C41',
    '#C0CA33',
    '#43A047',
    '#646464'
  ];

  public doughnutChartLabels: string[] = [];
  public doughnutChartData = [];
  public doughnutChartType: ChartType = 'doughnut';
  public doughnutChartOptions: ChartOptions = {
    cutout: 80,
    aspectRatio: 1,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: true,
        displayColors: false,
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';

            const value =
              context.parsed ||
              0;
            return [
              label.toString(),
              new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }).format(value as number)
            ];
          }
        }
      }
    }
  } as ChartOptions<'doughnut'> as unknown;

  formatChartData(funds: Fund[] | Investment[]): void {
    this.colors = [];
    if (funds !== null) {
      this.doughnutChartData = [{
        data: [],
        label: [],
        backgroundColor: []
      }];

      funds.forEach((fund: Fund | Investment, index: number) => {
        this.doughnutChartData[0].data.push(fund.balance);
        this.doughnutChartData[0].backgroundColor.push(this.defaultColors[index]);
        this.doughnutChartLabels.push(fund.percentage.toFixed(2) + '%' + ' ' + fund.name);
      });
    }
  }
}
