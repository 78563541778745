import { Injectable } from '@angular/core';
import { WindowService } from '@app/shared/service/window.service';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  constructor(private readonly windowService: WindowService) {}

  private getEnvironment(environment: 'dev' | 'hml' | 'localhost'): boolean {
    return this.windowService.window.location.href.includes(environment);
  }

  public isProductionEnvironment(): boolean {
    const isDevEnvironment = this.getEnvironment('dev');
    const isHomolEnvironment = this.getEnvironment('hml');
    const isLocalEnvironment = this.getEnvironment('localhost');

    return !(isDevEnvironment || isHomolEnvironment || isLocalEnvironment);
  }
}
