import { Component, ElementRef, OnInit } from '@angular/core';
import { Event, NavigationStart, Router } from '@angular/router';
import { UserData } from '@app/core/auth/user.model';
import { Observable, Subscription } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';

interface SideBarNavigation {
  diversification: { title: string; routerLink: string[] };
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  nameInitials: string;
  routeSubscription: Subscription;
  isHeaderEnabled$: Observable<boolean>;
  isHeaderEnabled: boolean;

  constructor(
    private _elementRef: ElementRef,
    private router: Router,
    private authService: AuthService
  ) {
    this.routeSubscription = new Subscription();
  }

  ngOnInit() {
    this._elementRef.nativeElement.removeAttribute('ng-version');
    this.isHeaderEnabled$ = this.authService.isHeaderEnabled.pipe(
      tap((isEnabled) => (this.isHeaderEnabled = isEnabled))
    );
    this.getUserName();

    this.router.events
      .pipe(first())
      .pipe(
        filter(
          (event: Event): event is NavigationStart =>
            event instanceof NavigationStart
        )
      )
      .pipe(
        tap((response) => {
          const isOpinAuthentication = response.url.includes('/auth/opin');
          if (isOpinAuthentication) {
            const [_baseURL, codeQueryParam] = response.url.split('?');

            this.authService.logoutForOpenInsuranceWithCode(codeQueryParam);
          }

          this.authService.handleAuthenticationByJourney(response.url);
        })
      )
      .subscribe();
  }

  getUserName() {
    this.isHeaderEnabled$
      .pipe(filter((isHeaderEnabled) => isHeaderEnabled))
      .subscribe(() => {
        this.nameInitials = this.authService.getUserData(UserData.NameInitials);
      });
  }
}
