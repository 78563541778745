<div class="container">
  <ng-container *ngIf="data?.error == 404; else genericError">
    <mat-icon class="error_outline">error_outline</mat-icon>

    <h2>404</h2>
    <div class="wrapper">
      <p>Página não encontrada</p>
    </div>

    <button
      mat-raised-button
      color="primary"
      type="button"
      [routerLink]="'/'"
      title="Voltar para página inicial"
    >
      Home
    </button>
  </ng-container>

  <ng-template #genericError>
    <mat-icon class="error_outline">error_outline</mat-icon>

    <h2>Opa!</h2>
    <div class="wrapper">
      <p>
        Houve um problema técnico. Não se preocupe, tente novamente mais tarde.
      </p>
    </div>

    <button
      mat-raised-button
      color="primary"
      type="button"
      [routerLink]="'/'"
      title="Voltar para página inicial"
    >
      Home
    </button>
  </ng-template>
</div>
