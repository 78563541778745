import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OpinButtonModule } from '../../atoms/button/button.module';
import { OpinButtonsFooterComponent } from './buttons-footer.component';

@NgModule({
  declarations: [OpinButtonsFooterComponent],
  exports: [OpinButtonsFooterComponent],
  imports: [OpinButtonModule, CommonModule]
})
export class OpinButtonsFooterModule {}
