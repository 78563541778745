import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toPositive'
})
export class ToPositivePipe implements PipeTransform {
  transform(value: any): any {
    return Math.abs(+value);
  }
}
