<div class="profile-menu">
  <button
    mat-stroked-button
    (click)="toggleProfileVisibility()"
    title="Abrir menu do usuário"
  >
    <span>
      {{ this.nameInitials }}
    </span>
  </button>
  <div class="profile-menu-container" [ngClass]="{ open: isProfileOpen }">
    <ul>
      <li *ngFor="let item of navigationList">
        <button
          mat-flat-button
          [title]="item.name"
          (click)="handleNavigationItemAction(item)"
        >
          {{ item.name }}
        </button>
      </li>
    </ul>
  </div>
</div>
