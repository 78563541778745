import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OpinSharingCardComponentModule } from '../../molecules/sharing-card/sharing-card.module';
import { OpinSharingCardListComponent } from './sharing-card-list.component';

@NgModule({
  declarations: [OpinSharingCardListComponent],
  exports: [OpinSharingCardListComponent],
  imports: [CommonModule, OpinSharingCardComponentModule]
})
export class OpinSharingCardListModule {}
