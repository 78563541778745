import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from '@app/shared/angular-material/angular-material.module';

import { AccordionComponent } from './accordion/accordion.component';
import { AccordionHeaderComponent } from './accordion/accordion-header/accordion-header.component';
import { AccordionTitleComponent } from './accordion/accordion-title/accordion-title.component';
import { AccordionSubtitleComponent } from './accordion/accordion-subtitle/accordion-subtitle.component';
import { AccordionBodyComponent } from './accordion/accordion-body/accordion-body.component';
import { AccordionGroupComponent } from './accordion-group/accordion-group.component';

@NgModule({
  declarations: [
    AccordionGroupComponent,
    AccordionComponent,
    AccordionHeaderComponent,
    AccordionTitleComponent,
    AccordionSubtitleComponent,
    AccordionBodyComponent
  ],
  exports: [
    AccordionGroupComponent,
    AccordionComponent,
    AccordionHeaderComponent,
    AccordionTitleComponent,
    AccordionSubtitleComponent,
    AccordionBodyComponent
  ],
  imports: [CommonModule, AngularMaterialModule]
})
export class AccordionModule {}
