import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { OpinIconModule } from '../../atoms/icon/icon.module';
import { OpinButtonWithIconComponentModule } from '../button-with-icon/button-with-icon.module';
import { OpinDividerComponentModule } from './../../atoms/divider/divider.module';
import { OpinLabelModule } from './../../atoms/label/label.module';
import { OpinTextRedirectModule } from './../../atoms/text-redirect/text-redirect.module';
import { OpinCardListInformationComponent } from './card-list-information.component';

@NgModule({
  declarations: [OpinCardListInformationComponent],
  exports: [OpinCardListInformationComponent],
  imports: [
    OpinLabelModule,
    OpinDividerComponentModule,
    OpinIconModule,
    OpinTextRedirectModule,
    OpinButtonWithIconComponentModule,
    SharedModule
  ]
})
export class OpinCardListInfoModule {}
