import { Injectable } from '@angular/core';
import { AuthService } from '@app/auth/auth.service';

import { Router } from '@angular/router';
import {
  ContributionManagerBff,
  DetailPlan,
  Plano,
  PlanoInelegivel,
  SimulacaoReativacaoRequest,
  SimulacaoReativacaoResponse,
  SolicitacaoRequest,
  SuspensaoSimulacaoRequest,
  SuspensaoSimulacaoResponse
} from '@app/core/contribution-manager-bff';
import { Plan } from '@app/core/new-contribution-bff';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { UpBaseRoute } from '../upselling.model';
import { PlanData } from './new-contribution.model';

@Injectable({
  providedIn: 'root'
})
export class ContributionManagerService {
  constructor(
    private cManagerBff: ContributionManagerBff,
    private authService: AuthService,
    private router: Router
  ) {}

  public list(): Observable<Plano> {
    return this.cManagerBff
      .plansList(this.authService.getToken())
      .pipe(take(1));
  }
  public detail(registration: string): Observable<DetailPlan> {
    return this.cManagerBff
      .detalhar(this.authService.getToken(), registration)
      .pipe(take(1));
  }
  public suspend(
    payload: SuspensaoSimulacaoRequest
  ): Observable<SuspensaoSimulacaoResponse> {
    return this.cManagerBff
      .suspensao(this.authService.getToken(), payload)
      .pipe(
        take(1),
        switchMap((response: SuspensaoSimulacaoResponse) =>
          this.solicitation(
            SolicitacaoRequest.fromJS({
              flagDuploSim: false,
              idSimulacao: response.idSimulacao
            })
          )
        )
      );
  }

  public active(
    payload: SimulacaoReativacaoRequest
  ): Observable<SimulacaoReativacaoResponse> {
    return this.cManagerBff
      .reativacao(this.authService.getToken(), payload)
      .pipe(
        take(1),
        switchMap((response: SimulacaoReativacaoResponse) =>
          this.solicitation(
            SolicitacaoRequest.fromJS({
              flagDuploSim: false,
              idSimulacao: response.idSimulacao
            })
          )
        )
      );
  }

  public solicitation(request: SolicitacaoRequest) {
    return this.cManagerBff
      .solicitacao(this.authService.getToken(), request)
      .pipe(take(1));
  }

  public isPlanaManagerEligible(
    registration: string,
    planData: PlanData
  ): boolean {
    if (planData.manageContributionList) {
      const ineligibleList = planData.manageContributionList.inelegiveis.map(
        (plan: PlanoInelegivel) => plan.matricula
      );
      return !ineligibleList.find((plan: string) => plan === registration);
    }
    return false;
  }

  public onSelection(plan: Plan): Observable<any> {
    this.router.navigate([
      ...UpBaseRoute.newContribution,
      'detail',
      plan.registration
    ]);
    return of().pipe(take(1));
  }
}
